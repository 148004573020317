import {
  CancelPaymentRequestRequest,
  CancelPaymentRequestResponse,
  CreatePaymentRequest,
  CreatePaymentRequestResponse,
  DeclinePaymentRequestRequest,
  DeclinePaymentRequestResponse,
  GetPaymentRequestDetailsRequest,
  GetPendingPaymentRequestsRequest,
  PaymentRequestDetails,
  PaymentRequestDetailsStatusEnum,
  PendingPaymentRequests,
} from 'api/wallet-app';
import { http, HttpResponse, PathParams } from 'msw';
import {
  commandsBasePath,
  mockedRequestsRestApiBasePath,
  walletBasePath,
} from 'mocks/helpers';
import { paymentRequestList, WALLET_ID } from 'mocks/fixtures';
import { v4 as uuid } from 'uuid';

const paymentRequestsHandlers = [
  /*
   * GET /api/v1/wallet/pending-payment-requests
   * Generated API Endpoint Name: getPendingPaymentRequests
   */
  http.get<GetPendingPaymentRequestsRequest, undefined, PendingPaymentRequests>(
    walletBasePath('pending-payment-requests'),
    () => {
      return HttpResponse.json({
        paymentRequests: paymentRequestList.filter(
          (paymentRequest) =>
            paymentRequest.status === PaymentRequestDetailsStatusEnum.Pending
        ),
      });
    }
  ),

  /*
   * GET /api/v1/wallet/payment-request/{paymentRequestId}
   * Generated API Endpoint Name: getPaymentRequestDetails
   */
  http.get<GetPaymentRequestDetailsRequest, undefined, PaymentRequestDetails>(
    walletBasePath('payment-request/:paymentRequestId'),
    ({ params }) => {
      const paymentRequest = paymentRequestList.find(
        (paymentRequest) => paymentRequest.paymentId === params.paymentRequestId
      );

      if (!paymentRequest) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        return HttpResponse.json(paymentRequest);
      }
    }
  ),

  /*
   * POST /api/v1/wallet/payment-request/{paymentRequestId}/remind
   * Generated API Endpoint Name: sendReminderPaymentRequest
   */
  http.post(walletBasePath('payment-request/:paymentRequestId/remind'), () => {
    return new HttpResponse(null, { status: 204 });
  }),

  /*
   * POST /api/v1/commands/request-payment
   * Generated API Endpoint Name: requestPayment
   */
  http.post<PathParams, CreatePaymentRequest, CreatePaymentRequestResponse>(
    commandsBasePath('request-payment'),
    async ({ request }) => {
      const { amount, memo, payerWalletId } = await request.json();
      const paymentId = uuid();
      paymentRequestList.push({
        amount: amount.toString(),
        memo,
        initiatedAt: new Date(),
        isReceiving: false,
        payeeWalletId: WALLET_ID,
        payerWalletId: payerWalletId,
        paymentId,
        status: PaymentRequestDetailsStatusEnum.Pending,
        updatedAt: new Date(),
        payeeHandle: 'payeeHandle',
        payeeName: 'Payee Name',
        payerHandle: 'payerHandle',
        payerName: 'Payer Name',
      });

      return HttpResponse.json({ paymentId });
    }
  ),

  /*
   * POST /api/v1/commands/decline-payment-request
   * Generated API Endpoint Name: declinePaymentRequest
   */
  http.post<
    PathParams,
    DeclinePaymentRequestRequest,
    DeclinePaymentRequestResponse
  >(commandsBasePath('decline-payment-request'), async ({ request }) => {
    const { paymentId, declinedReason } = await request.json();

    const index = paymentRequestList.findIndex(
      (paymentRequest) => paymentRequest.paymentId === paymentId
    );

    if (index === -1) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      paymentRequestList[index] = {
        ...paymentRequestList[index],
        declinedReason,
        status: PaymentRequestDetailsStatusEnum.Declined,
      };
      return HttpResponse.json({ paymentId });
    }
  }),

  /*
   * POST /api/v1/commands/cancel-payment-request
   * Generated API Endpoint Name: cancelPaymentRequest
   */
  http.post<
    PathParams,
    CancelPaymentRequestRequest,
    CancelPaymentRequestResponse
  >(
    mockedRequestsRestApiBasePath({ path: 'commands/cancel-payment-request' }),
    async ({ request }) => {
      const { paymentId, cancelReason } = await request.json();

      const index = paymentRequestList.findIndex(
        (paymentRequest) => paymentRequest.paymentId === paymentId
      );

      if (index === -1) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        paymentRequestList[index] = {
          ...paymentRequestList[index],
          canceledReason: cancelReason,
          status: PaymentRequestDetailsStatusEnum.Canceled,
        };
        return HttpResponse.json({ paymentId });
      }
    }
  ),
];

export default paymentRequestsHandlers;

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailAddressUnsubscribeRequest
 */
export interface EmailAddressUnsubscribeRequest {
  /**
   *
   * @type {string}
   * @memberof EmailAddressUnsubscribeRequest
   */
  token: string;
}

/**
 * Check if a given object implements the EmailAddressUnsubscribeRequest interface.
 */
export function instanceOfEmailAddressUnsubscribeRequest(
  value: object
): value is EmailAddressUnsubscribeRequest {
  if (!('token' in value) || value['token'] === undefined) return false;
  return true;
}

export function EmailAddressUnsubscribeRequestFromJSON(
  json: any
): EmailAddressUnsubscribeRequest {
  return EmailAddressUnsubscribeRequestFromJSONTyped(json, false);
}

export function EmailAddressUnsubscribeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailAddressUnsubscribeRequest {
  if (json == null) {
    return json;
  }
  return {
    token: json['token'],
  };
}

export function EmailAddressUnsubscribeRequestToJSON(
  value?: EmailAddressUnsubscribeRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    token: value['token'],
  };
}

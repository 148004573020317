import { Button } from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';

export default function AdditionalInfo() {
  const { send } = OnboardingContext.useActorRef();
  return (
    <Button
      onClick={() => {
        send({ type: 'next' });
      }}
    >
      Next
    </Button>
  );
}

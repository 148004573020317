import { useRef } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  useDisclosure,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { APP_STATE_QUERY_KEY, useUpdateOnboardingData } from 'hooks';
import { useQueryClient } from '@tanstack/react-query';

export default function GoBack() {
  const {
    isOpen: resetMachineAlertDialogIsOpen,
    onOpen: resetMachineAlertDialogOnOpen,
    onClose: resetMachineAlertDialogOnClose,
  } = useDisclosure();
  const {
    isOpen: resetEditingContextAlertDialogIsOpen,
    onOpen: resetEditingContextAlertDialogOnOpen,
    onClose: resetEditingContextAlertDialogOnClose,
  } = useDisclosure();

  const { send } = OnboardingContext.useActorRef();
  const {
    value,
    context: { resetMachine },
  } = OnboardingContext.useSelector((state) => state);

  function handleClick() {
    if (value === 'Overview') {
      resetMachineAlertDialogOnOpen();
    } else if (
      value === 'SolePropBusinessDetails' ||
      value === 'PersonalInfo' ||
      value === 'LlcBusinessDetails'
    ) {
      resetEditingContextAlertDialogOnOpen();
    } else {
      send({ type: 'back' });
    }
  }

  return (
    <>
      <ArrowBackIcon
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            handleClick();
          }
        }}
        alignSelf="left"
        boxSize={6}
        cursor="pointer"
      />
      <RestartOnboardingAlertDialog
        isOpen={resetMachineAlertDialogIsOpen}
        onClose={resetMachineAlertDialogOnClose}
        resetMachine={resetMachine}
      />
      <ResetEditingContextAlertDialog
        isOpen={resetEditingContextAlertDialogIsOpen}
        onClose={resetEditingContextAlertDialogOnClose}
      />
    </>
  );
}

function RestartOnboardingAlertDialog({
  isOpen,
  resetMachine,
  onClose,
}: {
  isOpen: boolean;
  resetMachine: () => void;
  onClose: () => void;
}) {
  const cancelRef = useRef(null);
  const queryClient = useQueryClient();
  const {
    mutate: updateOnboardingData,
    isPending: updateOnboardingDataIsPending,
  } = useUpdateOnboardingData();
  const onboardingMachineActor = OnboardingContext.useActorRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={!updateOnboardingDataIsPending}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontFamily="heading">
          Restart Onboarding?
        </AlertDialogHeader>
        <AlertDialogBody>
          If you continue with this action, all of your submitted onboarding
          data will be wiped. Are you sure you want to do this?
        </AlertDialogBody>
        <AlertDialogFooter width="60%" alignSelf="flex-end">
          <Button
            isDisabled={updateOnboardingDataIsPending}
            ref={cancelRef.current}
            onClick={onClose}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              updateOnboardingData(
                { people: [] },
                {
                  onSuccess: () => {
                    onClose();
                    onboardingMachineActor?.send({ type: 'back' });
                    queryClient
                      .refetchQueries({ queryKey: [APP_STATE_QUERY_KEY] })
                      .then(() => {
                        // Need to wait to cause the machine to re-initialize until the onboarding data has been updated
                        resetMachine();
                      });
                  },
                }
              );
            }}
            ml={3}
            isLoading={updateOnboardingDataIsPending}
          >
            Restart
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function ResetEditingContextAlertDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const cancelRef = useRef(null);
  const { send } = OnboardingContext.useActorRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontFamily="heading">
          Are you sure?
        </AlertDialogHeader>
        <AlertDialogBody>
          If you continue with this action, you will lose any edits you have
          made.
        </AlertDialogBody>
        <AlertDialogFooter width="60%" alignSelf="flex-end">
          <Button ref={cancelRef.current} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onClose();
              send({ type: 'back' });
            }}
            ml={3}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { PersonDto, PersonDtoRolesEnum } from 'api/wallet-app';
import { useUpdateOnboardingData } from 'hooks';
import { SSN_HOLDER, SsnForm } from 'pages/onboarding/components';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useMemo } from 'react';

export default function Ssn() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      personBeingEdited,
      onboardingData: { people, business },
      resetMachine,
    },
  } = OnboardingContext.useSelector((state) => state);

  const isBeneficialOwnerInformation = useMemo(() => {
    return (
      personBeingEdited?.roles?.length === 1 &&
      personBeingEdited.roles.includes(PersonDtoRolesEnum.BeneficialOwner)
    );
  }, [personBeingEdited]);

  const { mutate: updateOnboardingData, isPending } = useUpdateOnboardingData();

  function onSubmitSsn() {
    resetMachine();
    send({
      type: 'updatePersonBeingEdited',
    });
  }

  return (
    <Stack spacing={4}>
      <HStack justify="space-between">
        <Heading as="h3" size="lg">
          {`${isBeneficialOwnerInformation ? 'Beneficial Owner ' : ''}Social Security Number`}
        </Heading>
      </HStack>
      <Text>
        Your money is held in an FDIC insured financial institution, which
        requires collecting your Social Security number.
      </Text>
      <SsnForm
        isLoading={isPending}
        shouldMaskInputs={!!personBeingEdited?.ssn}
        onSubmitSsn={(ssn) => {
          if (personBeingEdited?.id) {
            const peopleToKeep = people.filter(
              (person) => person.id !== personBeingEdited?.id
            );

            // If SSN has been edited, submit user person info without Id
            // TODO: remove this once BE deploys the ability to update with id and a changed SSN
            if (ssn !== SSN_HOLDER) {
              const { id, ...personBeingEditedWithoutId } = personBeingEdited;

              updateOnboardingData(
                {
                  business,
                  people: [
                    ...peopleToKeep,
                    { ...(personBeingEditedWithoutId as PersonDto), ssn },
                  ],
                },
                {
                  onSuccess: () => {
                    onSubmitSsn();
                  },
                }
              );
            }
            // Submit updated data with id since SSN wasn't updated
            else {
              updateOnboardingData(
                {
                  business,
                  people: [...peopleToKeep, personBeingEdited as PersonDto],
                },
                {
                  onSuccess: () => {
                    onSubmitSsn();
                  },
                }
              );
            }
          }
          // We are creating a brand new person for the Wallet
          else {
            updateOnboardingData(
              {
                business: business,
                people: [
                  ...people,
                  {
                    ...(personBeingEdited as PersonDto),
                    ssn,
                  },
                ],
              },
              {
                onSuccess: () => {
                  onSubmitSsn();
                },
              }
            );
          }
        }}
      />
    </Stack>
  );
}

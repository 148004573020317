import { useMemo, useState } from 'react';
import {
  FormLabel,
  Flex,
  Input,
  Text,
  Stack,
  HStack,
  FormControl,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { WarningIcon } from '@chakra-ui/icons';
import {
  INVALID_DATE_ERROR_MESSAGE,
  MUST_BE_18_ERROR_MESSAGE,
  MUST_BE_UNDER_125_ERROR_MESSAGE,
} from './date-of-birth-input-schema';

type DateOfBirth = {
  day: string;
  month: string;
  year: string;
};

export default function DateOfBirthInput() {
  const [hasTouched, setHasTouched] = useState(false);

  const {
    getValues,
    setValue,
    formState: { errors },
    register,
    trigger,
  } = useFormContext<DateOfBirth>();

  const isErrorForWholeInput = useMemo(() => {
    const yearErrorMessage = errors.year?.message;
    return (
      yearErrorMessage === INVALID_DATE_ERROR_MESSAGE ||
      yearErrorMessage === MUST_BE_18_ERROR_MESSAGE ||
      yearErrorMessage === MUST_BE_UNDER_125_ERROR_MESSAGE
    );
  }, [errors.year]);

  const shouldClearInputs = useMemo(() => {
    const { day, month, year } = getValues();
    return day === '●●' && month === '●●' && year === '●●●●' && !hasTouched;
  }, [getValues, hasTouched]);

  function handleFocus() {
    if (shouldClearInputs) {
      setValue('day', '');
      setValue('month', '');
      setValue('year', '');
      setHasTouched(true);
    }
  }

  return (
    <FormControl as={Flex} flexDir="column" id="fieldset">
      <FormLabel>
        <HStack spacing={1}>
          <Text color="red.500" mr={1}>
            *
          </Text>
          <Text>Date of Birth</Text>
        </HStack>
      </FormLabel>
      <HStack>
        <Input
          {...register('month', {
            onBlur: () => {
              if (isErrorForWholeInput) {
                trigger('year');
              }
            },
          })}
          onFocus={() => {
            handleFocus();
          }}
          flex={1}
          minLength={1}
          maxLength={2}
          placeholder="MM"
          type="tel"
          isInvalid={!!errors.month || isErrorForWholeInput}
        />
        <Input
          {...register('day', {
            onBlur: () => {
              if (isErrorForWholeInput) {
                trigger('year');
              }
            },
          })}
          onFocus={() => {
            handleFocus();
          }}
          flex={1}
          minLength={1}
          maxLength={2}
          placeholder="DD"
          type="tel"
          isInvalid={!!errors.day || isErrorForWholeInput}
        />
        <Input
          {...register('year')}
          onFocus={() => {
            handleFocus();
          }}
          flex={1}
          minLength={4}
          maxLength={4}
          placeholder="YYYY"
          type="tel"
          isInvalid={!!errors.year || !!errors.root}
        />
      </HStack>
      <Stack spacing={1} py={2} fontSize="sm" color="red.500">
        {Object.entries(errors).map(([key, value], index) => {
          if (key === 'month' || key === 'day' || key === 'year') {
            return (
              <HStack key={index} fontSize="sm" color="red.500">
                <WarningIcon />
                <Text>{value.message}</Text>
              </HStack>
            );
          }
        })}
      </Stack>
    </FormControl>
  );
}

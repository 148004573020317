import { Stack, Text, Image, StackProps, Button } from '@chakra-ui/react';
import { usePlaidSdk } from 'hooks';
import PuzzlePieces from 'images/puzzle-pieces.png';

export default function AddFundingSourceTile({
  maxW = 'none',
}: {
  maxW?: StackProps['maxW'];
}) {
  const {
    plaidSdkIsReady,
    generatePlaidLinkTokenIsPending,
    addFundingSourceIsPending,
    openPlaidSdk,
  } = usePlaidSdk();

  return (
    <Stack
      align="center"
      border="1px solid"
      borderColor="blue.400"
      bgColor="blue.50"
      p={4}
      borderRadius="lg"
      spacing={4}
      maxW={maxW}
      overflow="hidden"
    >
      <Stack>
        <Image src={PuzzlePieces} />
        <Text fontWeight="bold" textAlign="center">
          Link a bank account
        </Text>
        <Text textAlign="center">
          Add a bank account to your Wallet to start transferring money
        </Text>
      </Stack>
      <Button
        onClick={openPlaidSdk}
        isLoading={
          (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
          addFundingSourceIsPending
        }
      >
        Link Account
      </Button>
    </Stack>
  );
}

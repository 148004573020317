import { Heading, Stack } from '@chakra-ui/react';
import { PersonalInfoForm } from '../../../components/forms';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useMemo } from 'react';
import {
  BusinessDtoBusinessStructureEnum,
  PersonDto,
  PersonDtoRolesEnum,
} from 'api/wallet-app';

export default function PersonalInfo() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      personBeingEdited,
      onboardingData: { business: { businessStructure } = {} },
    },
  } = OnboardingContext.useSelector((state) => state);

  const isBeneficialOwnerInformation = useMemo(() => {
    return (
      personBeingEdited?.roles?.length === 1 &&
      personBeingEdited.roles.includes(PersonDtoRolesEnum.BeneficialOwner)
    );
  }, [personBeingEdited]);

  const isEditingController = useMemo(
    () => personBeingEdited?.roles?.includes(PersonDtoRolesEnum.Controller),
    [personBeingEdited]
  );

  return (
    <Stack>
      <Heading mb={4} fontFamily="heading" fontSize={26}>
        {`${
          isBeneficialOwnerInformation ? 'Beneficial Owner' : 'Your Personal'
        } Info`}
      </Heading>
      <PersonalInfoForm
        shouldDisplayBoQuestion={isEditingController}
        handleOnSubmit={(data) => {
          const formatDateOfBirth = () => {
            const { day, month, year } = data;
            return day === '●●' && month === '●●' && year === '●●●●'
              ? '****/**/**'
              : `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          };

          send({
            type: 'updatePersonBeingEdited',
            personDetails: {
              ...personBeingEdited,
              dateOfBirth: formatDateOfBirth(),
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone.replace(/[\s()\-]/g, ''),
              id: personBeingEdited?.id,
              roles: determineRoles({
                isController: !!isEditingController,
                businessStructure,
                boSelection: data.isBeneficialOwner === 'yes' ? true : false,
                existingRoles: personBeingEdited?.roles,
              }),
            },
          });
        }}
        defaultValues={
          personBeingEdited
            ? {
                day: personBeingEdited.dateOfBirth?.includes('*')
                  ? '●●'
                  : (personBeingEdited.dateOfBirth?.split('-')[2] ?? ''),
                month: personBeingEdited.dateOfBirth?.includes('*')
                  ? '●●'
                  : (personBeingEdited.dateOfBirth?.split('-')[1] ?? ''),

                year: personBeingEdited.dateOfBirth?.includes('*')
                  ? '●●●●'
                  : (personBeingEdited.dateOfBirth?.split('-')[0] ?? ''),
                email: personBeingEdited.email ?? '',
                firstName: personBeingEdited.firstName ?? '',
                lastName: personBeingEdited.lastName ?? '',
                phone: personBeingEdited.phone ?? '',
                isBeneficialOwner: defaultBoSelection({
                  personBeingEdited,
                  isSoleProp:
                    businessStructure ===
                    BusinessDtoBusinessStructureEnum.SoleProp,
                  isBeneficialOwner: isBeneficialOwnerInformation,
                }),
              }
            : {
                day: '',
                email: '',
                firstName: '',
                lastName: '',
                month: '',
                phone: '',
                year: '',
                isBeneficialOwner: '',
              }
        }
      />
    </Stack>
  );
}

function determineRoles({
  existingRoles = [],
  boSelection,
  isController,
  businessStructure,
}: {
  existingRoles?: PersonDtoRolesEnum[];
  boSelection: boolean;
  isController: boolean;
  businessStructure?: BusinessDtoBusinessStructureEnum;
}) {
  if (existingRoles.length > 2) {
    console.error('You currently cannot have more than 2 roles.');
    return;
  }

  if (
    existingRoles.length === 0 &&
    businessStructure === BusinessDtoBusinessStructureEnum.SoleProp
  ) {
    return [PersonDtoRolesEnum.SoleProprietor];
  }

  if (isController) {
    return boSelection
      ? [PersonDtoRolesEnum.Controller, PersonDtoRolesEnum.BeneficialOwner]
      : [PersonDtoRolesEnum.Controller];
  }

  if (
    boSelection &&
    !existingRoles.includes(PersonDtoRolesEnum.BeneficialOwner)
  ) {
    return [...existingRoles, PersonDtoRolesEnum.BeneficialOwner];
  }

  if (existingRoles.length === 2 && !boSelection) {
    return [PersonDtoRolesEnum.Controller];
  }

  return existingRoles;
}

function defaultBoSelection({
  personBeingEdited,
  isSoleProp,
  isBeneficialOwner,
}: {
  personBeingEdited?: Partial<PersonDto>;
  isSoleProp: boolean;
  isBeneficialOwner: boolean;
}) {
  if (!personBeingEdited?.firstName && !isSoleProp && !isBeneficialOwner) {
    return '';
  }

  return personBeingEdited?.roles?.includes(
    PersonDtoRolesEnum.BeneficialOwner
  ) || isBeneficialOwner
    ? 'yes'
    : 'no';
}

import { useMutation } from '@tanstack/react-query';
import useOnboardingClient from './useOnboardingClient';
import { v4 } from 'uuid';
import { PLAID_SCREEN_KEY } from 'pages/WalletApp/add-funding-source';
import { useMediaQuery, useToast } from '@chakra-ui/react';

export default function useSubmitOnboardingData() {
  const onboardingClient = useOnboardingClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation({
    mutationKey: ['submit-onboarding-data'],
    mutationFn: () => {
      return onboardingClient.submitOnboardingData({ idempotencyKey: v4() });
    },
    onSuccess: () => {
      sessionStorage.setItem(PLAID_SCREEN_KEY, 'true');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error Submitting Data',
        description:
          'There was an error submitting your onboarding data. Please try again.',
      });
    },
  });
}

import { object, string } from 'yup';
import { addressFormSchema } from '../address-form/address-form-schema';

const baseSolePropBusinessInfoFormSchema = object().shape({
  industryClassificationCode: string().required(
    'Industry classification is required'
  ),
  doingBusinessAs: string().optional(),
  einChoice: string().required(),
  ein: string()
    .optional()
    .when('einChoice', {
      is: (value: string) => value === 'yes',
      then: (schema) =>
        schema
          .required('EIN is required ')
          .test(
            'is-nine-digits',
            'EIN must be 9 numbers',
            (value) => value.replace(/[_\-]/g, '').length === 9
          ),
      otherwise: (schema) => schema,
    }),
});

export const solePropBusinessInfoFormSchema =
  baseSolePropBusinessInfoFormSchema.concat(addressFormSchema);

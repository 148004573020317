import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';
import { useWalletStore } from 'stores';

export default function useUnsubscribe() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient(false);
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation({
    mutationKey: ['unsubscribe'],
    mutationFn: (token: string) => {
      return walletsClient.unsubscribeEmails({
        emailAddressUnsubscribeRequest: {
          token,
        },
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Failed to Opt Out',
        description: 'The request to opt out of failed.',
      });
    },
  });
}

import { Heading, Stack } from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { ControllerAttestationForm } from 'pages/onboarding/components';
import { useMemo } from 'react';

export default function ControllerAttestation() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: { isController },
  } = OnboardingContext.useSelector((state) => state);

  const isControllerDefaultValue = useMemo(() => {
    if (isController === undefined) {
      return '';
    } else if (isController) {
      return 'yes';
    } else {
      return 'no';
    }
  }, [isController]);

  return (
    <Stack spacing={6}>
      <Heading as="h3" size="lg">
        Controller
      </Heading>
      <ControllerAttestationForm
        defaultValues={{
          isController: isControllerDefaultValue,
        }}
        onSubmitControllerAttestation={(data) => {
          send({
            type: 'updateIsController',
            isController: data.isController === 'yes' ? true : false,
          });
        }}
      />
    </Stack>
  );
}

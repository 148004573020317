import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { useRef } from 'react';

export default function ConfirmLogoutAlertDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const cancelRef = useRef(null);
  const { keycloak } = useKeycloak();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontFamily="heading">
          Exit Onboarding?
        </AlertDialogHeader>
        <AlertDialogBody>
          If you exit now, you'll be logged out.
        </AlertDialogBody>
        <AlertDialogFooter width="60%" alignSelf="flex-end">
          <Button ref={cancelRef.current} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              keycloak.logout();
            }}
            ml={3}
          >
            Log Out
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

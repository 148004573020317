import { useEffect, useState } from 'react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { Heading, Stack } from '@chakra-ui/react';
import { BusinessDtoBusinessStructureEnum } from 'api/wallet-app';
import { WalletQueryParams } from 'utils/helpers';
import { useUpdateOnboardingData } from 'hooks';
import { TypeOfBusinessForm } from 'pages/onboarding/components';

export default function TypeOfBusiness() {
  const walletQueryParams = new WalletQueryParams();

  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      onboardingData: { business: { businessStructure } = {}, people },
      resetMachine,
    },
  } = OnboardingContext.useSelector((state) => state);

  const [origin, setOrigin] = useState('');

  useEffect(() => {
    if (walletQueryParams.hasValidPartner()) {
      setOrigin(walletQueryParams.getPartnerId());
    }

    if (walletQueryParams.hasToken()) {
      const partnerId = walletQueryParams.getLinkTokenIntegrationPartnerId();
      setOrigin(partnerId || '');
    }
  }, [origin]);

  const { mutate: updateOnboardingData, isPending } = useUpdateOnboardingData();

  return (
    <Stack spacing={12}>
      <Stack spacing={6}>
        <Heading as="h3" size="lg">
          Business Type
        </Heading>
        <TypeOfBusinessForm
          isLoading={isPending}
          defaultValues={{ businessStructure: businessStructure ?? '' }}
          onSubmitTypeOfBusiness={(data, shouldUpdate) => {
            if (shouldUpdate) {
              updateOnboardingData(
                {
                  people: people,
                  business: {
                    businessStructure:
                      data.businessStructure as BusinessDtoBusinessStructureEnum,
                  },
                },
                {
                  onSuccess: () => {
                    resetMachine();
                  },
                }
              );
            } else {
              send({ type: 'next' });
            }
          }}
        />
      </Stack>
    </Stack>
  );
}

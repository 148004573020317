/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  BusinessInvitationListResponse,
  CheckInvitationRequest,
  CheckInvitationResponse,
  DeactivateAccountResponse,
  DeactivateWalletRequest,
  DefaultErrorResponse,
  EmailAddressUnsubscribeRequest,
  InviteAuthorizedUserRequest,
  InviteBusinessRequest,
  LinkWalletRequest,
  RevokeUserAccessRequest,
  SettingsInfoResponse,
  UserListItem,
  UserListResponse,
  ValidateUserInviteRequest,
  ValidateUserInviteResponse,
  ViolationsError,
  WalletBalanceResponse,
} from '../models/index';
import {
  BusinessInvitationListResponseFromJSON,
  BusinessInvitationListResponseToJSON,
  CheckInvitationRequestFromJSON,
  CheckInvitationRequestToJSON,
  CheckInvitationResponseFromJSON,
  CheckInvitationResponseToJSON,
  DeactivateAccountResponseFromJSON,
  DeactivateAccountResponseToJSON,
  DeactivateWalletRequestFromJSON,
  DeactivateWalletRequestToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  EmailAddressUnsubscribeRequestFromJSON,
  EmailAddressUnsubscribeRequestToJSON,
  InviteAuthorizedUserRequestFromJSON,
  InviteAuthorizedUserRequestToJSON,
  InviteBusinessRequestFromJSON,
  InviteBusinessRequestToJSON,
  LinkWalletRequestFromJSON,
  LinkWalletRequestToJSON,
  RevokeUserAccessRequestFromJSON,
  RevokeUserAccessRequestToJSON,
  SettingsInfoResponseFromJSON,
  SettingsInfoResponseToJSON,
  UserListItemFromJSON,
  UserListItemToJSON,
  UserListResponseFromJSON,
  UserListResponseToJSON,
  ValidateUserInviteRequestFromJSON,
  ValidateUserInviteRequestToJSON,
  ValidateUserInviteResponseFromJSON,
  ValidateUserInviteResponseToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
  WalletBalanceResponseFromJSON,
  WalletBalanceResponseToJSON,
} from '../models/index';

export interface CheckUserInviteRequest {
  checkInvitationRequest: CheckInvitationRequest;
}

export interface DeactivateWalletOperationRequest {
  xWalletId: string;
  deactivateWalletRequest: DeactivateWalletRequest;
  idempotencyKey?: string;
}

export interface GetBusinessInvitesRequest {
  xWalletId: string;
}

export interface GetSettingsInfoRequest {
  xWalletId: string;
}

export interface GetUserRequest {
  xWalletId: string;
}

export interface GetUsersRequest {
  xWalletId: string;
}

export interface GetWalletBalanceRequest {
  xWalletId: string;
}

export interface InviteAuthorizedUserOperationRequest {
  xWalletId: string;
  inviteAuthorizedUserRequest: InviteAuthorizedUserRequest;
  idempotencyKey?: string;
}

export interface InviteBusinessOperationRequest {
  xWalletId: string;
  inviteBusinessRequest: InviteBusinessRequest;
}

export interface LinkWalletOperationRequest {
  xWalletId: string;
  linkWalletRequest: LinkWalletRequest;
  idempotencyKey?: string;
}

export interface ResendBusinessInviteRequest {
  xWalletId: string;
  inviteId: string;
}

export interface RevokeUserAccessOperationRequest {
  xWalletId: string;
  revokeUserAccessRequest: RevokeUserAccessRequest;
  idempotencyKey?: string;
}

export interface UnsubscribeEmailsRequest {
  emailAddressUnsubscribeRequest: EmailAddressUnsubscribeRequest;
}

export interface ValidateInviteRequest {
  validateUserInviteRequest: ValidateUserInviteRequest;
  idempotencyKey?: string;
}

/**
 *
 */
export class WalletsApi extends runtime.BaseAPI {
  /**
   * Checks a Wallet user invitation.
   */
  async checkUserInviteRaw(
    requestParameters: CheckUserInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CheckInvitationResponse>> {
    if (requestParameters['checkInvitationRequest'] == null) {
      throw new runtime.RequiredError(
        'checkInvitationRequest',
        'Required parameter "checkInvitationRequest" was null or undefined when calling checkUserInvite().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/commands/check-user-invite`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CheckInvitationRequestToJSON(
          requestParameters['checkInvitationRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CheckInvitationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Checks a Wallet user invitation.
   */
  async checkUserInvite(
    requestParameters: CheckUserInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CheckInvitationResponse> {
    const response = await this.checkUserInviteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Closes a wallet account.
   */
  async deactivateWalletRaw(
    requestParameters: DeactivateWalletOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeactivateAccountResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling deactivateWallet().'
      );
    }

    if (requestParameters['deactivateWalletRequest'] == null) {
      throw new runtime.RequiredError(
        'deactivateWalletRequest',
        'Required parameter "deactivateWalletRequest" was null or undefined when calling deactivateWallet().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/deactivate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeactivateWalletRequestToJSON(
          requestParameters['deactivateWalletRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeactivateAccountResponseFromJSON(jsonValue)
    );
  }

  /**
   * Closes a wallet account.
   */
  async deactivateWallet(
    requestParameters: DeactivateWalletOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeactivateAccountResponse> {
    const response = await this.deactivateWalletRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the business invites.
   */
  async getBusinessInvitesRaw(
    requestParameters: GetBusinessInvitesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BusinessInvitationListResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getBusinessInvites().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/business-invites`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BusinessInvitationListResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get the business invites.
   */
  async getBusinessInvites(
    requestParameters: GetBusinessInvitesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BusinessInvitationListResponse> {
    const response = await this.getBusinessInvitesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Gets information for the settings page.
   */
  async getSettingsInfoRaw(
    requestParameters: GetSettingsInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SettingsInfoResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getSettingsInfo().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/settings/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SettingsInfoResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets information for the settings page.
   */
  async getSettingsInfo(
    requestParameters: GetSettingsInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SettingsInfoResponse> {
    const response = await this.getSettingsInfoRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Gets the user information.
   */
  async getUserRaw(
    requestParameters: GetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserListItem>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getUser().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/current-user`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserListItemFromJSON(jsonValue)
    );
  }

  /**
   * Gets the user information.
   */
  async getUser(
    requestParameters: GetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserListItem> {
    const response = await this.getUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of users.
   */
  async getUsersRaw(
    requestParameters: GetUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserListResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getUsers().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/users`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserListResponseFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves a list of users.
   */
  async getUsers(
    requestParameters: GetUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserListResponse> {
    const response = await this.getUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets the balance for a wallet.
   */
  async getWalletBalanceRaw(
    requestParameters: GetWalletBalanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WalletBalanceResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getWalletBalance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/balance`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WalletBalanceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets the balance for a wallet.
   */
  async getWalletBalance(
    requestParameters: GetWalletBalanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WalletBalanceResponse> {
    const response = await this.getWalletBalanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Invites an authorized user to join an existing Wallet.
   */
  async inviteAuthorizedUserRaw(
    requestParameters: InviteAuthorizedUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling inviteAuthorizedUser().'
      );
    }

    if (requestParameters['inviteAuthorizedUserRequest'] == null) {
      throw new runtime.RequiredError(
        'inviteAuthorizedUserRequest',
        'Required parameter "inviteAuthorizedUserRequest" was null or undefined when calling inviteAuthorizedUser().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/invite-user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InviteAuthorizedUserRequestToJSON(
          requestParameters['inviteAuthorizedUserRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Invites an authorized user to join an existing Wallet.
   */
  async inviteAuthorizedUser(
    requestParameters: InviteAuthorizedUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.inviteAuthorizedUserRaw(requestParameters, initOverrides);
  }

  /**
   * Invites a business to Wallet.
   */
  async inviteBusinessRaw(
    requestParameters: InviteBusinessOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling inviteBusiness().'
      );
    }

    if (requestParameters['inviteBusinessRequest'] == null) {
      throw new runtime.RequiredError(
        'inviteBusinessRequest',
        'Required parameter "inviteBusinessRequest" was null or undefined when calling inviteBusiness().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/business-invites`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InviteBusinessRequestToJSON(
          requestParameters['inviteBusinessRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Invites a business to Wallet.
   */
  async inviteBusiness(
    requestParameters: InviteBusinessOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.inviteBusinessRaw(requestParameters, initOverrides);
  }

  /**
   * Link a wallet to an integration partner item.
   */
  async linkWalletRaw(
    requestParameters: LinkWalletOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling linkWallet().'
      );
    }

    if (requestParameters['linkWalletRequest'] == null) {
      throw new runtime.RequiredError(
        'linkWalletRequest',
        'Required parameter "linkWalletRequest" was null or undefined when calling linkWallet().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/link-wallet`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LinkWalletRequestToJSON(requestParameters['linkWalletRequest']),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Link a wallet to an integration partner item.
   */
  async linkWallet(
    requestParameters: LinkWalletOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.linkWalletRaw(requestParameters, initOverrides);
  }

  /**
   * Resends a business invite.
   */
  async resendBusinessInviteRaw(
    requestParameters: ResendBusinessInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling resendBusinessInvite().'
      );
    }

    if (requestParameters['inviteId'] == null) {
      throw new runtime.RequiredError(
        'inviteId',
        'Required parameter "inviteId" was null or undefined when calling resendBusinessInvite().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/business-invites/{inviteId}`.replace(
          `{${'inviteId'}}`,
          encodeURIComponent(String(requestParameters['inviteId']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Resends a business invite.
   */
  async resendBusinessInvite(
    requestParameters: ResendBusinessInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.resendBusinessInviteRaw(requestParameters, initOverrides);
  }

  /**
   * Revokes an authorized user\'s access to a Wallet.
   */
  async revokeUserAccessRaw(
    requestParameters: RevokeUserAccessOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling revokeUserAccess().'
      );
    }

    if (requestParameters['revokeUserAccessRequest'] == null) {
      throw new runtime.RequiredError(
        'revokeUserAccessRequest',
        'Required parameter "revokeUserAccessRequest" was null or undefined when calling revokeUserAccess().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/revoke-user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RevokeUserAccessRequestToJSON(
          requestParameters['revokeUserAccessRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Revokes an authorized user\'s access to a Wallet.
   */
  async revokeUserAccess(
    requestParameters: RevokeUserAccessOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.revokeUserAccessRaw(requestParameters, initOverrides);
  }

  /**
   * Unsubscribes an email address.
   */
  async unsubscribeEmailsRaw(
    requestParameters: UnsubscribeEmailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['emailAddressUnsubscribeRequest'] == null) {
      throw new runtime.RequiredError(
        'emailAddressUnsubscribeRequest',
        'Required parameter "emailAddressUnsubscribeRequest" was null or undefined when calling unsubscribeEmails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/public/email-unsubscribe`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailAddressUnsubscribeRequestToJSON(
          requestParameters['emailAddressUnsubscribeRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unsubscribes an email address.
   */
  async unsubscribeEmails(
    requestParameters: UnsubscribeEmailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.unsubscribeEmailsRaw(requestParameters, initOverrides);
  }

  /**
   * Validates a Wallet authorized user invite.
   */
  async validateInviteRaw(
    requestParameters: ValidateInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ValidateUserInviteResponse>> {
    if (requestParameters['validateUserInviteRequest'] == null) {
      throw new runtime.RequiredError(
        'validateUserInviteRequest',
        'Required parameter "validateUserInviteRequest" was null or undefined when calling validateInvite().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/validate-invite`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ValidateUserInviteRequestToJSON(
          requestParameters['validateUserInviteRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ValidateUserInviteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Validates a Wallet authorized user invite.
   */
  async validateInvite(
    requestParameters: ValidateInviteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ValidateUserInviteResponse> {
    const response = await this.validateInviteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

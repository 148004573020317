import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { APP_STATE_QUERY_KEY, useAppClient } from 'hooks';
import { AppStateResponse } from 'api/wallet-app';

type PoolingState =
  | 'wait'
  | 'ready'
  | 'shouldProceed'
  | 'proceed'
  | 'stopPolling';

export default function usePollThenRefetchAppState({
  stateTypes,
  refetchAppStateInterval = 1000,
  refetchQueryTimeDelay = 2000,
  maxPollTime = 60000,
  minWaitTime = 4000,
}: {
  stateTypes: AppStateResponse['type'][];
  refetchAppStateInterval?: number;
  refetchQueryTimeDelay?: number;
  maxPollTime?: number;
  minWaitTime?: number;
}) {
  const [pollingState, setPollingState] = useState<PoolingState>('wait');

  const queryClient = useQueryClient();
  const appClient = useAppClient();

  // This is intentionally polling without using the useAppState query. I want to control when
  // we force a rerender in App.tsx, and I accomplish that by declaratively refetching the app
  // state query based query key in the useEffect below.
  useEffect(() => {
    if (pollingState === 'stopPolling') return;

    const apiRequestInterval = setInterval(() => {
      appClient.getAppState().then((data) => {
        if (!stateTypes.includes(data.type)) {
          if (pollingState === 'wait') {
            setPollingState('shouldProceed');
          } else {
            setPollingState('proceed');
          }
        }
      });
    }, refetchAppStateInterval);

    return () => {
      clearInterval(apiRequestInterval);
    };
  }, [pollingState]);

  // After they have moved on from all of the states in the stateTypes property passed to this
  // hook, refetch the app state based on the app state query key after 2 seconds.
  // This allows the user to see that they actually successfully submitted in the UI.
  // Refetching they query causes the App.tsx to rerender because the data will be different.
  // Doing this will put the user in the correct state from a UI perspective.
  useEffect(() => {
    // NOTE: If pollingState is equal to 'stopPolling' we are not 'refreshing' app state query at all,
    // so they will stay on the page they are currently on.
    if (pollingState === 'proceed') {
      const refetchTimeout = setTimeout(() => {
        queryClient.refetchQueries({ queryKey: [APP_STATE_QUERY_KEY] });
      }, refetchQueryTimeDelay);

      return () => {
        clearTimeout(refetchTimeout);
      };
    }
  }, [pollingState, queryClient]);

  // Safeguards that stop polling after a specified time and that waits to allow the user to
  // proceed until a specified time has passed
  useEffect(() => {
    const maxPollTimeTimeout = setTimeout(() => {
      setPollingState('stopPolling');
    }, maxPollTime);

    const minWaitTimeTimeout = setTimeout(() => {
      if (pollingState === 'shouldProceed') {
        setPollingState('proceed');
      } else {
        setPollingState('ready');
      }
    }, minWaitTime);

    return () => {
      clearTimeout(minWaitTimeTimeout);
      clearTimeout(maxPollTimeTimeout);
    };
  }, [queryClient, maxPollTime]);

  return pollingState === 'proceed';
}

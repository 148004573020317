import { ProfilesResponse, WalletProfileDto } from 'api/wallet-app';
import { http, HttpResponse, PathParams } from 'msw';
import { mockedRequestsRestApiBasePath, walletBasePath } from 'mocks/helpers';
import { searchProfileList } from 'mocks/fixtures/wallet-profiles-fixtures';

const walletProfilesHandlers = [
  /*
   * GET /api/v1/wallet/profiles/{id}
   * Generated API Endpoint Name: getWalletProfile
   */
  http.get<{ id: string }, undefined, WalletProfileDto>(
    mockedRequestsRestApiBasePath({ path: 'wallet/profiles/:id' }),
    ({ params }) => {
      const profile = searchProfileList.find(
        (profile) => profile.id === params.id
      );

      if (!profile) {
        return HttpResponse.json(null, { status: 404 });
      } else {
        return HttpResponse.json({
          id: profile.id,
          doingBusinessAs: profile.doingBusinessAs ?? 'Not Defined in Mocks',
          handle: profile.handle,
          location: profile.location,
          name: profile.name,
        });
      }
    }
  ),

  /*
   * GET /api/v1/wallet/profiles/search
   * Generated API Endpoint Name: searchWalletProfiles
   */
  http.get<PathParams, undefined, ProfilesResponse>(
    walletBasePath('profiles/search'),
    ({ request }) => {
      const url = new URL(request.url);

      const searchValue = url.searchParams.get('searchValue');

      if (searchValue) {
        const matchedProfiles = searchProfileList.filter(
          (profile) =>
            profile.handle.includes(searchValue) ||
            profile.doingBusinessAs?.includes(searchValue)
        );

        return HttpResponse.json({ profiles: matchedProfiles });
      } else {
        return HttpResponse.json({ profiles: [] });
      }
    }
  ),
];

export default walletProfilesHandlers;

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The remove funding sources response.
 * @export
 * @interface RemoveFundingSourcesResponse
 */
export interface RemoveFundingSourcesResponse {
  /**
   *
   * @type {string}
   * @memberof RemoveFundingSourcesResponse
   */
  fundingSourceId: string;
}

/**
 * Check if a given object implements the RemoveFundingSourcesResponse interface.
 */
export function instanceOfRemoveFundingSourcesResponse(
  value: object
): value is RemoveFundingSourcesResponse {
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  return true;
}

export function RemoveFundingSourcesResponseFromJSON(
  json: any
): RemoveFundingSourcesResponse {
  return RemoveFundingSourcesResponseFromJSONTyped(json, false);
}

export function RemoveFundingSourcesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveFundingSourcesResponse {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId: json['fundingSourceId'],
  };
}

export function RemoveFundingSourcesResponseToJSON(
  value?: RemoveFundingSourcesResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
  };
}

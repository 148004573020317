import { Heading, Stack, Text } from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { LlcBusinessInfoForm } from 'pages/onboarding/components';

export default function LlcBusinessDetails() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: { businessBeingEdited, appStateType },
  } = OnboardingContext.useSelector((state) => state);

  return (
    <Stack spacing={6}>
      <Heading as="h3" size="lg">
        Business Details
      </Heading>
      <Text>
        Please enter your legal business name and Employer Identification Number
        (EIN) as registered with the IRS.
      </Text>
      <LlcBusinessInfoForm
        disableEinInput={appStateType === 'RESUBMISSION_NEEDED'}
        defaultValues={{
          businessName: businessBeingEdited?.businessName ?? '',
          businessPhone: businessBeingEdited?.businessPhone ?? '',
          ein: businessBeingEdited?.ein ?? '',
          industryClassificationCode:
            businessBeingEdited?.industryClassificationCode ?? '',
          doingBusinessAs: businessBeingEdited?.doingBusinessAs ?? '',
        }}
        onSubmitBusinessInfo={(data) => {
          send({
            type: 'updateBusinessBeingEdited',
            businessDetails: {
              ...data,
              businessPhone: data.businessPhone.replace(/[\s()\-_]/g, ''),
              ein: data.ein.replace(/[_\-]/g, ''),
            },
          });
        }}
      />
    </Stack>
  );
}

import { assign, setup } from 'xstate';
import { FundingSourceDto, WalletProfileDto } from 'api/wallet-app';

export type TransferContext = {
  amount: string | undefined;
  fundingSource: FundingSourceDto | undefined;
  recipient: WalletProfileDto | undefined;
  balance: string | undefined;
};

export type TransferEvent =
  | {
      type: 'NEXT';
      amount?: string;
      recipient?: WalletProfileDto;
      fundingSource?: FundingSourceDto;
      balance?: string;
    }
  | { type: 'BACK' };

export const transferMachine = setup({
  actions: {
    amount: assign({
      amount: ({ event }) => {
        if (event.type !== 'NEXT') return;
        return event.amount;
      },
    }),
    balance: assign({
      balance: ({ event }) => {
        if (event.type !== 'NEXT') return;
        return event.balance;
      },
    }),

    recipient: assign({
      recipient: ({ event }) => {
        if (event.type !== 'NEXT') return;
        return event.recipient;
      },
    }),
    fundingSource: assign({
      fundingSource: ({ event }) => {
        if (event.type !== 'NEXT') return;
        return event.fundingSource;
      },
    }),
  },
  types: {
    context: {} as TransferContext,
    events: {} as TransferEvent,
  },
}).createMachine({
  id: 'sendMoney',
  initial: 'enterAmount',
  context: {
    amount: '0.00',
    balance: '0.00',
    fundingSource: {} as FundingSourceDto,
    recipient: {} as WalletProfileDto,
  },
  states: {
    enterAmount: {
      on: {
        NEXT: {
          target: 'selectSource',
          actions: ['amount', 'recipient'],
        },
      },
    },
    selectSource: {
      on: {
        NEXT: { target: 'confirm', actions: ['fundingSource', 'balance'] },
        BACK: 'enterAmount',
      },
    },
    confirm: { on: { BACK: 'selectSource', NEXT: 'success' } },
    success: { type: 'final' },
  },
});

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  Button,
  Image,
  Text,
} from '@chakra-ui/react';
import { usePlaidSdk } from 'hooks';
import PuzzlePieces from 'images/puzzle-pieces.png';

export default function AddFundingSourceModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const {
    plaidSdkIsReady,
    generatePlaidLinkTokenIsPending,
    addFundingSourceIsPending,
    openPlaidSdk,
  } = usePlaidSdk();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={
        (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
        addFundingSourceIsPending
      }
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as={'h5'} fontSize="24px">
            Link Bank Account
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Image src={PuzzlePieces} />
            <Text>
              You must link a bank account before depositing money or
              transferring to a bank.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant="secondary"
              onClick={onClose}
              isDisabled={
                (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending
              }
            >
              Cancel
            </Button>
            <Button
              onClick={openPlaidSdk}
              isLoading={
                (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending
              }
            >
              Link Account
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

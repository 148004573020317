import { useEffect, useMemo, useState } from 'react';
import { TextInput } from 'components/formComponents';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { OnboardingAddressDto } from 'api/wallet-app';
import {
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  Heading,
  HStack,
  Button,
  Stack,
  Input,
} from '@chakra-ui/react';
import { useHookFormMask } from 'use-mask-input';
import { isEqual } from 'lodash';
import {
  AddressFormInputs,
  IndustryClassificationCodesInput,
} from 'pages/onboarding/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { solePropBusinessInfoFormSchema } from './sole-prop-business-info-schema';

type SolePropBusinessInfoForm = Omit<
  Required<OnboardingAddressDto>,
  'streetNumber2'
> & {
  einChoice: string;
  doingBusinessAs?: string;
  streetNumber2?: string;
  ein?: string;
  industryClassificationCode: string;
};

export default function SolePropBusinessInfoForm({
  defaultValues,
  residentialAddress,
  isLoading,
  disableEinInputs,
  onSubmitBusinessInfo,
}: {
  defaultValues: SolePropBusinessInfoForm;
  residentialAddress?: OnboardingAddressDto;
  isLoading: boolean;
  disableEinInputs: boolean;
  onSubmitBusinessInfo: (data: SolePropBusinessInfoForm) => void;
}) {
  const methods = useForm<SolePropBusinessInfoForm>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(solePropBusinessInfoFormSchema),
  });

  const {
    register,
    formState: { errors },
    resetField,
    setValue,
    handleSubmit,
    watch,
    control,
  } = methods;

  const {
    city,
    country,
    postalCode,
    stateProvinceRegion,
    streetNumber1,
    streetNumber2,
  } = watch();

  const businessAddress: OnboardingAddressDto = {
    city,
    country,
    postalCode,
    stateProvinceRegion,
    streetNumber1,
    streetNumber2,
  };

  const [sameAddress, setSameAddress] = useState(
    isEqual(businessAddress, residentialAddress)
  );

  const registerWithMask = useHookFormMask(register);

  const shouldDisableResidentialAddressCheckbox = useMemo(
    () => residentialAddress && isEqual(businessAddress, residentialAddress),
    [businessAddress, residentialAddress]
  );

  useEffect(() => {
    if (isEqual(businessAddress, residentialAddress)) {
      setSameAddress(true);
    } else {
      setSameAddress(false);
    }
  }, [businessAddress, residentialAddress]);

  return (
    <Stack spacing={6}>
      <Stack>
        <Heading as="h6" size="sm">
          Registered Business Address
        </Heading>
        <Text fontSize={14}>
          Please enter your business address as listed within your business's
          official documents.
        </Text>
      </Stack>
      <Checkbox
        isDisabled={
          !residentialAddress || shouldDisableResidentialAddressCheckbox
        }
        onChange={(e) => {
          setSameAddress(e.target.checked);
          if (!e.target.checked) {
            resetField('city');
            resetField('country');
            resetField('postalCode');
            resetField('stateProvinceRegion');
            resetField('streetNumber1');
            resetField('streetNumber2');
          } else {
            setValue('city', residentialAddress?.city ?? '');
            setValue('country', residentialAddress?.country ?? '');
            setValue('postalCode', residentialAddress?.postalCode ?? '');
            setValue(
              'stateProvinceRegion',
              residentialAddress?.stateProvinceRegion ?? ''
            );
            setValue('streetNumber1', residentialAddress?.streetNumber1 ?? '');
            setValue('streetNumber2', residentialAddress?.streetNumber2);
          }
        }}
        isChecked={sameAddress}
        borderColor="gray.700"
      >
        Same as residential address
      </Checkbox>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitBusinessInfo)} noValidate>
          <Stack spacing={12}>
            <Stack spacing={8}>
              <AddressFormInputs
                streetNumber1Label="Business Address Line 1"
                streetNumber2Label="Business Address Line 2"
              />
              <Stack spacing={4}>
                <Heading as="h6" size="sm">
                  Registered Business Address
                </Heading>
                <Text fontSize={14}>
                  The following fields may require supporting business documents
                  to be uploaded after Wallet creation.
                </Text>
                <IndustryClassificationCodesInput />
                <Stack>
                  <HStack align="flex-end">
                    <Text>DBA</Text>
                    <Text color="gray.500" fontSize="xs">
                      Optional
                    </Text>
                  </HStack>
                  <Text fontSize="14" color="gray.500">
                    Enter your business's operating name, if different from the
                    legal name.
                  </Text>
                  <Input {...register('doingBusinessAs')} />
                </Stack>
                <>
                  <Text fontWeight="bold" fontFamily="heading">
                    Employer Identification Number (EIN)
                  </Text>
                  <Text>I use an EIN for tax reporting purposes</Text>
                  <Controller
                    name="einChoice"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} isDisabled={disableEinInputs}>
                        <HStack spacing={40}>
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                        </HStack>
                      </RadioGroup>
                    )}
                  />
                  {watch('einChoice') === 'yes' && (
                    <TextInput
                      label=""
                      type="tel"
                      inputMode="numeric"
                      placeholder="12-3456789"
                      {...registerWithMask('ein', '99-9999999')}
                      errors={errors}
                      isDisabled={disableEinInputs}
                    />
                  )}
                </>
              </Stack>
            </Stack>
            <Button isLoading={isLoading} type="submit">
              Next
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProcessingOnboardingAppStateDto
 */
export interface ProcessingOnboardingAppStateDto {
  /**
   *
   * @type {string}
   * @memberof ProcessingOnboardingAppStateDto
   */
  walletId: string;
}

/**
 * Check if a given object implements the ProcessingOnboardingAppStateDto interface.
 */
export function instanceOfProcessingOnboardingAppStateDto(
  value: object
): value is ProcessingOnboardingAppStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  return true;
}

export function ProcessingOnboardingAppStateDtoFromJSON(
  json: any
): ProcessingOnboardingAppStateDto {
  return ProcessingOnboardingAppStateDtoFromJSONTyped(json, false);
}

export function ProcessingOnboardingAppStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProcessingOnboardingAppStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
  };
}

export function ProcessingOnboardingAppStateDtoToJSON(
  value?: ProcessingOnboardingAppStateDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
  };
}

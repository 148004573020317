import { useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { ProfileOverlay } from 'components/ProfileOverlay';
import {
  Divider,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
  Image,
  Heading,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useSearchWalletProfiles } from 'hooks/api';
import { WalletProfileDto } from 'api/wallet-app';
import { InviteCenterLink } from '../../components/InviteCenterLink';
import { useDebounce } from 'use-debounce';
import { CenteredSpinner } from 'components/CenteredSpinner';
import noProfiles from 'images/transfer-and-pay/no-profiles.svg';

export function FindProfile() {
  const [searchValue, setSearchValue] = useState('');
  const [selectedProfile, setSelectedProfile] = useState<WalletProfileDto>();
  const [debounceValue] = useDebounce(searchValue, 800);
  const {
    data: searchedProfiles,
    isLoading: searchProfilesIsLoading,
    isRefetching: searchProfilesIsRefetching,
    isPending,
  } = useSearchWalletProfiles(debounceValue);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      {selectedProfile && (
        <ProfileOverlay
          profile={selectedProfile}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <Stack spacing={5}>
        <HStack justify="space-between">
          <ArrowBackIcon
            tabIndex={0}
            onClick={() => navigate(-1)}
            boxSize={6}
            cursor="pointer"
          />
          <Text textAlign="center">Send & Request</Text>
          <div />
        </HStack>
        <Stack spacing={6}>
          <InputGroup>
            <Input
              placeholder="@handle, business, phone number"
              color="gray.500"
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
              value={searchValue}
            />
            <InputRightElement
              children={<SearchIcon boxSize={13} color="gray.700" />}
            />
          </InputGroup>
          {searchProfilesIsLoading ||
          searchProfilesIsRefetching ||
          isPending ? (
            <CenteredSpinner centerProps={{ h: '200px' }} />
          ) : (
            <Stack
              maxHeight={
                searchedProfiles?.profiles.length === 0
                  ? 'inherit'
                  : ['450px', '350px']
              }
              spacing={4}
            >
              <Text fontWeight="700" color="gray.500" fontSize="xs">
                {!searchValue ? 'RECENT' : 'RESULTS'}
              </Text>
              <Stack
                divider={<Divider borderColor="gray.200" />}
                overflowY="auto"
                boxShadow={
                  searchedProfiles?.profiles.length === 0 && !searchValue
                    ? 'none'
                    : '0px -2px 4px 0px #0000000D inset'
                }
              >
                {searchedProfiles?.profiles.map((searchedProfile) => {
                  return (
                    <Stack
                      key={searchedProfile.id}
                      spacing={1}
                      onClick={() => {
                        setSelectedProfile({
                          handle: searchedProfile.handle,
                          id: searchedProfile.id,
                          location: searchedProfile.location,
                          name: searchedProfile.name,
                          doingBusinessAs: searchedProfile.doingBusinessAs,
                        });
                        onOpen();
                      }}
                      _hover={{ cursor: 'pointer' }}
                    >
                      <Text>{searchedProfile.name}</Text>
                      <HStack
                        color="gray.500"
                        justifyContent="space-between"
                        fontSize="sm"
                        pr={2}
                      >
                        <Text>@{searchedProfile.handle}</Text>
                        <Text>{searchedProfile.location}</Text>
                      </HStack>
                    </Stack>
                  );
                })}
                {searchedProfiles &&
                  searchedProfiles.profiles.length === 0 &&
                  !searchValue && (
                    <Stack spacing={4} align="center" pt={6}>
                      <Image src={noProfiles} />
                      <Heading fontSize="2xl">No businesses found</Heading>
                      <Text width="80%" textAlign="center">
                        Try searching for their phone, name, or @handle
                      </Text>
                    </Stack>
                  )}
              </Stack>
              <InviteCenterLink />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

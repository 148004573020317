import {
  Button,
  Heading,
  Stack,
  useDisclosure,
  Text,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import { ConfirmOnboardingSubmissionModal } from 'pages/onboarding/components';

export default function PenaltyOfPerjury() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Stack spacing={6}>
        <Heading>W-9 Certification</Heading>
        <Text>
          Please review the information you have provided to ensure its
          accuracy. Before submitting, you must certify the following under
          penalties of perjury:
        </Text>
        <Stack bgColor="#F7FAFC" p={4} borderRadius="lg">
          <Text fontWeight="bold" fontSize="24px">
            Certification
          </Text>
          <OrderedList>
            <ListItem>
              The number I entered is my correct taxpayer identification number
              (or I am waiting for a number to be issued to me);
            </ListItem>
            <ListItem>
              I am not subject to backup withholding because:
              <OrderedList styleType="lower-alpha">
                <ListItem>I am exempt from backup withholding, or</ListItem>
                <ListItem>
                  I have not been notified by the IRS that I am subject to
                  backup withholding as a result of a failure to report all
                  interest or dividends, or
                </ListItem>
                <ListItem>
                  The IRS has notified me that I am no longer subject to backup
                  withholding;
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>I am a U.S. citizen or other U.S. person; and</ListItem>
            <ListItem>
              Foreign Account Tax Compliance Act (FACTA) does not apply for this
              business entity.
            </ListItem>
          </OrderedList>
        </Stack>
        <Text>
          By clicking
          <Text as="span" fontWeight="bold">
            Submit
          </Text>
          , you certify that the information provided is accurate to the best of
          your knowledge.
        </Text>
        <Text>
          If you are unable to certify the above statements are true and
          correct, you are unable to open a Bushel Wallet account.
        </Text>
        <Button onClick={onOpen}>Submit and Certify</Button>
      </Stack>
      <ConfirmOnboardingSubmissionModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

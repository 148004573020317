/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AddressValidationResponse,
  DefaultErrorResponse,
  GetCountryCodesResponse,
  GetIndustryClassificationCodesResponse,
  GetSectorClassificationCodesResponse,
  OnboardingDataDto,
  SubmitOnboardingResponse,
  ValidateAddressRequest,
  ViolationsError,
} from '../models/index';
import {
  AddressValidationResponseFromJSON,
  AddressValidationResponseToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  GetCountryCodesResponseFromJSON,
  GetCountryCodesResponseToJSON,
  GetIndustryClassificationCodesResponseFromJSON,
  GetIndustryClassificationCodesResponseToJSON,
  GetSectorClassificationCodesResponseFromJSON,
  GetSectorClassificationCodesResponseToJSON,
  OnboardingDataDtoFromJSON,
  OnboardingDataDtoToJSON,
  SubmitOnboardingResponseFromJSON,
  SubmitOnboardingResponseToJSON,
  ValidateAddressRequestFromJSON,
  ValidateAddressRequestToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface GetIndustryClassificationCodesRequest {
  priorityCodesOnly?: boolean;
}

export interface SubmitOnboardingDataRequest {
  idempotencyKey?: string;
}

export interface UpdateOnboardingDataRequest {
  onboardingDataDto: OnboardingDataDto;
  idempotencyKey?: string;
}

export interface ValidateAddressOperationRequest {
  validateAddressRequest: ValidateAddressRequest;
}

/**
 *
 */
export class OnboardingApi extends runtime.BaseAPI {
  /**
   * Gets a list of country codes and if they are approved for wallet.
   */
  async getCountryCodesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCountryCodesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/onboarding/countries`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCountryCodesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets a list of country codes and if they are approved for wallet.
   */
  async getCountryCodes(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCountryCodesResponse> {
    const response = await this.getCountryCodesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Gets all industry classification codes.
   */
  async getIndustryClassificationCodesRaw(
    requestParameters: GetIndustryClassificationCodesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetIndustryClassificationCodesResponse>> {
    const queryParameters: any = {};

    if (requestParameters['priorityCodesOnly'] != null) {
      queryParameters['priorityCodesOnly'] =
        requestParameters['priorityCodesOnly'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/industry-classification-codes`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetIndustryClassificationCodesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets all industry classification codes.
   */
  async getIndustryClassificationCodes(
    requestParameters: GetIndustryClassificationCodesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetIndustryClassificationCodesResponse> {
    const response = await this.getIndustryClassificationCodesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Fetch list of Sectors.
   */
  async getSectorClassificationCodesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetSectorClassificationCodesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/sector-classification-codes`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetSectorClassificationCodesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch list of Sectors.
   */
  async getSectorClassificationCodes(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetSectorClassificationCodesResponse> {
    const response = await this.getSectorClassificationCodesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Submit the onboarding data.
   */
  async submitOnboardingDataRaw(
    requestParameters: SubmitOnboardingDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SubmitOnboardingResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/onboarding/wallet`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitOnboardingResponseFromJSON(jsonValue)
    );
  }

  /**
   * Submit the onboarding data.
   */
  async submitOnboardingData(
    requestParameters: SubmitOnboardingDataRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitOnboardingResponse> {
    const response = await this.submitOnboardingDataRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update onboarding information.
   */
  async updateOnboardingDataRaw(
    requestParameters: UpdateOnboardingDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingDataDto>> {
    if (requestParameters['onboardingDataDto'] == null) {
      throw new runtime.RequiredError(
        'onboardingDataDto',
        'Required parameter "onboardingDataDto" was null or undefined when calling updateOnboardingData().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/onboarding/wallet`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OnboardingDataDtoToJSON(requestParameters['onboardingDataDto']),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OnboardingDataDtoFromJSON(jsonValue)
    );
  }

  /**
   * Update onboarding information.
   */
  async updateOnboardingData(
    requestParameters: UpdateOnboardingDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingDataDto> {
    const response = await this.updateOnboardingDataRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Validate Address
   */
  async validateAddressRaw(
    requestParameters: ValidateAddressOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddressValidationResponse>> {
    if (requestParameters['validateAddressRequest'] == null) {
      throw new runtime.RequiredError(
        'validateAddressRequest',
        'Required parameter "validateAddressRequest" was null or undefined when calling validateAddress().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/onboarding/validate-address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ValidateAddressRequestToJSON(
          requestParameters['validateAddressRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddressValidationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Validate Address
   */
  async validateAddress(
    requestParameters: ValidateAddressOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddressValidationResponse> {
    const response = await this.validateAddressRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

import { PropsWithChildren } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { head, get, last } from 'lodash';
import { Capacitor } from '@capacitor/core';

export default function PanelStyle(
  props: PropsWithChildren<{
    rest?: FlexProps;
  }>
) {
  const { children, rest } = props;
  //@ts-ignore
  const firstChild = children.length ? head(children) : children;
  const component = last(get(firstChild, '_source.fileName', '').split('/'));
  const platform = Capacitor.getPlatform();

  return (
    <Flex
      zIndex="9"
      className={`content__panel ${component}`}
      width={['100%', `488px`]}
      maxWidth="488px"
      bg="white"
      flexDirection="column"
      alignSelf="center"
      p={['20px 25px', '38px 48px 48px']}
      m="env(save-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      height={['100%', 'auto']}
      boxShadow={[
        'null',
        '0px 10px 20px rgba(45, 55, 72, 0.24), 0px 2px 10px rgba(45, 55, 72, 0.17)',
      ]}
      pb="4rem"
      borderRadius="16px"
      overflow={['scroll', 'initial']}
      mb={platform == 'android' ? 0 : '2rem'}
      {...rest}
    >
      {children}
    </Flex>
  );
}

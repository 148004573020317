/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentRequestDetails
 */
export interface PaymentRequestDetails {
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  paymentId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  memo?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentRequestDetails
   */
  initiatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentRequestDetails
   */
  updatedAt: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  declinedReason?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  canceledReason?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payerWalletId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payeeWalletId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payerName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payeeName?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentRequestDetails
   */
  isReceiving: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  status: PaymentRequestDetailsStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payeeHandle?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  payerHandle?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequestDetails
   */
  transactionId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentRequestDetailsStatusEnum {
  Pending = 'Pending',
  Canceled = 'Canceled',
  Declined = 'Declined',
  Paid = 'Paid',
}

/**
 * Check if a given object implements the PaymentRequestDetails interface.
 */
export function instanceOfPaymentRequestDetails(
  value: object
): value is PaymentRequestDetails {
  if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  if (!('initiatedAt' in value) || value['initiatedAt'] === undefined)
    return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  if (!('payerWalletId' in value) || value['payerWalletId'] === undefined)
    return false;
  if (!('payeeWalletId' in value) || value['payeeWalletId'] === undefined)
    return false;
  if (!('isReceiving' in value) || value['isReceiving'] === undefined)
    return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function PaymentRequestDetailsFromJSON(
  json: any
): PaymentRequestDetails {
  return PaymentRequestDetailsFromJSONTyped(json, false);
}

export function PaymentRequestDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentRequestDetails {
  if (json == null) {
    return json;
  }
  return {
    paymentId: json['paymentId'],
    amount: json['amount'],
    memo: json['memo'] == null ? undefined : json['memo'],
    initiatedAt: new Date(json['initiatedAt']),
    updatedAt: new Date(json['updatedAt']),
    declinedReason:
      json['declinedReason'] == null ? undefined : json['declinedReason'],
    canceledReason:
      json['canceledReason'] == null ? undefined : json['canceledReason'],
    payerWalletId: json['payerWalletId'],
    payeeWalletId: json['payeeWalletId'],
    payerName: json['payerName'] == null ? undefined : json['payerName'],
    payeeName: json['payeeName'] == null ? undefined : json['payeeName'],
    isReceiving: json['isReceiving'],
    status: json['status'],
    payeeHandle: json['payeeHandle'] == null ? undefined : json['payeeHandle'],
    payerHandle: json['payerHandle'] == null ? undefined : json['payerHandle'],
    transactionId:
      json['transactionId'] == null ? undefined : json['transactionId'],
  };
}

export function PaymentRequestDetailsToJSON(
  value?: PaymentRequestDetails | null
): any {
  if (value == null) {
    return value;
  }
  return {
    paymentId: value['paymentId'],
    amount: value['amount'],
    memo: value['memo'],
    initiatedAt: value['initiatedAt'].toISOString(),
    updatedAt: value['updatedAt'].toISOString(),
    declinedReason: value['declinedReason'],
    canceledReason: value['canceledReason'],
    payerWalletId: value['payerWalletId'],
    payeeWalletId: value['payeeWalletId'],
    payerName: value['payerName'],
    payeeName: value['payeeName'],
    isReceiving: value['isReceiving'],
    status: value['status'],
    payeeHandle: value['payeeHandle'],
    payerHandle: value['payerHandle'],
    transactionId: value['transactionId'],
  };
}

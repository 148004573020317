import { emailRegex, noNumbersRegex } from 'utils/helpers';
import { object, string } from 'yup';
import { dateOfBirthInputSchema } from '../../inputs/date-of-birth-input';

const basePersonalInfoFormSchema = object().shape({
  firstName: string()
    .required('First name is required')
    .max(50, 'Maximum 50 characters')
    .matches(noNumbersRegex, { message: 'No numbers allowed' }),
  lastName: string()
    .required('Last name is required')
    .max(50, 'Maximum 50 characters')
    .matches(noNumbersRegex, { message: 'No numbers allowed' }),
  phone: string()
    .required('Phone number is required')
    .test(
      'phone-has-ten-digits',
      'Phone number must be at least 10 numbers',
      (value) => value.replace(/[\s()\-_]/g, '').length === 10
    ),
  email: string()
    .required('Email is required')
    .min(3, 'Email must be at least 3 characters')
    .matches(emailRegex, { message: 'Please enter a valid email' }),
  isBeneficialOwner: string().required(
    'Answering if you are a beneficial owner or not is required'
  ),
});

export const personalInfoFormSchema = basePersonalInfoFormSchema.concat(
  dateOfBirthInputSchema
);

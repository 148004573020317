import bgImage from 'images/harvest.svg';
import logo from 'images/bushel-logo.png';
import { jwtDecode } from 'jwt-decode';
import { OnboardingAddressDto } from 'api/wallet-app';

export const phoneRegExp =
  /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
export const ssnRegExp =
  /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
export const noSpecialCharsRegex = /^[a-zA-Z0-9 ]+$/;
export const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const noNumbersRegex = /^([^0-9]*)$/;
// https://colinhacks.com/essays/reasonable-email-regex
export const emailRegex =
  /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

export interface IIndexable {
  [key: string]: any;
}

export const assertNoPoBox = (address?: string) => {
  const poBoxStrings = ['PO Box', 'P.O. Box', 'Post Office Box', 'POBox'];
  const found = poBoxStrings.some((e) =>
    address?.toLowerCase().includes(e.toLowerCase())
  );

  const hasPoBox = poBoxStrings.some((e) => {
    return address?.toLowerCase().includes(e.toLowerCase());
  });

  return !hasPoBox;
};

export const formatPhone = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const getDefaultAddressValues = (
  context: OnboardingAddressDto | undefined
) => {
  return {
    streetNumber1: context?.streetNumber1 || '',
    streetNumber2: context?.streetNumber2 || '',
    city: context?.city || '',
    stateProvinceRegion: context?.stateProvinceRegion || '',
    postalCode: context?.postalCode || '',
    country: 'US',
  };
};

export const desktopHeaderHeight = '72px';
export const desktopHeaderMargin = '80px';
export const background = `url(${bgImage})`;
export const bushelLogo = logo;

export const isSandbox = () => {
  return window.origin === 'https://app.sandbox.bushelwallet.com';
};

export class WalletQueryParams {
  params: IIndexable;
  partnerId: string | null = null;
  VALID_PARTNERS = ['compeer-agtech', 'fbn', 'agvend', 'rawhide'];
  // if the user is using a Link token and is coming from one of these integration partners, we should prevent them from creating accounts
  BUSHELDEV = '0dcb5220-e58b-44dd-9a30-d89c8305be13';
  BUSHELDEV_UAT = '499a1f0a-ef9a-4e76-90ca-66aeeaa03ed2';
  PROD = 'f813133e-9be7-44a5-b5e6-1b8e330eb0a3';
  BLOCKED_INTEGRATION_PARTNER_IDS = [
    this.BUSHELDEV,
    this.BUSHELDEV_UAT,
    this.PROD,
  ];

  constructor() {
    const rawParams = new URLSearchParams(window.location.search);
    this.params = Object.fromEntries(rawParams.entries());
  }

  hasValidPartner = () => {
    const partner = this.params['partner'];

    if (!partner) return false;

    return this.VALID_PARTNERS.includes(partner.toLowerCase()) ? true : false;
  };

  getPartnerId = () => {
    return this.params['partner'];
  };

  hasToken = () => {
    return this.params['token'] !== undefined;
  };

  getLinkTokenIntegrationPartnerId = () => {
    if (!this.hasToken()) return undefined;

    let parsedToken: { 'app.linkToken.integrationPartnerId': string } | null =
      null;

    try {
      parsedToken = jwtDecode(this.params['token']);
    } catch (e) {
      console.error(e);
      return undefined;
    }

    if (parsedToken) {
      const { 'app.linkToken.integrationPartnerId': partnerId } =
        parsedToken || '';
      return partnerId;
    } else {
      return undefined;
    }
  };

  isBlockedIntegrationPartnerId = (partnerId: string) => {
    return this.BLOCKED_INTEGRATION_PARTNER_IDS.includes(partnerId);
  };
}

export const cleanupSSN = (ssn: string) => {
  return ssn
    .split('')
    .filter((i) => i !== '-')
    .join('');
};

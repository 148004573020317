import { Button, Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioButtonGroupInput } from '../../inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { controllerAttestationFormSchema } from './controller-attestation-form-schema';

type ControllerAttestationForm = { isController: string };

export default function ControllerAttestationForm({
  defaultValues,
  onSubmitControllerAttestation,
}: {
  defaultValues: ControllerAttestationForm;
  onSubmitControllerAttestation: (data: ControllerAttestationForm) => void;
}) {
  const methods = useForm<ControllerAttestationForm>({
    defaultValues,
    resolver: yupResolver(controllerAttestationFormSchema),
  });
  const { handleSubmit, watch } = methods;

  function onSubmit(data: ControllerAttestationForm) {
    onSubmitControllerAttestation(data);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={12}>
          <Stack spacing={6}>
            <Text>Are you considered a controller at this organization?</Text>
            <RadioButtonGroupInput
              name="isController"
              options={[
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
            />
            <Text bg="gray.50" borderRadius="sm" p={2} fontSize="sm">
              The controller is the person responsible for managing or directing
              the company's operations, policies, and major business decisions.
              This role usually includes executives such as CEOs, CFOs, or other
              high-level managers.
            </Text>
          </Stack>
          <Button isDisabled={watch('isController') === ''} type="submit">
            Next
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProcessingOnboardingAppStateDto } from './ProcessingOnboardingAppStateDto';
import {
  ProcessingOnboardingAppStateDtoFromJSON,
  ProcessingOnboardingAppStateDtoFromJSONTyped,
  ProcessingOnboardingAppStateDtoToJSON,
} from './ProcessingOnboardingAppStateDto';

/**
 * Processing onboarding application state
 * @export
 * @interface ProcessingOnboardingAppState
 */
export interface ProcessingOnboardingAppState {
  /**
   *
   * @type {ProcessingOnboardingAppStateDto}
   * @memberof ProcessingOnboardingAppState
   */
  appState: ProcessingOnboardingAppStateDto;
}

/**
 * Check if a given object implements the ProcessingOnboardingAppState interface.
 */
export function instanceOfProcessingOnboardingAppState(
  value: object
): value is ProcessingOnboardingAppState {
  if (!('appState' in value) || value['appState'] === undefined) return false;
  return true;
}

export function ProcessingOnboardingAppStateFromJSON(
  json: any
): ProcessingOnboardingAppState {
  return ProcessingOnboardingAppStateFromJSONTyped(json, false);
}

export function ProcessingOnboardingAppStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProcessingOnboardingAppState {
  if (json == null) {
    return json;
  }
  return {
    appState: ProcessingOnboardingAppStateDtoFromJSON(json['appState']),
  };
}

export function ProcessingOnboardingAppStateToJSON(
  value?: ProcessingOnboardingAppState | null
): any {
  if (value == null) {
    return value;
  }
  return {
    appState: ProcessingOnboardingAppStateDtoToJSON(value['appState']),
  };
}

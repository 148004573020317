import { isAfter, isBefore, sub } from 'date-fns';
import { object, string } from 'yup';

export const INVALID_DATE_ERROR_MESSAGE = 'Invalid Date';
export const MUST_BE_18_ERROR_MESSAGE = 'Must be 18 years of age to continue';
export const MUST_BE_UNDER_125_ERROR_MESSAGE =
  'Must be under 125 years of age to continue';

export const dateOfBirthInputSchema = object().shape({
  month: string()
    .required('Month is required')
    .test('is-valid-range', 'Month must be from 1 - 12', (value) => {
      if (value === '●●') return true;
      const num = parseInt(value, 10);
      return num >= 1 && num <= 12;
    })
    .matches(/^[0-9●]*$/, { message: 'Month must be numbers only' }),
  day: string()
    .required('Day is required')
    .test('is-valid-range', 'Day must be from 1 - 31', (value) => {
      if (value === '●●') return true;
      const num = parseInt(value, 10);
      return num >= 1 && num <= 31;
    })
    .matches(/^[0-9●]*$/, { message: 'Day must be numbers only' }),
  year: string()
    .required('Year is required')
    .min(4, 'Year must be 4 numbers')
    .matches(/^[0-9●]*$/, { message: 'Day must be numbers only' })
    .test('is-not-valid-dob', 'Invalid Date', (value, context) => {
      const day = context.parent.day;
      const month = context.parent.month;

      if (value.length !== 4 || day.length === 0 || month.length === 0) {
        return true;
      }

      const inputs = [value, month, day];
      if (!inputs.every(isValidNumber)) {
        return true;
      }

      const yearAsInt = parseInt(value, 10);
      const monthAsInt = parseInt(month, 10);
      const dayAsInt = parseInt(day, 10);

      return isValidDate({ year: yearAsInt, month: monthAsInt, day: dayAsInt });
    })
    .test(
      'must-be-18',
      'Must be 18 years of age to continue',
      (value, context) => {
        const day = context.parent.day;
        const month = context.parent.month;

        if (value.length !== 4 || day.length === 0 || month.length === 0) {
          return true;
        }

        const inputs = [value, month, day];
        if (!inputs.every(isValidNumber)) {
          return true;
        }

        const yearAsInt = parseInt(value, 10);
        const monthAsInt = parseInt(month, 10);
        const dayAsInt = parseInt(day, 10);

        const fullDateOfBirth = new Date(yearAsInt, monthAsInt - 1, dayAsInt);

        const minYear = sub(new Date(), { years: 18 });
        return isBefore(fullDateOfBirth, minYear);
      }
    )
    .test(
      'must-be-under-125',
      'Must be under 125 years of age to continue',
      (value, context) => {
        const day = context.parent.day;
        const month = context.parent.month;

        if (value.length !== 4 || day.length === 0 || month.length === 0) {
          return true;
        }

        const inputs = [value, month, day];
        if (!inputs.every(isValidNumber)) {
          return true;
        }

        const yearAsInt = parseInt(value, 10);
        const monthAsInt = parseInt(month, 10);
        const dayAsInt = parseInt(day, 10);

        const fullDateOfBirth = new Date(yearAsInt, monthAsInt - 1, dayAsInt);

        const maxYear = sub(new Date(), { years: 125 });
        return isAfter(fullDateOfBirth, maxYear);
      }
    ),
});

function isValidDate({
  day,
  month,
  year,
}: {
  day: number;
  month: number;
  year: number;
}) {
  // Check months with 31 days in them
  if ([1, 3, 5, 7, 8, 10, 12].includes(month) && day < 32) {
    return true;
  }
  // Check months with 30 days in them
  else if ([4, 6, 9, 11].includes(month) && day < 31) {
    return true;
  }
  // Check February
  else {
    // Consider leap year
    if (year % 4 === 0 && day < 30) {
      return true;
    }
    // For non leap years
    else {
      return day < 29;
    }
  }
}

function isValidNumber(value: string) {
  return /^[0-9]+$/.test(value);
}

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to create an authorized user invitation.
 * @export
 * @interface InviteAuthorizedUserRequest
 */
export interface InviteAuthorizedUserRequest {
  /**
   *
   * @type {string}
   * @memberof InviteAuthorizedUserRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof InviteAuthorizedUserRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof InviteAuthorizedUserRequest
   */
  email: string;
}

/**
 * Check if a given object implements the InviteAuthorizedUserRequest interface.
 */
export function instanceOfInviteAuthorizedUserRequest(
  value: object
): value is InviteAuthorizedUserRequest {
  if (!('firstName' in value) || value['firstName'] === undefined) return false;
  if (!('lastName' in value) || value['lastName'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  return true;
}

export function InviteAuthorizedUserRequestFromJSON(
  json: any
): InviteAuthorizedUserRequest {
  return InviteAuthorizedUserRequestFromJSONTyped(json, false);
}

export function InviteAuthorizedUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteAuthorizedUserRequest {
  if (json == null) {
    return json;
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
  };
}

export function InviteAuthorizedUserRequestToJSON(
  value?: InviteAuthorizedUserRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    firstName: value['firstName'],
    lastName: value['lastName'],
    email: value['email'],
  };
}

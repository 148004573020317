import { Heading, Button, Text, Stack } from '@chakra-ui/react';
import { PersonDtoRolesEnum } from 'api/wallet-app';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { IoAddCircleOutline } from 'react-icons/io5';
import { InfoCard, InfoCardBadgeValue } from '../info-card';
import { useMemo } from 'react';

export default function BeneficialOwnerSection() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      onboardingData: { people },
    },
  } = OnboardingContext.useSelector((state) => state);

  const beneficialOwners = useMemo(() => {
    return people.filter((person) => {
      return (
        person.roles?.length === 1 &&
        person.roles.includes(PersonDtoRolesEnum.BeneficialOwner)
      );
    });
  }, [people]);

  const hasControllerWhoIsABo = useMemo(() => {
    return beneficialOwners.some(
      (person) => person.roles && person.roles?.length > 1
    );
  }, [beneficialOwners]);

  function handleClick(id?: string) {
    const person = people.find((person) => person.id == id);
    if (!person) {
      return;
    }
    send({
      type: 'updatePersonBeingEdited',
      personDetails: person,
    });
  }

  return (
    <Stack spacing={3}>
      <Heading size="sm">
        {hasControllerWhoIsABo
          ? 'Other Beneficial Owners'
          : 'Beneficial Owners'}
      </Heading>
      <Text>
        All individuals who own 25% or more must be added to the business
      </Text>
      <Stack>
        {beneficialOwners.length > 0 &&
          beneficialOwners.map((owner) => {
            if (owner.roles && owner.roles.length > 1) {
              return null;
            } else {
              return (
                <InfoCard
                  key={owner.id}
                  title={`${owner.firstName} ${owner.lastName}`}
                  value={InfoCardBadgeValue.READY_TO_SUBMIT}
                  onClickEdit={() => handleClick(owner?.id)}
                  id={owner.id}
                  canDelete={true}
                />
              );
            }
          })}
      </Stack>
      {beneficialOwners.length < 5 && (
        <Button
          leftIcon={<IoAddCircleOutline fontSize="25px" />}
          variant="secondary"
          onClick={() => {
            send({
              type: 'updatePersonBeingEdited',
              personDetails: {
                roles: [PersonDtoRolesEnum.BeneficialOwner],
              },
            });
          }}
          width="auto"
          alignSelf="flex-start"
          textTransform="none"
          fontSize={14}
        >
          Add an Owner
        </Button>
      )}
    </Stack>
  );
}

import { useState } from 'react';
import { PanelWrapper } from 'components/layout/onboarding/panelWrapper';
import { Stack } from '@chakra-ui/react';
import { AppStateResponse, OnboardingDataDto } from 'api/wallet-app';
import { OnboardingHeader } from './components';
import {
  ControllerAttestation,
  DeadEnd,
  LlcBusinessDetails,
  Overview,
  TypeOfBusiness,
  Address,
  Ssn,
  SolePropBusinessDetails,
  PersonalInfo,
  SubmitOnboardingLoadingScreen,
  AdditionalInfo,
  PenaltyOfPerjury,
} from './steps';
import { OnboardingContext } from 'contexts/OnboardingContext';

export default function Onboarding({
  defaultOnboardingData,
  appStateType,
}: {
  defaultOnboardingData: OnboardingDataDto | undefined;
  appStateType: AppStateResponse['type'];
}) {
  const [machineKey, setMachineKey] = useState(1);

  return (
    <OnboardingContext.Provider
      key={machineKey}
      options={{
        input: {
          defaultOnboardingData: defaultOnboardingData ?? { people: [] },
          resetMachine: () => setMachineKey((prev) => prev + 1),
          defaultAppStateType: appStateType,
        },
      }}
    >
      <PanelWrapper>
        <Stack spacing={6} w="100%">
          <OnboardingHeader />
          <OnboardingStepDisplayer />
        </Stack>
      </PanelWrapper>
    </OnboardingContext.Provider>
  );
}

function OnboardingStepDisplayer() {
  const machineValue = OnboardingContext.useSelector((state) => state.value);

  switch (machineValue) {
    case 'TypeOfBusiness':
      return <TypeOfBusiness />;
    case 'Overview':
      return <Overview />;
    case 'SolePropBusinessDetails':
      return <SolePropBusinessDetails />;
    case 'PersonalInfo':
      return <PersonalInfo />;
    case 'Address':
      return <Address />;
    case 'Ssn':
      return <Ssn />;
    case 'AreYouAController':
      return <ControllerAttestation />;
    case 'DeadEnd':
      return <DeadEnd />;
    case 'LlcBusinessDetails':
      return <LlcBusinessDetails />;
    case 'Submit':
      return <SubmitOnboardingLoadingScreen />;
    case 'AdditionalInfo':
      return <AdditionalInfo />;
    case 'PenaltyOfPerjury':
      return <PenaltyOfPerjury />;
  }
}

import { create } from 'zustand';
import { FundingSource } from './types/App/fundingSources';

export type WalletStore = {
  walletId: string | undefined;
  balance: string | undefined;
  fundingSources: FundingSource[];
  setFundingSources: (fundingSources: FundingSource[]) => void;
  setWalletId: (walletId: string) => void;
};

export const useWalletStore = create<WalletStore>((set) => ({
  walletId: undefined,
  balance: undefined,
  fundingSources: [],
  setFundingSources: (fundingSources: FundingSource[]) =>
    set(() => ({ fundingSources: fundingSources })),
  setWalletId: (walletId: string) => set(() => ({ walletId: walletId })),
}));

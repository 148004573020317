import { Dispatch } from 'react';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import currency from 'currency.js';
import { RequestContext, RequestEvent } from './requestReducer';
import { useRequestPayment } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmForm, confirmFormResolver } from '../confirmFormResolver';

export const Confirm = ({
  send,
  context,
}: {
  send: Dispatch<RequestEvent>;
  context: RequestContext;
}) => {
  const { amount, requestee } = context;
  const { mutate: requestPayment, isPending: requestPaymentIsPending } =
    useRequestPayment();

  const methods = useForm<ConfirmForm>({
    defaultValues: { memo: '' },
    resolver: confirmFormResolver,
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = methods;

  function onSubmit(formData: ConfirmForm) {
    requestPayment(
      {
        amount: parseFloat(amount.value),
        memo: formData.memo,
        payerWalletId: requestee?.id as string,
      },
      {
        onSuccess: () => {
          send({ type: 'NEXT' });
        },
      }
    );
  }

  return !requestee ? (
    <Center minHeight="170px">
      <Spinner size="xl" thickness="4px" color="blue.400" />
    </Center>
  ) : (
    <Stack spacing={6}>
      <Stack>
        <Text fontSize="sm" color="#718096">
          Amount
        </Text>
        <Heading
          as="h3"
          fontSize="24px"
          py={3}
          px={4}
          border="solid 1px"
          borderColor="#E2E8F0"
          borderRadius="xl"
        >
          {currency(amount.value).format()}
        </Heading>
      </Stack>
      <Stack>
        <Text fontSize="sm" color="#718096">
          To
        </Text>
        <HStack
          color="gray.500"
          py={3}
          px={4}
          border="solid 1px"
          borderColor="#E2E8F0"
          borderRadius="xl"
          justify="space-between"
          fontFamily="inter"
        >
          <Text fontWeight="bold">{requestee?.name}</Text>
          <Text>{requestee?.location}</Text>
        </HStack>
      </Stack>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <FormControl isInvalid={!!errors?.memo}>
              <FormLabel fontSize="sm" color="#718096">
                Request Memo
              </FormLabel>
              <Textarea
                placeholder="Required - 256 character limit"
                p={4}
                id="memo"
                {...register('memo')}
              />
              <FormErrorMessage>{errors?.memo?.message}</FormErrorMessage>
            </FormControl>
            <Button
              isLoading={requestPaymentIsPending}
              isDisabled={!watch('memo')}
              type="submit"
            >
              Request {currency(amount.value).format()}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

import { Button, Flex, Heading, Stack, Image, Text } from '@chakra-ui/react';
import { PanelWrapperStyle } from 'components/layout/onboarding/general';
import { useUnsubscribe } from 'hooks/api';
import walletLogo from 'images/wallet-print-logo.png';
import { useSearchParams } from 'react-router-dom';

export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const { mutate: unsubscribe, isSuccess, isPending } = useUnsubscribe();
  return (
    <PanelWrapperStyle panelProps={{ p: [0, 0, 0] }} showBushelLogo={false}>
      <Stack spacing={6} pt={6} pb={[0, 12]} h="100%" justify="space-between">
        <Stack spacing={4} align="center">
          <Image src={walletLogo} w="231px" />
          <Stack
            bgColor="#F7FAFC"
            spacing={4}
            px={[6, 12]}
            py={4}
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="border"
            w="100%"
          >
            <Heading as="h3" size="h3">
              {isSuccess ? 'Success' : 'Are you certain?'}
            </Heading>
            <Text>
              {isSuccess
                ? "We've received your request to opt-out."
                : "You'll no longer receive this type of communication from this sender."}
            </Text>
          </Stack>
        </Stack>
        {!isSuccess && (
          <Flex px={[6, 12]}>
            <Button
              onClick={() => {
                const tokenSearchParam = searchParams.get('token');
                if (tokenSearchParam) {
                  unsubscribe(tokenSearchParam);
                }
              }}
              isLoading={isPending}
            >
              Unsubscribe
            </Button>
          </Flex>
        )}
      </Stack>
    </PanelWrapperStyle>
  );
}

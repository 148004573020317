import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';

export default function ConfirmOnboardingSubmissionModal({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { send } = OnboardingContext.useActorRef();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ready to submit?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Please review your information to ensure everything is accurate. Once
          submitted, changes may need additional verification.
        </ModalBody>
        <ModalFooter alignItems="flex-end">
          <Button variant="secondary" onClick={onClose} width="auto" mr={2}>
            Cancel
          </Button>
          <Button
            width="auto"
            onClick={() => {
              // We have a dedicated loading page, so we send them there to view a loading state
              // instead of doing a loading state on this page.
              onClose();
              send({ type: 'submit' });
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

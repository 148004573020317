/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  DefaultErrorResponse,
  ProfilesResponse,
  ViolationsError,
  WalletProfileDto,
} from '../models/index';
import {
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  ProfilesResponseFromJSON,
  ProfilesResponseToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
  WalletProfileDtoFromJSON,
  WalletProfileDtoToJSON,
} from '../models/index';

export interface GetWalletProfileRequest {
  xWalletId: string;
  id: string;
}

export interface SearchWalletProfilesRequest {
  xWalletId: string;
  searchValue?: string;
}

/**
 *
 */
export class WalletProfilesApi extends runtime.BaseAPI {
  /**
   * Get the profile of a wallet
   */
  async getWalletProfileRaw(
    requestParameters: GetWalletProfileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WalletProfileDto>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getWalletProfile().'
      );
    }

    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getWalletProfile().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/profiles/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WalletProfileDtoFromJSON(jsonValue)
    );
  }

  /**
   * Get the profile of a wallet
   */
  async getWalletProfile(
    requestParameters: GetWalletProfileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WalletProfileDto> {
    const response = await this.getWalletProfileRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Search wallet profiles.
   */
  async searchWalletProfilesRaw(
    requestParameters: SearchWalletProfilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProfilesResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling searchWalletProfiles().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['searchValue'] != null) {
      queryParameters['searchValue'] = requestParameters['searchValue'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/profiles`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProfilesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Search wallet profiles.
   */
  async searchWalletProfiles(
    requestParameters: SearchWalletProfilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProfilesResponse> {
    const response = await this.searchWalletProfilesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

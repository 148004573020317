import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  HStack,
  Text,
  useDisclosure,
  Stack,
  Center,
  ModalHeader,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import MicrodepositScreenshot from 'images/microdeposit-screenshot.png';
import BankIcon from 'images/bank-icon.svg';
import { IoTrashOutline } from 'react-icons/io5';
import { useRemoveFundingSource } from 'hooks/api';
import { FundingSourceDto } from 'api/wallet-app';
import { usePlaidSdk } from 'hooks';

function VerifyManualMicro({
  fundingSource,
}: {
  fundingSource: FundingSourceDto;
}) {
  const { plaidSdkIsReady, generatePlaidLinkTokenIsPending, openPlaidSdk } =
    usePlaidSdk();

  const {
    onOpen: onInstructionOpen,
    onClose: onInstructionClose,
    isOpen: isInstructionOpen,
  } = useDisclosure();

  return (
    <>
      <Flex
        alignItems="center"
        textTransform="uppercase"
        color="blue.400"
        cursor="pointer"
        onClick={onInstructionOpen}
        fontSize="14px"
      >
        <Text fontWeight="bold">Verify Account</Text>
      </Flex>
      <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
        <ModalOverlay background="gray.50" />
        <ModalContent padding="1rem" margin="auto">
          <Flex justifyContent="space-between" alignItems="center" px="24px">
            <CloseIcon
              fontSize="12px"
              cursor="pointer"
              onClick={onInstructionClose}
            />
            <Text fontFamily="inter" fontSize="14px" color="gray.500">
              Microdeposit Verification
            </Text>
            <Flex />
          </Flex>
          <ModalBody mt="3rem" maxWidth="400px">
            <Flex direction="column">
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                1
              </Flex>
              <Text mt="12px" mb="1.5rem" fontFamily="inter" fontSize="16px">
                Check your bank account ending in {fundingSource.accountNumber}.
              </Text>
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                2
              </Flex>
              <Text mt="12px" fontFamily="inter" fontSize="16px">
                Find the $0.01 microdeposit we sent and look for the 3-letter
                code in the description
              </Text>
              <Image mb="2rem" mt="8px" src={MicrodepositScreenshot} />
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                3
              </Flex>
              <Text mt="12px" mb="1.5rem" fontFamily="inter" fontSize="16px">
                If you know your{' '}
                <span style={{ color: 'red' }}>3-letter code</span>, continue to
                the next page
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter mt="2rem">
            <Button
              isLoading={!plaidSdkIsReady && generatePlaidLinkTokenIsPending}
              onClick={() => {
                onInstructionClose();
                openPlaidSdk();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const PendingFundingSource = ({
  fundingSource,
}: {
  fundingSource: FundingSourceDto;
}) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const {
    mutateAsync: removeFundingSourceAsync,
    isPending: removeFundingSourceIsPending,
  } = useRemoveFundingSource();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        backgroundColor="gray.50"
        padding={['1rem 1rem 1rem 10px', '1rem']}
        fontFamily="inter"
      >
        <Stack spacing={4} w="100%">
          <HStack>
            <HStack w="100%" spacing={[6, 0]}>
              <Flex width={['30px', '65px']} opacity="40%">
                {fundingSource.icon ? (
                  <Image
                    src={atob(fundingSource.icon)}
                    width="35px"
                    height="35px"
                    objectFit="contain"
                  />
                ) : (
                  <Image width="30px" height="30px" src={BankIcon} />
                )}
              </Flex>
              <Stack color="gray.400" fontStyle="italic">
                <Heading
                  as="h4"
                  fontSize="18px"
                  maxWidth={['180px', '300px', '500px', '500px', '900px']}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {fundingSource.name}
                </Heading>
                <Text fontWeight="bold">Pending</Text>
              </Stack>
            </HStack>
            <HStack spacing={4} minW="fit-content">
              {!isMobile && <VerifyManualMicro fundingSource={fundingSource} />}
              <IconButton
                icon={<IoTrashOutline size={18} />}
                aria-label="Trash Icon"
                variant="ghost"
                size="icon"
                color="black"
                w="fit-content"
                p={2}
                onClick={onOpen}
              />
            </HStack>
          </HStack>
          {isMobile && (
            <Center>
              <VerifyManualMicro fundingSource={fundingSource} />
            </Center>
          )}
        </Stack>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={!removeFundingSourceIsPending}
        size="xs"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Account</ModalHeader>
          <ModalBody>
            This account will be permanently removed from your wallet
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <Button
                variant="secondary"
                onClick={onClose}
                isDisabled={removeFundingSourceIsPending}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                isLoading={removeFundingSourceIsPending}
                onClick={async () => {
                  await removeFundingSourceAsync(
                    fundingSource.fundingSourceId
                  ).finally(() => {
                    onClose();
                  });
                }}
              >
                Remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import {
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { InviteAuthorizedUserRequest } from 'api/wallet-app';
import { SinglePanelView } from 'pages/WalletApp/Transfers/components/SinglePanelView';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { emailRegex } from 'utils/helpers';
import ConfirmInvite from './ConfirmInvite';

export type InviteUserToWallet = Omit<InviteAuthorizedUserRequest, 'walletId'>;

export default function InviteUser() {
  const [user, setUser] = useState<InviteUserToWallet>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<InviteUserToWallet>({
    defaultValues: user,
    mode: 'onBlur',
  });

  function onSubmit(data: InviteUserToWallet) {
    setUser(data);
  }

  return (
    <SinglePanelView displayBackgroundImage={false}>
      <Stack
        spacing={5}
        padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      >
        {isSubmitSuccessful && (
          <ConfirmInvite
            user={user}
            onBack={() => {
              reset(user, { keepValues: true });
            }}
          />
        )}
        {!isSubmitSuccessful && (
          <>
            <CloseButton
              color="black"
              variant="unstyled"
              boxSize={6}
              _hover={{ bgColor: 'gray.100' }}
              onClick={() => navigate(-1)}
            />
            <Stack spacing={6}>
              <Heading>Invite a staff member to your Wallet</Heading>
              <Text color="textSecondary">
                Grant other employees access to your Wallet account. This invite
                expires in 7 days.
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={12}>
                  <Stack spacing={6}>
                    <FormControl isInvalid={!!errors.firstName}>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        {...register('firstName', {
                          required: {
                            value: true,
                            message: 'First name is required',
                          },
                          maxLength: {
                            value: 50,
                            message: 'First name must be 50 characters or less',
                          },
                          pattern: {
                            value: /^[^0-9]*$/,
                            message: 'First name cannot contain a number',
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.firstName?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.lastName}>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        {...register('lastName', {
                          required: {
                            value: true,
                            message: 'Last name is required',
                          },
                          maxLength: {
                            value: 50,
                            message: 'Last name must 50 characters or less',
                          },
                          pattern: {
                            value: /^[^0-9]*$/,
                            message: 'Last name cannot contain a number',
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.lastName?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        {...register('email', {
                          required: {
                            value: true,
                            message: 'Email is required',
                          },
                          pattern: {
                            value: emailRegex,
                            message: 'Please enter a valid email',
                          },
                          maxLength: {
                            value: 256,
                            message: 'Email must be 256 characters or less',
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Button
                    isDisabled={!isDirty}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Next
                  </Button>
                </Stack>
              </form>
            </Stack>
          </>
        )}
      </Stack>
    </SinglePanelView>
  );
}

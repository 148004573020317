import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import BushelLogo from 'images/Logo/Product/Wallet.svg';
import { IoLogOutOutline } from 'react-icons/io5';
import { useKeycloak } from '@react-keycloak/web';
import { GoBack } from 'pages/onboarding/components';

type HeaderProps = {
  children?: React.ReactNode;
  title?: string;
  backButton?: boolean;
  logoutButton?: boolean;
  customButton?: React.ReactNode;
};
export const Header = React.memo(
  ({
    children,
    title,
    backButton = true,
    logoutButton = false,
    customButton,
  }: HeaderProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);
    const { keycloak } = useKeycloak();

    const handleLogout = () => {
      keycloak.logout();
    };

    if (customButton && logoutButton) {
      throw new Error(
        "You can't have both a customButton and a logoutButton in the same spot"
      );
    }

    return (
      <>
        <Stack spacing={6} w="100%">
          <HStack justify="space-between" w="100%">
            {backButton ? (
              <Flex flex={1} justify="flex-start">
                <GoBack />
              </Flex>
            ) : (
              <Spacer flex={1} />
            )}
            <Image src={BushelLogo} height={18} flex={1} />
            {logoutButton ? (
              <Flex flex={1} justify="flex-end">
                <Icon
                  boxSize={6}
                  cursor="pointer"
                  onClick={onOpen}
                  as={IoLogOutOutline}
                />
              </Flex>
            ) : (
              <Spacer flex={1} />
            )}
            {customButton && (
              <Flex flex={1} justify="flex-end">
                {customButton}
              </Flex>
            )}
          </HStack>
          {title && (
            <Heading mb={4} fontFamily="heading" fontSize={26}>
              {title}
            </Heading>
          )}
          {children && <>{children}</>}
        </Stack>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontFamily="heading">
              Exit Onboarding?
            </AlertDialogHeader>
            <AlertDialogBody>
              If you exit now, you'll be logged out.
            </AlertDialogBody>
            <AlertDialogFooter width="60%" alignSelf="flex-end">
              <Button
                ref={cancelRef.current}
                onClick={onClose}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={handleLogout} ml={3}>
                Log Out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
);

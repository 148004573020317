/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingDataDto } from './OnboardingDataDto';
import {
  OnboardingDataDtoFromJSON,
  OnboardingDataDtoFromJSONTyped,
  OnboardingDataDtoToJSON,
} from './OnboardingDataDto';

/**
 *
 * @export
 * @interface ResubmissionNeededStateDto
 */
export interface ResubmissionNeededStateDto {
  /**
   *
   * @type {string}
   * @memberof ResubmissionNeededStateDto
   */
  walletId: string;
  /**
   *
   * @type {OnboardingDataDto}
   * @memberof ResubmissionNeededStateDto
   */
  onboardingData: OnboardingDataDto;
}

/**
 * Check if a given object implements the ResubmissionNeededStateDto interface.
 */
export function instanceOfResubmissionNeededStateDto(
  value: object
): value is ResubmissionNeededStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('onboardingData' in value) || value['onboardingData'] === undefined)
    return false;
  return true;
}

export function ResubmissionNeededStateDtoFromJSON(
  json: any
): ResubmissionNeededStateDto {
  return ResubmissionNeededStateDtoFromJSONTyped(json, false);
}

export function ResubmissionNeededStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResubmissionNeededStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
    onboardingData: OnboardingDataDtoFromJSON(json['onboardingData']),
  };
}

export function ResubmissionNeededStateDtoToJSON(
  value?: ResubmissionNeededStateDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    onboardingData: OnboardingDataDtoToJSON(value['onboardingData']),
  };
}

import { Capacitor } from '@capacitor/core';
import { Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import {
  desktopHeaderHeight,
  desktopHeaderMargin,
  background,
  bushelLogo,
} from 'utils/helpers';
import OnboardingPanel from './PanelStyle';

export default function PanelWrapperStyle({
  children,
  fullWidth = false,
  panelProps,
  showBushelLogo = true,
}: PropsWithChildren<{
  fullWidth?: boolean;
  panelProps?: FlexProps;
  showBushelLogo?: boolean;
}>) {
  const platform = Capacitor.getPlatform();

  // have to wrap everything in a fixed container
  // because mobile layouts don't play well with 100vh on
  // browsers when you include address bar / other chrome
  return (
    <Box
      position="fixed"
      height="100%"
      width="100%"
      overflow="scroll"
      pb={platform == 'android' ? 0 : '2rem'}
      className="panelwrapper"
    >
      <Flex
        flex="1"
        minWidth="100%"
        height="100%"
        flexDirection="column"
        alignItems="space-between"
      >
        <Flex
          className="header"
          width="100%"
          height={desktopHeaderHeight}
          justifyContent="center"
          alignItems="center"
          display={['none', 'flex']}
        >
          {showBushelLogo && (
            <Image height="24px" src={bushelLogo} alt="Bushel Logo" />
          )}
        </Flex>
        <Flex
          className="content"
          flexDirection="column"
          width={['auto', '100%']}
          minHeight={[
            '100%',
            `calc(100vh - ${desktopHeaderHeight} - ${desktopHeaderMargin} )`,
          ]}
          backgroundImage={['null', background]}
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          backgroundSize="contain"
          mt={[0, '2rem']}
        >
          {fullWidth ? (
            children
          ) : (
            <OnboardingPanel rest={panelProps}>{children}</OnboardingPanel>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

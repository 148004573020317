import { Text, Stack } from '@chakra-ui/react';
import { InfoCard, InfoCardBadgeValue } from 'pages/onboarding/components';
import { OnboardingContext } from 'contexts/OnboardingContext';

export default function BusinessInfoSection({
  onClickEdit,
}: {
  onClickEdit: () => void;
}) {
  const {
    context: {
      onboardingData: {
        business: {
          businessName,
          doingBusinessAs,
          industryClassificationCode,
        } = {},
      },
    },
  } = OnboardingContext.useSelector((state) => state);

  return (
    <Stack spacing={3}>
      <Text fontWeight="bold" size="sm">
        Business Info
      </Text>
      <InfoCard
        title={businessName ?? doingBusinessAs ?? 'Business Information'}
        value={
          !!industryClassificationCode
            ? InfoCardBadgeValue.READY_TO_SUBMIT
            : InfoCardBadgeValue.NEEDS_INFO
        }
        onClickEdit={onClickEdit}
      />
    </Stack>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BusinessInvitation
 */
export interface BusinessInvitation {
  /**
   *
   * @type {string}
   * @memberof BusinessInvitation
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInvitation
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInvitation
   */
  phoneNumber?: string;
  /**
   *
   * @type {Date}
   * @memberof BusinessInvitation
   */
  dateInvited: Date;
  /**
   *
   * @type {boolean}
   * @memberof BusinessInvitation
   */
  canBeResent: boolean;
}

/**
 * Check if a given object implements the BusinessInvitation interface.
 */
export function instanceOfBusinessInvitation(
  value: object
): value is BusinessInvitation {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('dateInvited' in value) || value['dateInvited'] === undefined)
    return false;
  if (!('canBeResent' in value) || value['canBeResent'] === undefined)
    return false;
  return true;
}

export function BusinessInvitationFromJSON(json: any): BusinessInvitation {
  return BusinessInvitationFromJSONTyped(json, false);
}

export function BusinessInvitationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInvitation {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    email: json['email'],
    phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    dateInvited: new Date(json['dateInvited']),
    canBeResent: json['canBeResent'],
  };
}

export function BusinessInvitationToJSON(
  value?: BusinessInvitation | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    email: value['email'],
    phoneNumber: value['phoneNumber'],
    dateInvited: value['dateInvited'].toISOString(),
    canBeResent: value['canBeResent'],
  };
}

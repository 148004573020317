import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { datadogRum } from '@datadog/browser-rum';
import { createMatomoTracker } from 'utils/matomo';
import { useWalletStore } from './stores';
import { useAppState } from 'hooks/api';
import { AppStateResponse } from 'api/wallet-app';
import { Onboarding } from 'pages/onboarding';
import WalletApp from 'pages/WalletApp/WalletApp';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { PanelWrapper } from 'components/layout/onboarding';
import { Heading } from '@chakra-ui/react';
import { Review } from 'pages/review';
import { SdkUpload } from 'pages/sdk-upload';

if (window.location.href == 'https://app.bushelwallet.com/') {
  datadogRum.init({
    applicationId: '713cd936-81e3-43d2-ab4e-a23c3cc7c9c1',
    clientToken: 'pub43a48a60de428b0c0a64b739e789da67',
    site: 'datadoghq.com',
    service: 'wallet-desktop-app',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  createMatomoTracker('https://matomo.bushelops.com', '14');
}

export const App = () => {
  const { keycloak } = useKeycloak();
  const { data: appStateData, isLoading: appStateIsLoading } = useAppState();

  window.logout = keycloak.logout;

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  // TODO: Should we do something else if appStateData is undefined?
  return appStateIsLoading || !appStateData ? (
    <CenteredSpinner />
  ) : (
    <AppDisplayer appState={appStateData} />
  );
};

function AppDisplayer({ appState }: { appState: AppStateResponse }) {
  const walletId = useWalletStore((state) => state.walletId);
  const setWalletId = useWalletStore((state) => state.setWalletId);

  useEffect(() => {
    if (!walletId) {
      if (appState.type === 'NORMAL') {
        setWalletId(appState.appState.walletId);
      } else if (appState.type === 'UNDER_REVIEW') {
        setWalletId(appState.reviewState.walletId);
      } else if (appState.type === 'PROCESSING_ONBOARDING') {
        setWalletId(appState.appState.walletId);
      }
    }
  }, [appState, walletId]);

  switch (appState.type) {
    case 'NORMAL':
      if (!walletId) {
        return <CenteredSpinner />;
      } else {
        return <WalletApp />;
      }
    case 'ONBOARDING':
      return (
        <Onboarding
          defaultOnboardingData={appState.appState.onboardingData}
          appStateType={appState.type}
        />
      );
    case 'UNDER_REVIEW':
    case 'PROCESSING_ONBOARDING':
      if (!walletId) {
        return <CenteredSpinner />;
      } else {
        return <Review />;
      }
    case 'SDK_UPLOAD':
      return <SdkUpload />;
    case 'RESUBMISSION_NEEDED':
      return (
        <Onboarding
          defaultOnboardingData={appState.appState.onboardingData}
          appStateType={appState.type}
        />
      );
    default:
      return (
        <PanelWrapper>
          <Heading>Current App State not supported</Heading>
        </PanelWrapper>
      );
  }
}

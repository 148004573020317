import { useQuery } from '@tanstack/react-query';
import useWalletProfilesClient from './useWalletProfilesClient';
import { useWalletStore } from 'stores';

export const WALLET_PROFILE_QUERY_KEY = 'wallet-profile';

export default function useWalletProfile(walletIdToSearchFor?: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const walletsClient = useWalletProfilesClient();
  return useQuery({
    queryKey: [WALLET_PROFILE_QUERY_KEY, walletIdToSearchFor],
    queryFn: () => {
      return walletsClient.getWalletProfile({
        xWalletId: walletId,
        id: walletIdToSearchFor!,
      });
    },
    enabled: !!walletIdToSearchFor,
    retry: true,
  });
}

import { useState } from 'react';
import {
  Heading,
  Image,
  Button,
  Text,
  Stack,
  Divider,
  HStack,
} from '@chakra-ui/react';
import plaidDone from 'images/plaid-done.svg';
import notifications from 'images/notifications.png';
import { usePlaidSdk } from 'hooks';
import SendReceive from 'images/plaid/sendreceive.svg';
import Lock from 'images/plaid/lock.svg';
import List from 'images/plaid/list.svg';
import Bank from 'images/bank.png';

export const PLAID_SCREEN_KEY = 'display-plaid-screen';

export type PossibleScreens = 'initial' | 'micro' | 'success';

export default function AddFundingSource({
  progressFromScreen,
}: {
  progressFromScreen: () => void;
}) {
  const [screenToDisplay, setScreenToDisplay] =
    useState<PossibleScreens>('initial');
  const {
    plaidSdkIsReady,
    generatePlaidLinkTokenIsPending,
    addFundingSourceIsPending,
    openPlaidSdk,
  } = usePlaidSdk({
    onPlaidSuccess: ({ metadata }) => {
      if (
        metadata.accounts[0].verification_status ==
        'pending_manual_verification'
      ) {
        setScreenToDisplay('micro');
      } else {
        setScreenToDisplay('success');
      }
    },
  });

  // This is screen is intended to be viewed once, so we can delete the sessionStorage
  // item immediately when we get to this page
  sessionStorage.removeItem(PLAID_SCREEN_KEY);

  switch (screenToDisplay) {
    case 'initial':
      return (
        <Stack spacing={8}>
          <Stack spacing={6} align="center">
            <Image src={Bank} maxWidth="120px" />
            <Stack spacing={5}>
              <Heading as="h2" size="xl">
                Link Your Bank Account
              </Heading>
              <Stack spacing={3} divider={<Divider borderColor="gray.500" />}>
                <ListItem
                  title="Take Charge of Your Money"
                  icon={SendReceive}
                  body="Linking your Bushel Wallet account to your bank account gives you complete control of where your money is."
                />
                <ListItem
                  title="Seamless Bank Transfers"
                  icon={Lock}
                  body="Bushel uses Plaid for secure bank linking so you can seamlessly transfer your Bushel Wallet money to any of your banks."
                />
                <ListItem
                  title="Enhance Control with Multiple Banks"
                  icon={List}
                  body="For even greater control you can link multiple banks and remove linking at any time."
                />
              </Stack>
            </Stack>
          </Stack>
          <HStack>
            <Button
              variant="secondary"
              onClick={progressFromScreen}
              isDisabled={
                (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending
              }
            >
              Skip For Now
            </Button>
            <Button
              onClick={openPlaidSdk}
              isLoading={
                (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending
              }
            >
              Next
            </Button>
          </HStack>
        </Stack>
      );
    case 'micro':
      return (
        <Stack spacing={12}>
          <Stack spacing={6} align="center">
            <Image boxSize={10} src={notifications} />
            <Stack spacing={4} align="center">
              <Heading as="h2" size="xl">
                Link in Progress
              </Heading>
              <Text textAlign="center">
                You will receive a notification from us in about a day. Stay
                tuned!
              </Text>
            </Stack>
          </Stack>
          <Button width="100%" onClick={progressFromScreen}>
            Continue
          </Button>
        </Stack>
      );
    case 'success':
      return (
        <Stack justifyContent="center" alignItems="center" spacing={4}>
          <Image src={plaidDone} />
          <Text fontWeight="700" fontSize="24px" width="50%" textAlign="center">
            Account Linked Successfully
          </Text>
          <Button onClick={progressFromScreen}>Next</Button>
        </Stack>
      );
  }
}

const ListItem = ({
  title,
  icon,
  body,
}: {
  title: string;
  icon: string;
  body: string;
}) => {
  return (
    <HStack justify="center">
      <Image boxSize={6} src={icon} />
      <Stack direction="column" spacing={1}>
        <Text color="gray.700">{title}</Text>
        <Text color="gray.500">{body}</Text>
      </Stack>
    </HStack>
  );
};

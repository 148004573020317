import {
  BusinessInvitation,
  CompletedBusinessInvitation,
  SettingsInfoResponse,
  UserInviteListItem,
  UserInviteListItemInviteStatusEnum,
  UserListItem,
  UserListItemTypeEnum,
} from 'api/wallet-app';

export const WALLET_ID = '48438796-1436-462a-b1f2-fdb0224e978e';

export const completedInviteList: CompletedBusinessInvitation[] = [
  {
    dateInvited: new Date(),
    email: 'completedInvite1@mock.com',
    id: 'fe7dcfcd-3305-4c8a-b8e5-00348385b2a7',
    name: 'Completed Business Invite 1',
    walletId: '798a338e-910d-4edc-aa1e-dbbd8792c46e',
  },
  {
    dateInvited: new Date(),
    email: 'completedInvite2@mock.com',
    id: '908a8e45-4050-4967-b576-9f3159e8324d',
    name: 'Completed Business Invite 2',
    walletId: '3905eefc-3a89-400a-954f-76dff91f4f66',
  },
];

export const sentInviteList: BusinessInvitation[] = [
  {
    id: 'f7824bae-bdf1-4484-a485-337a88baf3f1',
    email: 'sentInvite1@mock.com',
    dateInvited: new Date(),
    canBeResent: true,
  },
  {
    id: 'e780a278-b5bc-4598-b574-2fb6a1294698',
    email: 'sentInvite2@mock.com',
    dateInvited: new Date(),
    canBeResent: false,
  },
];

export const settingsInfo: SettingsInfoResponse = {
  business: {
    businessName: 'Moo Moo Cow',
    doingBusinessAs: 'Moo Moo Cow DBA',
    businessAddress: {
      city: 'City',
      country: 'Country',
      postalCode: '12345',
      stateProvinceRegion: 'State',
      streetNumber1: 'Street Number 1',
      streetNumber2: 'Street Number 2',
    },
    handle: 'MooMooCow',
  },
  links: {
    debitCardActivation: 'https://www.google.com',
    faq: 'https://help.bushelpowered.com',
    policies: 'https://bushelwallet.com/policies',
    support: 'https://bushelwallet.com/contact-us',
  },
};

export const userList: UserListItem[] = [
  {
    id: '6cd406a0-b671-4e66-984c-580a05a00b6c',
    firstName: 'Mock',
    lastName: 'Owner',
    type: UserListItemTypeEnum.WalletOwner,
    email: 'owner@mock.com',
    phoneNumber: '1234567890',
  },
  {
    id: '146119ed-c809-48b2-8f0f-828cc8e33523',
    firstName: 'Mock',
    lastName: 'Authorized User 1',
    type: UserListItemTypeEnum.AuthorizedUser,
    email: 'authUser1@mock.com',
    phoneNumber: '7773338888',
  },
  {
    id: 'f7d90832-80d0-419e-a397-d43829200782',
    firstName: 'Mock',
    lastName: 'Authorized User 2',
    type: UserListItemTypeEnum.AuthorizedUser,
    email: 'authUser2@mock.com',
    phoneNumber: '2344321919',
  },
];

export const invitedAuthorizedUserList: UserInviteListItem[] = [
  {
    id: '9a273711-9eb1-4fba-85d8-ba5e411f4c29',
    firstName: 'Mock',
    lastName: 'Invite One',
    inviteStatus: UserInviteListItemInviteStatusEnum.Invited,
    email: 'userInvite1mock.com',
  },
  {
    id: '919148fd-aca4-4f81-8a1d-a7a9ab94fba7',
    firstName: 'Mock',
    lastName: 'Invite Two',
    inviteStatus: UserInviteListItemInviteStatusEnum.Invited,
    email: 'userInvite2@mock.com',
  },
];

import { Button, Heading, Stack, Text, Image, Flex } from '@chakra-ui/react';
import { PanelWrapperStyle } from 'components/layout/onboarding/general';
import walletLogo from 'images/wallet-print-logo.png';
import { useNavigate } from 'react-router-dom';

export default function InvalidLink() {
  const navigate = useNavigate();
  return (
    <PanelWrapperStyle panelProps={{ p: [0, 0, 0] }} showBushelLogo={false}>
      <Stack spacing={6} pt={6} pb={[0, 12]} h="100%" justify="space-between">
        <Stack spacing={4} align="center">
          <Image src={walletLogo} w="231px" />
          <Stack
            bgColor="#F7FAFC"
            spacing={4}
            px={[6, 12]}
            py={4}
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="border"
          >
            <Heading as="h3" size="h3">
              That link is expired
            </Heading>
            <Text>
              The link you clicked has already been used or is no longer valid.
            </Text>
          </Stack>
          <Heading as="h6" size="h6" px={[6, 12]}>
            If you started the account creation process and did not finish or
            you have an existing account, please log in instead.
          </Heading>
        </Stack>
        <Flex px={[6, 12]}>
          <Button
            onClick={() => {
              navigate('/');
            }}
          >
            Log In
          </Button>
        </Flex>
      </Stack>
    </PanelWrapperStyle>
  );
}

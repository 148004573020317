import { Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { Alerts, Transactions } from './components';
import { WalletBalanceHeader } from '../Accounts/components';
import { AddFundingSourceTile } from 'components/add-funding-source-tile';
import { useFundingSourceList } from 'hooks';
import { useMemo } from 'react';

export default function Home() {
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const { data: fundingSourceListData, isLoading: fundingSourceListIsLoading } =
    useFundingSourceList();
  const hasZeroFundingSources = useMemo(
    () =>
      !fundingSourceListIsLoading &&
      fundingSourceListData?.fundingSources?.length === 0,
    [fundingSourceListIsLoading, fundingSourceListData]
  );

  return (
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(5, 1fr)' }}
      templateRows={{ base: 'repeat(4, auto)', md: 'repeat(3, auto)' }}
      gap={{ base: 4, md: 6 }}
      pb={!isTablet ? '150px' : 4}
    >
      <GridItem colSpan={{ base: 1, md: 2 }}>
        <WalletBalanceHeader />
      </GridItem>
      {hasZeroFundingSources && (
        <GridItem
          rowStart={{ base: 'auto', md: 2 }}
          colSpan={{ base: 1, md: 2 }}
        >
          <AddFundingSourceTile />
        </GridItem>
      )}
      <GridItem
        rowStart={{
          base: 'auto',
          md: fundingSourceListData?.fundingSources?.length === 0 ? 3 : 2,
        }}
        colSpan={{ base: 1, md: 2 }}
      >
        <Alerts />
      </GridItem>
      <GridItem
        rowStart={{ base: 'auto', md: 2 }}
        colSpan={{ base: 1, md: 3 }}
        rowSpan={{ base: 1, md: 2 }}
      >
        <Transactions />
      </GridItem>
    </Grid>
  );
}

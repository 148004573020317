import magnifyingGlass from 'images/submit.gif';
import {
  Text,
  Heading,
  UnorderedList,
  ListItem,
  Progress,
  Stack,
  Image,
} from '@chakra-ui/react';
import { useAnimateProgressBar, useCyclingListItems } from './hooks';
import { usePollThenRefetchAppState, useSubmitOnboardingData } from 'hooks';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useEffect } from 'react';

export default function SubmitOnboardingLoadingScreen() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: { appStateType },
  } = OnboardingContext.useSelector((state) => state);

  const { mutate: submitOnboardingData, isIdle } = useSubmitOnboardingData();

  const listItems = useCyclingListItems();
  const progress = useAnimateProgressBar();
  const shouldProceed = usePollThenRefetchAppState({
    stateTypes:
      appStateType === 'ONBOARDING'
        ? ['ONBOARDING', 'PROCESSING_ONBOARDING']
        : ['RESUBMISSION_NEEDED', 'PROCESSING_ONBOARDING'],
  });

  useEffect(() => {
    if (isIdle) {
      submitOnboardingData(undefined, {
        onError: () => {
          send({ type: 'back' });
        },
      });
    }
  }, [isIdle]);

  return (
    <Stack>
      <Image src={magnifyingGlass} />
      <Stack spacing={8}>
        <Heading as="h2" size="xl">
          Confirming Your Information...
        </Heading>
        <UnorderedList color="gray.500" minHeight="105px" spacing={4}>
          {listItems.map((i, x) => {
            return <ListItem key={x}>{i}</ListItem>;
          })}
        </UnorderedList>
      </Stack>
      <Stack
        spacing={4}
        alignItems="center"
        p={['0 25px 20px 25px', '0 48px 48px']}
      >
        {!shouldProceed ? (
          <Text color="gray.500">Almost there...</Text>
        ) : (
          <Text color="green.500">Finished!</Text>
        )}
        <Progress
          value={shouldProceed ? 100 : progress}
          height="4px"
          borderRadius="lg"
          width="100%"
          colorScheme={shouldProceed ? 'green' : 'blue'}
        />
      </Stack>
    </Stack>
  );
}

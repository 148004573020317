import React, { PropsWithChildren } from 'react';
import { KeycloakConfig } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthLoading } from 'components/loading/AuthLoading';
import { App } from 'App';
import { ErrorProvider, ErrorFallback } from 'contexts/ErrorContext';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Capacitor } from '@capacitor/core';
import { debounce } from 'lodash';
import Keycloak from 'keycloak/capacitor-adapter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isSandbox, WalletQueryParams } from 'utils/helpers';
import { UnauthenticatedRoutes } from 'routes';

type ContextType = PropsWithChildren<{
  restApiBasePath: string;
  keycloakConfig: KeycloakConfig;
}>;

type App = {
  restApiBasePath: string;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AppContext = React.createContext<App | undefined>(undefined);

export const AppContextProvider = ({
  restApiBasePath,
  keycloakConfig,
}: ContextType) => {
  const walletQueryParams = new WalletQueryParams();
  const useOtherClient = React.useRef(false);
  const { realm, url, clientId } = keycloakConfig;

  // toggle allowance of creating accounts by Integration / Lending Partners
  if (isSandbox()) {
    useOtherClient.current = true;
  }

  if (walletQueryParams.hasValidPartner()) {
    useOtherClient.current = true;
  }

  if (walletQueryParams.hasToken()) {
    const integrationPartnerId =
      walletQueryParams.getLinkTokenIntegrationPartnerId();

    if (integrationPartnerId) {
      const isBlocked =
        walletQueryParams.isBlockedIntegrationPartnerId(integrationPartnerId);
      if (!isBlocked) {
        useOtherClient.current = true;
      }
    }
  }

  const getKeycloakInitOptions = () => {
    const sharedInit = {
      checkLoginIframe: false,
      onLoad: 'login-required',
      scope: useOtherClient.current === true ? 'register' : '',
    };

    return Capacitor.isNativePlatform()
      ? {
          ...sharedInit,
          redirectUri: 'bushelwallet://app',
          adapter: 'capacitor',
        }
      : {
          ...sharedInit,
          adapter: 'default',
        };
  };

  const initOptions = getKeycloakInitOptions();

  const keycloak = new Keycloak({
    realm,
    url,
    clientId,
  });

  const registerKeycloakProvider = new Keycloak({
    realm,
    url,
    clientId: 'wallet-app-client-register',
  });

  React.useEffect(() => {
    if (!keycloak.loadUserInfo) return;
    // log user out after 30 minutes of inactivity
    const body = document.getElementsByTagName('body')[0];
    let timer: any;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        keycloak.logout();
      }, 1800000);
    };

    ['click', 'mousemove', 'keyup'].forEach((e) =>
      body.addEventListener(
        e,
        debounce(() => resetTimer(), 1000)
      )
    );

    resetTimer();

    return () => {
      clearTimeout(timer);
      ['click', 'mousemove', 'keyup'].forEach((e) =>
        body.removeEventListener(
          e,
          debounce(() => resetTimer(), 1000)
        )
      );
    };
  }, [keycloak]);

  const authClient =
    useOtherClient.current && realm !== 'exchange'
      ? registerKeycloakProvider
      : keycloak;
  const authLoading = <AuthLoading keycloak={authClient} />;

  return (
    <AppContext.Provider value={{ restApiBasePath }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ErrorProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
            <BrowserRouter>
              <UnauthenticatedRoutes />
              <ReactKeycloakProvider
                authClient={authClient}
                LoadingComponent={authLoading}
                initOptions={initOptions}
              >
                <App />
              </ReactKeycloakProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </ErrorProvider>
      </ErrorBoundary>
    </AppContext.Provider>
  );
};

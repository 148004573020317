import { TextInput } from 'components/formComponents';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';
import { DateOfBirthInput } from '../../inputs/date-of-birth-input';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { personalInfoFormSchema } from './personal-info-form-schema';
import { RadioButtonGroupInput } from '../../inputs';
import { WarningIcon } from '@chakra-ui/icons';

export type PersonalInfoForm = {
  firstName: string;
  lastName: string;
  day: string;
  month: string;
  year: string;
  phone: string;
  email: string;
  isBeneficialOwner: string;
};

export default function PersonalInfoForm({
  defaultValues,
  shouldDisplayBoQuestion = false,
  handleOnSubmit,
}: {
  defaultValues: PersonalInfoForm;
  shouldDisplayBoQuestion?: boolean;
  handleOnSubmit: (data: PersonalInfoForm) => void;
}) {
  const onSubmit: SubmitHandler<PersonalInfoForm> = (data) => {
    handleOnSubmit(data);
  };

  const methods = useForm<PersonalInfoForm>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(personalInfoFormSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const registerWithMask = useHookFormMask(register);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={12}>
          <Stack spacing={4}>
            <TextInput
              label="Legal Full First Name"
              {...register('firstName')}
              errors={errors}
              required={true}
            />
            <TextInput
              label="Legal Full Last Name"
              {...register('lastName')}
              errors={errors}
              required={true}
            />
            <DateOfBirthInput />
            <TextInput
              label="Phone Number"
              {...registerWithMask('phone', ['(999) 999-9999'])}
              type="tel"
              inputMode="numeric"
              errors={errors}
              required={true}
            />
            <TextInput
              label="Email"
              {...register('email')}
              errors={errors}
              required={true}
            />
            {shouldDisplayBoQuestion && (
              <FormControl isInvalid={!!errors.isBeneficialOwner}>
                <FormLabel>
                  <HStack>
                    <Text as="span" color="red.500">
                      *
                    </Text>
                    <Text>
                      Are you a beneficial owner of this organization?
                    </Text>
                  </HStack>
                  <Text mb={3} fontSize={12} color="gray.500">
                    A beneficial owner is an individual who owns 25% or more of
                    the business.
                  </Text>
                </FormLabel>
                <RadioButtonGroupInput
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  name="isBeneficialOwner"
                  isInvalid={!!errors.isBeneficialOwner}
                />
                <FormErrorMessage>
                  <HStack>
                    <WarningIcon />
                    <Text>{errors.isBeneficialOwner?.message}</Text>
                  </HStack>
                </FormErrorMessage>
              </FormControl>
            )}
          </Stack>
          <Button type="submit">Next</Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

import { Stack, Heading } from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { SolePropBusinessInfoForm } from 'pages/onboarding/components';
import { useUpdateOnboardingData } from 'hooks';

export default function SolePropBusinessDetails() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      onboardingData: { people },
      businessBeingEdited,
      resetMachine,
      appStateType,
    },
  } = OnboardingContext.useSelector((state) => state);

  const { mutate: updateOnboardingData, isPending } = useUpdateOnboardingData();

  return (
    <Stack spacing={6}>
      <Heading as="h3" size="lg">
        Business Details
      </Heading>
      <SolePropBusinessInfoForm
        isLoading={isPending}
        disableEinInputs={appStateType === 'RESUBMISSION_NEEDED'}
        residentialAddress={people.length > 0 ? people[0].address : undefined}
        onSubmitBusinessInfo={(data) => {
          send({
            type: 'updateBusinessBeingEdited',
          });
          updateOnboardingData(
            {
              people: people,
              business: {
                ...businessBeingEdited,
                businessAddress: {
                  city: data.city ?? '',
                  country: 'US',
                  postalCode: data.postalCode ?? '',
                  stateProvinceRegion: data.stateProvinceRegion ?? '',
                  streetNumber1: data.streetNumber1 ?? '',
                  streetNumber2: data.streetNumber2 ?? '',
                },
                ein:
                  data.einChoice === 'yes'
                    ? data.ein?.replace(/[_\-]/g, '')
                    : undefined,
                doingBusinessAs: !!data.doingBusinessAs
                  ? data.doingBusinessAs
                  : undefined,
                industryClassificationCode:
                  data.industryClassificationCode ?? '',
              },
            },
            {
              onSuccess: () => {
                send({ type: 'next' });
                resetMachine();
              },
            }
          );
        }}
        defaultValues={{
          ein: businessBeingEdited?.ein ?? '',
          industryClassificationCode:
            businessBeingEdited?.industryClassificationCode ?? '',
          doingBusinessAs: businessBeingEdited?.doingBusinessAs,
          city: businessBeingEdited?.businessAddress?.city ?? '',
          country: businessBeingEdited?.businessAddress?.country ?? 'US',
          postalCode: businessBeingEdited?.businessAddress?.postalCode ?? '',
          stateProvinceRegion:
            businessBeingEdited?.businessAddress?.stateProvinceRegion ?? '',
          streetNumber1:
            businessBeingEdited?.businessAddress?.streetNumber1 ?? '',
          streetNumber2:
            businessBeingEdited?.businessAddress?.streetNumber2 ?? '',
          einChoice: !!businessBeingEdited?.ein ? 'yes' : 'no',
        }}
      />
    </Stack>
  );
}

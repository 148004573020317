import {
  Button,
  Divider,
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Spinner,
  HStack,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { usePlaidSdk } from 'hooks';

export const SelectPathModal = ({
  isOpen,
  onClose,
  addLoanAccount,
}: {
  isOpen: boolean;
  onClose: () => void;
  addLoanAccount: () => void;
}) => {
  const {
    plaidSdkIsReady,
    addFundingSourceIsPending,
    generatePlaidLinkTokenIsPending,
    openPlaidSdk,
  } = usePlaidSdk({
    onPlaidSuccess: () => {
      onClose();
    },
  });

  const handleAddLoanAccount = () => {
    onClose();
    addLoanAccount();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <Heading>Add to Wallet</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={0}>
            <Button
              justifyContent="space-between"
              alignItems="center"
              _hover={{
                background: 'gray.50',
              }}
              cursor="pointer"
              onClick={openPlaidSdk}
              py={4}
              px={2}
              variant="unstyled"
              h="fit-content"
              isDisabled={
                (!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending
              }
            >
              <HStack justify="space-between">
                <Stack
                  fontFamily="inter"
                  align="flex-start"
                  fontWeight="normal"
                >
                  <Text>Bank Account</Text>
                  <Text fontSize="12px" color="textSecondary">
                    Add a checking or savings account to your wallet
                  </Text>
                </Stack>
                {(!plaidSdkIsReady && generatePlaidLinkTokenIsPending) ||
                addFundingSourceIsPending ? (
                  <Spinner color="blue.400" />
                ) : (
                  <ChevronRightIcon boxSize={7} color="textSecondary" />
                )}
              </HStack>
            </Button>
            <Divider />
            <Button
              justifyContent="space-between"
              alignItems="center"
              _hover={{
                background: 'gray.50',
              }}
              cursor="pointer"
              onClick={handleAddLoanAccount}
              py={4}
              px={2}
              variant="unstyled"
              h="fit-content"
            >
              <HStack justify="space-between">
                <Stack
                  fontFamily="inter"
                  align="flex-start"
                  fontWeight="normal"
                >
                  <Text>Loan & Line of Credit</Text>
                  <Text fontSize="12px" color="textSecondary">
                    Link an existing loan account to your wallet
                  </Text>
                </Stack>
                <ChevronRightIcon boxSize={7} color="textSecondary" />
              </HStack>
            </Button>
          </Stack>
          <ModalFooter px={0}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

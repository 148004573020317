import { http, HttpResponse, PathParams } from 'msw';
import {
  BusinessInvitationListResponse,
  CheckInvitationResponse,
  UserListResponse,
  ValidateUserInviteResponse,
  SettingsInfoResponse,
  WalletBalanceResponse,
  DeactivateAccountResponse,
  DeactivateWalletRequest,
  InviteBusinessRequest,
  ResendBusinessInviteRequest,
  ValidateUserInviteRequest,
  RevokeUserAccessRequest,
  UserInviteListItemInviteStatusEnum,
  InviteAuthorizedUserRequest,
  CheckInvitationRequest,
  UserListItemTypeEnum,
  UserListItem,
} from 'api/wallet-app';
import { commandsBasePath, walletBasePath } from 'mocks/helpers';
import {
  userList,
  completedInviteList,
  invitedAuthorizedUserList,
  sentInviteList,
  settingsInfo,
  WALLET_ID,
} from 'mocks/fixtures/wallets-fixtures';
import { v4 as uuid } from 'uuid';

const walletsHandlers = [
  /*
   * GET /api/v1/wallet/business-invites
   * Generated API Endpoint Name: getBusinessInvites
   */
  http.get<PathParams, undefined, BusinessInvitationListResponse>(
    walletBasePath('business-invites'),
    () => {
      return HttpResponse.json({
        completed: completedInviteList,
        sent: sentInviteList,
      });
    }
  ),

  /*
   * GET /api/v1/wallet/users
   * Generated API Endpoint Name: getUsers
   */
  http.get<PathParams, undefined, UserListResponse>(
    walletBasePath('users'),
    () => {
      return HttpResponse.json({
        users: userList,
        invites: invitedAuthorizedUserList,
      });
    }
  ),

  /*
   * GET /api/v1/wallet/settings/info
   * Generated API Endpoint Name: getSettingsInfo
   */
  http.get<PathParams, undefined, SettingsInfoResponse>(
    walletBasePath('settings/info'),
    () => {
      return HttpResponse.json(settingsInfo);
    }
  ),

  /*
   * GET /api/v1/wallet/current-user
   * Generated API Endpoint Name: getUser
   */
  http.get<PathParams, undefined, UserListItem>(
    walletBasePath('current-user'),
    () => {
      return HttpResponse.json({
        email: 'test-user@mock.com',
        firstName: 'Test',
        id: 'cc79b5da-61d3-464c-96cf-2260fe198af7',
        lastName: 'User',
        type: UserListItemTypeEnum.WalletOwner,
        phoneNumber: '1234567890',
      });
    }
  ),

  /*
   * GET /api/v1/wallet/balance
   * Generated API Endpoint Name: getWalletBalance
   */
  http.get<PathParams, undefined, WalletBalanceResponse>(
    walletBasePath('balance'),
    () => {
      // TODO: Investigate how much work it would be to make the balance increment/decrement
      // based on the transactions happening
      return HttpResponse.json({
        balance: '100',
      });
    }
  ),

  /*
   * POST /api/v1/wallet/deactivate
   * Generated API Endpoint Name: getWalletBalance
   */
  http.post<PathParams, DeactivateWalletRequest, DeactivateAccountResponse>(
    walletBasePath('deactivate'),
    async ({ request }) => {
      // TODO: see if I can update mocked endpoints to return 403's if we deactivate a wallet?
      const { note } = await request.json();
      return HttpResponse.json({
        isDeactivated: true,
        walletId: WALLET_ID,
        message: note ?? '',
      });
    }
  ),

  /*
   * POST /api/v1/wallet/business-invites
   * Generated API Endpoint Name: inviteBusiness
   */
  http.post<PathParams, InviteBusinessRequest, undefined>(
    walletBasePath('business-invites'),
    async ({ request }) => {
      const { email, phoneNumber } = await request.json();
      sentInviteList.push({
        canBeResent: false,
        dateInvited: new Date(),
        email,
        id: uuid(),
        phoneNumber,
      });

      return new HttpResponse(null, { status: 204 });
    }
  ),

  /*
   * POST /api/v1/wallet/business-invites/{inviteId}
   * Generated API Endpoint Name: resendBusinessInvite
   */
  http.post<
    Pick<ResendBusinessInviteRequest, 'inviteId'>,
    undefined,
    undefined
  >(walletBasePath('business-invites/:inviteId'), ({ params }) => {
    const index = sentInviteList.findIndex(
      (sentInvite) => sentInvite.id === params.inviteId
    );
    if (index === -1) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      sentInviteList[index] = {
        ...sentInviteList[index],
        canBeResent: false,
      };
      return new HttpResponse(null, { status: 204 });
    }
  }),

  /*
   * POST /api/v1/commands/validate-invite
   * Generated API Endpoint Name: validateInvite
   */
  http.post<PathParams, ValidateUserInviteRequest, ValidateUserInviteResponse>(
    commandsBasePath('validate-invite'),
    () => {
      return HttpResponse.json({
        businessName: 'New Business',
        email: 'newBusiness@mock.com',
        phoneNumber: '7777777777',
        requiresOnboarding: true,
      });
    }
  ),

  /*
   * POST /api/v1/commands/validate-business-invite
   * Generated API Endpoint Name: validateBusinessInvite
   */
  http.post(commandsBasePath('validate-business-invite'), () => {
    return new HttpResponse(null, { status: 204 });
  }),

  /*
   * POST /api/v1/commands/unsubscribe
   * Generated API Endpoint Name: unsubscribeEmails
   */
  http.post(commandsBasePath('unsubscribe'), () => {
    return new HttpResponse(null, { status: 204 });
  }),

  /*
   * POST /api/v1/commands/revoke-user
   * Generated API Endpoint Name: revokeUserAccess
   */
  http.post<PathParams, RevokeUserAccessRequest, undefined>(
    commandsBasePath('revoke-user'),
    async ({ request }) => {
      const { userId } = await request.json();

      const index = userList.findIndex((user) => user.id === userId);

      if (index === -1) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        userList.splice(index, 1);
        return new HttpResponse(null, { status: 204 });
      }
    }
  ),

  /*
   * POST /api/v1/commands/link-wallet
   * Generated API Endpoint Name: linkWallet
   */
  http.post(commandsBasePath('link-wallet'), () => {
    return new HttpResponse(null, { status: 204 });
  }),

  /*
   * POST /api/v1/commands/invite-user
   * Generated API Endpoint Name: inviteUser
   */
  http.post<PathParams, InviteAuthorizedUserRequest, undefined>(
    commandsBasePath('invite-user'),
    async ({ request }) => {
      const { firstName, lastName, email } = await request.json();

      invitedAuthorizedUserList.push({
        email,
        firstName,
        lastName,
        inviteStatus: UserInviteListItemInviteStatusEnum.Invited,
        id: uuid(),
      });
      return new HttpResponse(null, { status: 204 });
    }
  ),

  /*
   * POST /api/v1/commands/decline-invite
   * Generated API Endpoint Name: declineInvite
   */
  http.post(commandsBasePath('decline-invite'), () => {
    return new HttpResponse(null, { status: 204 });
  }),

  /*
   * POST /api/v1/commands/check-user-invite
   * Generated API Endpoint Name: checkUserInvite
   */
  http.post<PathParams, CheckInvitationRequest, CheckInvitationResponse>(
    commandsBasePath('check-user-invite'),
    () => {
      return HttpResponse.json({
        isValid: false,
      });
    }
  ),

  /*
   * POST /api/v1/commands/accept-invite
   * Generated API Endpoint Name: acceptInvite
   */
  http.post<PathParams, { firstName: string; lastName: string }, undefined>(
    commandsBasePath('accept-invite'),
    async ({ request }) => {
      const { firstName, lastName } = await request.json();
      userList.push({
        email: 'newAuthUser1@mock.com',
        firstName,
        lastName,
        type: UserListItemTypeEnum.AuthorizedUser,
        id: uuid(),
        phoneNumber: '1231231122',
      });

      return new HttpResponse(null, { status: 204 });
    }
  ),
];

export default walletsHandlers;

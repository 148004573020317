import {
  IconButton,
  Heading,
  UnorderedList,
  ListItem,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import { useInviteAuthorizedUser } from 'hooks/api';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { InviteUserToWallet } from './InviteUser';

export default function ConfirmInvite({
  user,
  onBack,
}: {
  user: InviteUserToWallet;
  onBack: () => void;
}) {
  const { mutate: inviteUser, isPending: inviteUserIsPending } =
    useInviteAuthorizedUser();
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        aria-label="Back Arrow"
        icon={<IoArrowBackOutline />}
        color="black"
        variant="unstyled"
        display="flex"
        _hover={{ bgColor: 'gray.100' }}
        size="icon"
        boxSize={6}
        onClick={onBack}
      />
      <Stack spacing={12}>
        <Stack spacing={6}>
          <Heading>Ready to send?</Heading>
          <Stack>
            <Text>{`${user.firstName} ${user.lastName} permissions:`}</Text>
            <UnorderedList spacing={2} pl={8}>
              <ListItem>Create & Edit Users</ListItem>
              <ListItem>View Users</ListItem>
              <ListItem>View Statements</ListItem>
              <ListItem>View Transactions</ListItem>
              <ListItem>Create Payments</ListItem>
            </UnorderedList>
          </Stack>
          <Text color="textSecondary" fontSize="sm">
            Note: This new user will be required to accept the standard Bushel
            Terms &amp; Conditions to join this Wallet account.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <Button
            isLoading={inviteUserIsPending}
            onClick={() => {
              inviteUser(user);
            }}
          >
            Send Invite
          </Button>
          <Button
            isDisabled={inviteUserIsPending}
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreatePaymentRequest
 */
export interface CreatePaymentRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePaymentRequest
   */
  payerWalletId: string;
  /**
   *
   * @type {number}
   * @memberof CreatePaymentRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentRequest
   */
  memo: string;
}

/**
 * Check if a given object implements the CreatePaymentRequest interface.
 */
export function instanceOfCreatePaymentRequest(
  value: object
): value is CreatePaymentRequest {
  if (!('payerWalletId' in value) || value['payerWalletId'] === undefined)
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  if (!('memo' in value) || value['memo'] === undefined) return false;
  return true;
}

export function CreatePaymentRequestFromJSON(json: any): CreatePaymentRequest {
  return CreatePaymentRequestFromJSONTyped(json, false);
}

export function CreatePaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePaymentRequest {
  if (json == null) {
    return json;
  }
  return {
    payerWalletId: json['payerWalletId'],
    amount: json['amount'],
    memo: json['memo'],
  };
}

export function CreatePaymentRequestToJSON(
  value?: CreatePaymentRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    payerWalletId: value['payerWalletId'],
    amount: value['amount'],
    memo: value['memo'],
  };
}

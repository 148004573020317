import { useMemo } from 'react';
import { Button, Heading, HStack, Stack } from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useSettingsInfo, useUserInfo } from 'hooks/api';
import { UserListItemTypeEnum } from 'api/wallet-app';
import { CenteredSpinner } from 'components/CenteredSpinner';
import {
  AccountSecurity,
  AccountSettings,
  BusinessInfo,
  HelpAndSupport,
  History,
  InviteABusiness,
  ManageStaff,
  MyAccount,
} from './components/settings-sections';
import { Exit } from 'images/custom-icons/exit-icon';
import { PLAID_SCREEN_KEY } from '../add-funding-source';

export default function Settings() {
  const { data: settingsInfoData, isLoading: settingsInfoIsLoading } =
    useSettingsInfo();
  const { data: userInfo, isLoading: userInfoIsLoading } = useUserInfo();

  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,

    [userInfo?.type]
  );

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" fontSize="32px">
          {t('wallet.settings.settings')}
        </Heading>
        <Button
          variant="secondary"
          w="fit-content"
          onClick={() => {
            keycloak.logout({ redirectUri: window.location.host }).then(() => {
              sessionStorage.removeItem(PLAID_SCREEN_KEY);
            });
          }}
          leftIcon={<Exit h="18px" w="22px" />}
        >
          {t('wallet.settings.logout')}
        </Button>
      </HStack>
      {userInfoIsLoading || settingsInfoIsLoading ? (
        <CenteredSpinner centerProps={{ h: '80vh' }} />
      ) : (
        <Stack
          flexDir={{ base: 'column', lg: 'row' }}
          spacing={{ base: 2, lg: 6 }}
        >
          <Stack w="100%" spacing={4}>
            <BusinessInfo businessInfo={settingsInfoData?.business} />
            <MyAccount userInfo={userInfo} />
            <ManageStaff />
            <AccountSecurity />
          </Stack>
          <Stack w="100%" spacing={4}>
            <History />
            <HelpAndSupport data={settingsInfoData} />
            {!isAuthorizedUser && <AccountSettings />}
            <InviteABusiness />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useFormState,
} from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ssnFormSchema } from './ssn-form-schema';

const NUMBER_REGEX = /[0-9]/g;
export const SSN_HOLDER = '000000000';
const SSN_MASK_HOLDER = '●●●-●●-●●●●';

type SsnForm = {
  ssn: string;
  confirmSsn: string;
};

export default function SsnForm({
  shouldMaskInputs,
  onSubmitSsn,
  isLoading,
}: {
  shouldMaskInputs: boolean;
  onSubmitSsn: (ssn: string) => void;
  isLoading: boolean;
}) {
  const [ssn, setSsn] = useState(shouldMaskInputs ? SSN_HOLDER : '');
  const [confirmSsn, setConfirmSsn] = useState(
    shouldMaskInputs ? SSN_HOLDER : ''
  );
  const [isTouched, setIsTouched] = useState(false);

  const methods = useForm<SsnForm>({
    mode: 'onBlur',
    defaultValues: {
      ssn: shouldMaskInputs ? SSN_MASK_HOLDER : '',
      confirmSsn: shouldMaskInputs ? SSN_MASK_HOLDER : '',
    },
    resolver: yupResolver(ssnFormSchema),
  });

  const { handleSubmit, setError, setValue, watch } = methods;

  const onSubmit: SubmitHandler<SsnForm> = () => {
    if (ssn !== confirmSsn) {
      setError('ssn', { message: "SSN's do not match" });
      setError('confirmSsn', { message: "SSN's do not match" });
    } else {
      onSubmitSsn(ssn);
    }
  };

  useEffect(() => {
    if (shouldMaskInputs && isTouched) {
      setValue('ssn', '');
      setValue('confirmSsn', '');
      setSsn('');
      setConfirmSsn('');
    }
  }, [shouldMaskInputs && isTouched]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={12}>
          <Stack
            border="solid 1px"
            borderColor="gray.200"
            p={6}
            backgroundColor="gray.50"
            spacing={6}
          >
            <SsnInput
              inputName="ssn"
              setSsn={setSsn}
              setIsTouched={setIsTouched}
            />
            <SsnInput
              inputLabel="Confirm Social Security Number"
              inputName="confirmSsn"
              setSsn={setConfirmSsn}
              setIsTouched={setIsTouched}
            />
          </Stack>
          <Button
            type="submit"
            isDisabled={
              watch('ssn').length !== 11 || watch('confirmSsn').length !== 11
            }
            isLoading={isLoading}
          >
            Next
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

function SsnInput({
  inputLabel = 'Social Security Number',
  inputName,
  setSsn,
  setIsTouched,
}: {
  inputLabel?: string;
  inputName: keyof SsnForm;
  setSsn: Dispatch<SetStateAction<string>>;
  setIsTouched: Dispatch<SetStateAction<boolean>>;
}) {
  const { errors } = useFormState<SsnForm>({ name: inputName });

  function handleSsnOnChange({
    value,
    onChange,
  }: {
    value: string;
    onChange: (...event: any[]) => void;
  }) {
    setSsn((prev) => {
      const valWithoutHyphens = value.replaceAll(/-/g, '');
      return prev + valWithoutHyphens.charAt(valWithoutHyphens.length - 1);
    });
    if (value.length === 3 || value.length === 6) {
      onChange(`${value.replaceAll(NUMBER_REGEX, '●')}-`);
    } else {
      onChange(`${value.replaceAll(NUMBER_REGEX, '●')}`);
    }
  }

  return (
    <FormControl isInvalid={!!errors[inputName]}>
      <FormLabel>
        <HStack spacing={1}>
          <Text color="red">*</Text>
          <Text>{inputLabel}</Text>
        </HStack>
      </FormLabel>
      <Controller
        name={inputName}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <Input
              bgColor="white"
              maxLength={11}
              type="tel"
              {...field}
              onChange={({ currentTarget: { value } }) => {
                if (NUMBER_REGEX.test(value)) {
                  handleSsnOnChange({ value, onChange });
                }
              }}
              onKeyDown={(e) => {
                const {
                  key,
                  currentTarget: { value },
                } = e;
                if (
                  key === 'ArrowLeft' ||
                  key === 'ArrowRight' ||
                  key === 'ArrowDown' ||
                  key === 'ArrowUp'
                ) {
                  e.preventDefault();
                } else if (key === 'Backspace') {
                  if (value.charAt(value.length - 2) === '-') {
                    onChange(field.value.slice(0, -2));
                    setSsn((prev) => prev.slice(0, -1));
                  } else {
                    onChange(field.value.slice(0, -1));
                    setSsn((prev) => prev.slice(0, -1));
                  }
                }
              }}
              onClick={(e) => {
                setIsTouched(true);
                const target = e.target as HTMLInputElement;
                const valueLength = target.value.length;
                target.setSelectionRange(valueLength, valueLength);
              }}
              onFocus={(e) => {
                const target = e.target as HTMLInputElement;
                const valueLength = target.value.length;
                target.setSelectionRange(valueLength, valueLength);
              }}
            />
          );
        }}
      />
      <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
    </FormControl>
  );
}

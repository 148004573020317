import { useMutation } from '@tanstack/react-query';
import useTransactionsClient from './useTransactionsClient';
import { useWalletStore } from 'stores';

export const EXPORT_TRANSACTIONS_QUERY_KEY = 'export-transactions';

export default function useExportTransactions() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const transactionClient = useTransactionsClient();
  return useMutation({
    mutationKey: [EXPORT_TRANSACTIONS_QUERY_KEY],
    mutationFn: () =>
      transactionClient.exportTransactions({ xWalletId: walletId }),
    onError: () => {
      console.error('Failed to fetch CSV data');
    },
    gcTime: 0,
  });
}

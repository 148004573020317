import { AppStateResponse, ResponseError } from 'api/wallet-app';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useAppClient from './useAppClient';

export const APP_STATE_QUERY_KEY = 'app-state';

export default function useAppState<U extends AppStateResponse>(
  select?: (appData: AppStateResponse) => U
): UseQueryResult<U | undefined> {
  const appClient = useAppClient();
  return useQuery<AppStateResponse, ResponseError, U>({
    queryKey: [APP_STATE_QUERY_KEY],
    queryFn: () => {
      return appClient.getAppState();
    },
    select,
    retry: true,
  });
}

/* 
  This is intended to be used within the src/pages/WalletApp directory of the app and/or
  any components used within that directory.
*/
export function useNormalAppState() {
  return useAppState((data) => {
    if (data.type === 'NORMAL') {
      return data;
    } else {
      throw new Error(
        'This hook is intended to be used in the normal portion of the app'
      );
    }
  });
}

/* 
  This is intended to be used within the src/pages/onboarding directory of the app and/or
  any components used within that directory.
*/
export function useOnboardingAppState() {
  return useAppState((data) => {
    if (data.type === 'ONBOARDING') {
      return data;
    } else {
      throw new Error(
        'This hook is intended to be used in the onboarding portion of the app'
      );
    }
  });
}

/* 
  This is intended to be used within the src/pages/review directory of the app and/or
  any components used within that directory.
*/
export function useReviewState() {
  return useAppState((data) => {
    if (data.type === 'UNDER_REVIEW') {
      return data;
    } else {
      throw new Error(
        'This hook is intended to be used in the review section of the app'
      );
    }
  });
}

/* 
  This is intended to be used within the src/pages/sdk-upload directory of the app and/or
  any components used within that directory.
*/
export function useSdkUploadState() {
  return useAppState((data) => {
    if (data.type === 'SDK_UPLOAD') {
      return data;
    } else {
      throw new Error(
        'This hook is intended to be used in the sdk-upload section of the app'
      );
    }
  });
}

import {
  Image,
  Stack,
  Heading,
  Text,
  HStack,
  Circle,
  IconButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, To, useNavigate } from 'react-router-dom';
import TransferIcon from 'images/transfer-and-pay/transfer-icon.svg';
import DepositIcon from 'images/transfer-and-pay/deposit-icon.svg';
import SendReceive from 'images/transfer-and-pay/send-receive.svg';
import Prepaid from 'images/transfer-and-pay/prepaid.svg';
import { useTranslation } from 'react-i18next';
import { useFundingSourceList } from 'hooks';
import { GetActiveFundingSourcesTypeEnum } from 'api/wallet-app';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { useCallback } from 'react';
import { AddFundingSourceModal } from '../components';

export function Menu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  // TODO: Find out if we have to make these two separate API request to get all the info we need
  const { data: liabilityFundingSources, isLoading } = useFundingSourceList({
    type: GetActiveFundingSourcesTypeEnum.Liability,
    transactional: true,
  });
  const {
    data: allFundingSourcesList,
    isLoading: allFundingSourceListIsLoading,
  } = useFundingSourceList();
  const navigate = useNavigate();

  const onClickTransferOption = useCallback(
    (to: To) => {
      if (allFundingSourcesList?.fundingSources?.length === 0) {
        onOpen();
      } else {
        navigate(to);
      }
    },
    [allFundingSourcesList]
  );

  if (allFundingSourceListIsLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      {allFundingSourcesList?.fundingSources?.length === 0 && (
        <AddFundingSourceModal isOpen={isOpen} onClose={onClose} />
      )}
      <Stack spacing={6}>
        <Stack pl={2} spacing={5}>
          <IconButton
            aria-label="Back button"
            icon={<CloseIcon boxSize={3} />}
            as={ReactRouterLink}
            to="/"
            variant="unstyled"
            height="fit-content"
            minWidth="fit-content"
            minW="fit-content"
            w="fit-content"
          />
          <Heading as="h3" size="xl">
            {t('wallet.transfers.transferAndPay')}
          </Heading>
        </Stack>
        {isLoading ? (
          <CenteredSpinner
            centerProps={{ h: '300px' }}
            spinnerProps={{ size: 'xl' }}
          />
        ) : (
          <Stack>
            <TransferLink
              title={t('wallet.transfers.depositInWallet')}
              icon={DepositIcon}
              text="Add money to your Wallet balance"
              onClick={() => {
                onClickTransferOption('deposit');
              }}
            />
            <TransferLink
              title={t('wallet.transfers.transferToBank')}
              icon={TransferIcon}
              text="Move money to your linked bank accounts"
              onClick={() => {
                onClickTransferOption('withdraw');
              }}
            />
            <TransferLink
              title="Send & Request"
              icon={SendReceive}
              text="Move money between other users"
              onClick={() => {
                onClickTransferOption('send-and-request');
              }}
            />
            {liabilityFundingSources?.fundingSources &&
              liabilityFundingSources.fundingSources.length > 0 && (
                <TransferLink
                  title="Make Loan Payment"
                  icon={Prepaid}
                  text="Make a payment on your linked loan account"
                  onClick={() => {
                    onClickTransferOption('manage-loan');
                  }}
                />
              )}
          </Stack>
        )}
      </Stack>
    </>
  );
}

function TransferLink({
  title,
  icon,
  text,
  onClick,
}: {
  title: string;
  icon: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <Box
      py={4}
      px={2}
      borderRadius="xl"
      _hover={{
        backgroundColor: 'blue.50',
        transition: 'all 0.4s ease',
        cursor: 'pointer',
      }}
      role="group"
      onClick={onClick}
    >
      <HStack justify="space-between" align="center">
        <HStack spacing={4}>
          <Circle
            backgroundColor="blue.400"
            size="50px"
            alignContent="center"
            _groupHover={{
              backgroundColor: 'blue.500',
            }}
            transition="all 0.4s ease"
          >
            <Image src={icon} boxSize={6} color="white" />
          </Circle>
          <Stack spacing={0}>
            <Heading as="h4" fontSize="xl" fontWeight="bold">
              {title}
            </Heading>
            <Text fontFamily="inter" fontSize="sm" color="textSecondary">
              {text}
            </Text>
          </Stack>
        </HStack>
        <ChevronRightIcon
          color="gray.500"
          boxSize={35}
          _groupHover={{ color: 'blue.500' }}
        />
      </HStack>
    </Box>
  );
}

import {
  HStack,
  Flex,
  Spacer,
  Icon,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { IoInformationCircleOutline, IoLogOutOutline } from 'react-icons/io5';
import { GoBack } from '../go-back';
import BushelLogo from 'images/Logo/Product/Wallet.svg';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { ConfirmLogoutAlertDialog, SsnInfoModal } from './components';
import { useMemo } from 'react';

export default function OnboardingHeader() {
  const {
    value,
    context: { appStateType },
  } = OnboardingContext.useSelector((state) => state);

  const {
    isOpen: confirmLogoutAlertDialogIsOpen,
    onOpen: confirmLogoutAlertDialogOnOpen,
    onClose: confirmLogoutAlertDialogOnClose,
  } = useDisclosure();
  const {
    isOpen: ssnInfoModalIsOpen,
    onOpen: ssnInfoModalOnOpen,
    onClose: ssnInfoModalOnClose,
  } = useDisclosure();

  const rightSideOfHeader = useMemo(() => {
    if (['TypeOfBusiness', 'Overview'].includes(value)) {
      return (
        <Flex flex={1} justify="flex-end">
          <Icon
            boxSize={6}
            cursor="pointer"
            onClick={confirmLogoutAlertDialogOnOpen}
            as={IoLogOutOutline}
          />
        </Flex>
      );
    } else if (value === 'Ssn') {
      return (
        <Flex flex={1} justify="flex-end">
          <Icon
            as={IoInformationCircleOutline}
            fontSize={24}
            cursor="pointer"
            onClick={ssnInfoModalOnOpen}
          />
        </Flex>
      );
    } else {
      return <Spacer flex={1} />;
    }
  }, [value]);

  return (
    <>
      <SsnInfoModal isOpen={ssnInfoModalIsOpen} onClose={ssnInfoModalOnClose} />
      <ConfirmLogoutAlertDialog
        isOpen={confirmLogoutAlertDialogIsOpen}
        onClose={confirmLogoutAlertDialogOnClose}
      />
      <HStack justify="space-between" w="100%">
        {value === 'TypeOfBusiness' ||
        value === 'Submit' ||
        (value === 'Overview' && appStateType === 'RESUBMISSION_NEEDED') ? (
          <Spacer flex={1} />
        ) : (
          <Flex flex={1} justify="flex-start">
            <GoBack />
          </Flex>
        )}
        <Image src={BushelLogo} height={18} flex={1} />
        {rightSideOfHeader}
      </HStack>
    </>
  );
}

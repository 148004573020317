import { Stack, Text } from '@chakra-ui/react';
import {
  InfoCard,
  InfoCardBadgeValue,
} from 'pages/onboarding/components/info-card';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useMemo } from 'react';
import { controllerRoles } from 'pages/onboarding/steps';

export default function PersonalInfoSection({
  onClickEdit,
}: {
  onClickEdit: () => void;
}) {
  const { context } = OnboardingContext.useSelector((state) => state);
  const {
    onboardingData: { people },
  } = context;

  const existingController = useMemo(
    () =>
      people.find((person) =>
        person.roles.some((role) => controllerRoles.has(role))
      ),
    [people]
  );

  const personalInfoCardTitle = useMemo(() => {
    if (!existingController) {
      return 'Personal Information';
    } else {
      if (existingController.firstName && existingController.lastName) {
        return `${existingController.firstName} ${existingController.lastName}`;
      }
    }
  }, [existingController]);

  const personalInfoCardValue = useMemo(() => {
    if (!existingController) {
      return InfoCardBadgeValue.NOT_STARTED;
    } else if (!existingController.dateOfBirth) {
      return InfoCardBadgeValue.NEEDS_INFO;
    } else {
      return InfoCardBadgeValue.READY_TO_SUBMIT;
    }
  }, [existingController]);

  return (
    <Stack direction="column" spacing={3}>
      <Text fontWeight="bold" size="sm">
        Your Personal Info
      </Text>
      <InfoCard
        title={personalInfoCardTitle}
        value={personalInfoCardValue}
        onClickEdit={onClickEdit}
      />
    </Stack>
  );
}

import { Text, Stack, Badge, Button, ButtonGroup } from '@chakra-ui/react';
import { IoPencilSharp, IoTrashOutline } from 'react-icons/io5';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useUpdateOnboardingData } from 'hooks';

export enum InfoCardBadgeValue {
  VERIFIED = 'verified',
  READY_TO_SUBMIT = 'ready to submit',
  NEEDS_INFO = 'needs info',
  NOT_STARTED = 'not started',
  FURTHER_DOCUMENTATION = 'further documentation',
}

type InfoCardType = {
  title?: string;
  value: InfoCardBadgeValue;
  onClickEdit?: () => void;
  id?: string;
  canDelete?: boolean;
};

export default function InfoCard({
  id,
  title,
  value,
  onClickEdit,
  canDelete = false,
}: InfoCardType) {
  const { context } = OnboardingContext.useSelector((state) => state);
  const {
    resetMachine,
    onboardingData: { people },
  } = context;
  const { mutate: updateOnboardingData, isPending } = useUpdateOnboardingData();

  function removePerson() {
    const peopleToKeep = people.filter((person) => person.id !== id);
    updateOnboardingData(
      { ...context, people: peopleToKeep },
      {
        onSuccess: () => {
          resetMachine();
        },
      }
    );
  }

  return (
    <Stack
      border={`1px ${cardBorderStyle(value)}`}
      borderColor={cardBorderColor(value)}
      borderRadius={3}
      p={2}
      background="gray.50"
      data-id={id}
    >
      <Badge colorScheme={colorOfBadge(value)} w="fit-content">
        {value}
      </Badge>
      <Text>{title}</Text>
      <ButtonGroup
        fontWeight="bold"
        justifyContent="flex-end"
        width="100%"
        spacing={0}
      >
        {canDelete && (
          <Button
            variant="unstyled"
            leftIcon={<IoTrashOutline />}
            onClick={removePerson}
            justifyContent="center"
            alignContent="center"
            color="red.400"
            _hover={{ bgColor: 'red.100' }}
            w="fit-content"
            h="fit-content"
            display="flex"
            px={2}
            isLoading={isPending}
          >
            Remove
          </Button>
        )}
        <Button
          variant="unstyled"
          leftIcon={<IoPencilSharp />}
          onClick={onClickEdit}
          justifyContent="center"
          alignContent="center"
          color="blue.400"
          _hover={{ bgColor: 'blue.100' }}
          w="fit-content"
          minW="fit-content"
          minWidth="fit-content"
          h="fit-content"
          display="flex"
          px={2}
        >
          Edit
        </Button>
      </ButtonGroup>
    </Stack>
  );
}

function cardBorderStyle(value: InfoCardBadgeValue) {
  switch (value) {
    case InfoCardBadgeValue.VERIFIED:
    case InfoCardBadgeValue.READY_TO_SUBMIT:
    case InfoCardBadgeValue.NOT_STARTED:
      return 'solid';
    case InfoCardBadgeValue.NEEDS_INFO:
      return 'dashed';
    case InfoCardBadgeValue.FURTHER_DOCUMENTATION:
      return 'dotted';
    default:
      return 'solid';
  }
}

function cardBorderColor(value: InfoCardBadgeValue) {
  switch (value) {
    case InfoCardBadgeValue.VERIFIED:
      return 'green.50';
    case InfoCardBadgeValue.READY_TO_SUBMIT:
      return 'green';
    case InfoCardBadgeValue.NEEDS_INFO:
      return 'orange';
    case InfoCardBadgeValue.NOT_STARTED:
      return 'gray.200';
    case InfoCardBadgeValue.FURTHER_DOCUMENTATION:
      return 'red';
    default:
      return 'gray.200';
  }
}

function colorOfBadge(value: InfoCardBadgeValue) {
  switch (value) {
    case InfoCardBadgeValue.VERIFIED:
    case InfoCardBadgeValue.READY_TO_SUBMIT:
      return 'green';
    case InfoCardBadgeValue.NEEDS_INFO:
      return 'orange';
    case InfoCardBadgeValue.NOT_STARTED:
      return 'blue';
    case InfoCardBadgeValue.FURTHER_DOCUMENTATION:
      return 'red';
  }
}

import { InvalidLink, Unsubscribe } from 'pages/unauthenticated-pages';
import { Route, Routes } from 'react-router-dom';

export default function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/invalid-link" element={<InvalidLink />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
    </Routes>
  );
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';

export default function SsnInfoModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={12}>
          <Stack spacing={6}>
            <Heading as="h4" size="lg">
              What is a Social Security Number?
            </Heading>
            <Text fontSize={15}>
              In the United States, a Social Security number is a nine-digit
              number issued to U.S. citizens, permanent residents, and temporary
              residents under section 205 of the Social Security Act, codified
              as 42 U.S.C. § 405.
            </Text>
            <Heading as="h4" size="lg">
              Where can I find it?
            </Heading>
            <Text>You can find your Social Security Number on:</Text>
            <UnorderedList>
              <ListItem>Your Social Security card,</ListItem>
              <ListItem>Tax returns,</ListItem>
              <ListItem>W-2s,</ListItem>
              <ListItem>Some bank statements.</ListItem>
            </UnorderedList>
            <Stack>
              <Text fontWeight="bold">
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW
                ACCOUNT:
              </Text>
              <Text>
                To help the government fight the funding of terrorism and money
                laundering activities, federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an Account.  What this means
                for you: When you open an Account, we will ask for your name,
                address, date of birth, and other information that will allow us
                to identify you. We may also ask to see a copy of your driver’s
                license or other identifying documents.
              </Text>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

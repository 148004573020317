import {
  Flex,
  HStack,
  Stack,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  Image,
  Text,
} from '@chakra-ui/react';
import Checkmark from 'images/toggle_checkmark.svg';
import { useController } from 'react-hook-form';

type RadioButton = {
  label: string;
  value: string;
  subtitle?: string;
};

export default function RadioButtonGroupInput(
  props: UseRadioGroupProps & {
    options: RadioButton[];
    name: string;
    isInvalid?: boolean;
  }
) {
  const { field } = useController({ name: props.name });
  const { ref, ...rest } = field;
  const { value, getRadioProps, getRootProps } = useRadioGroup(rest);
  return (
    <Stack {...getRootProps()} spacing={4}>
      {props.options.map((option) => {
        const radioProps = getRadioProps({ value: option.value });
        return (
          <RadioButton
            key={option.value}
            value={option.value}
            label={option.label}
            subtitle={option.subtitle}
            isChecked={option.value === value}
            isInvalid={props.isInvalid}
            {...radioProps}
          />
        );
      })}
    </Stack>
  );
}

function RadioButton(props: UseRadioProps & RadioButton) {
  const { getInputProps, getRadioProps } = useRadio(props);

  return (
    <Flex as="label" w="100%">
      <input {...getInputProps()} />
      <HStack
        {...getRadioProps()}
        w="100%"
        p={6}
        alignItems="center"
        spacing={props.isChecked ? 4 : 0}
        _hover={{ bgColor: 'green.50' }}
        border="1px solid"
        borderColor={props.isInvalid ? 'red' : 'gray.200'}
        borderRadius="base"
        cursor="pointer"
        _checked={{
          borderColor: 'green.500',
          borderWidth: '2px',
          color: 'green.500',
          bg: 'green.50',
        }}
      >
        {props.isChecked && <Image src={Checkmark} boxSize={6} />}
        <Stack spacing={0}>
          <Text fontWeight="bold">{props.label}</Text>
          {props.isChecked && props.subtitle && (
            <Text fontSize="sm">{props.subtitle}</Text>
          )}
        </Stack>
      </HStack>
    </Flex>
  );
}

import { FundingSourceDto, WalletProfileDto } from 'api/wallet-app';
import { NumberFormatValues } from 'react-number-format';

export type RequestContext = {
  amount: NumberFormatValues;
  requestee?: WalletProfileDto;
  currentState: RequestState;
};

export type RequestEvent =
  | {
      type: 'NEXT';
      amount?: NumberFormatValues;
      fundingSource?: FundingSourceDto;
      requestee?: WalletProfileDto;
    }
  | { type: 'BACK' };

export enum RequestState {
  ENTER_AMOUNT = 'enter-amount',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
}

export function requestReducer(state: RequestContext, event: RequestEvent) {
  switch (state.currentState) {
    case RequestState.ENTER_AMOUNT:
      if (event.type === 'NEXT') {
        return {
          ...state,
          amount: event.amount || state.amount,
          requestee: event.requestee || state.requestee,
          currentState: RequestState.CONFIRM,
        };
      }
      break;
    case RequestState.CONFIRM:
      if (event.type === 'NEXT') {
        return { ...state, currentState: RequestState.SUCCESS };
      } else if (event.type === 'BACK') {
        return { ...state, currentState: RequestState.ENTER_AMOUNT };
      }
      break;
    case RequestState.SUCCESS:
      break;

    default:
      return state;
  }
  return state;
}

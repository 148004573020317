import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SelectPathModal } from './components/SelectPathModal';
import { WalletBalanceHeader } from './components';
import { LinkAccounts } from './components/LinkAccounts';
import { find, findIndex } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline } from 'react-icons/io5';
import { FundingSource } from './components/fundingSource';
import { PendingFundingSource } from './components/pendingFundingSource';
import { LendingPartnersModal } from './components/LendingPartnersModal';
import { LoanPaymentMachine } from './components/LoanPayment/loanPaymentMachine';
import { createActorContext } from '@xstate/react';
import { useSearchParams } from 'react-router-dom';
import {
  useFundingSourceList,
  useRemoveFundingSource,
  useRenameFundingSource,
  useReorderFundingSources,
  useUserInfo,
} from 'hooks/api';
import { AuthorizedUserTooltip } from 'components/AuthorizedUserTooltip';
import { FundingSourceDto, UserListItemTypeEnum } from 'api/wallet-app';
import { AddFundingSourceTile } from 'components/add-funding-source-tile';

export const LoanPaymentContext = createActorContext(LoanPaymentMachine);

export const Accounts = () => {
  const [accountToUpdate, setAccountToUpdate] =
    React.useState<FundingSourceDto>();

  const { data: fundingSources, isLoading: fundingSourcesIsLoading } =
    useFundingSourceList();
  const { data: userInfo, isLoading: userInfoIsLoading } = useUserInfo();
  const { mutate: reorderFundingSources } = useReorderFundingSources();

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  const pendingSources = useMemo(() => {
    return (
      fundingSources?.fundingSources?.filter((fundingSource) => {
        return (
          fundingSource.isVerified === false ||
          fundingSource.needsUserValidation === true
        );
      }) ?? []
    );
  }, [fundingSources?.fundingSources]);

  const translatableSources = useMemo(() => {
    return (
      fundingSources?.fundingSources?.filter((fundingSource) => {
        return (
          fundingSource.isVerified === true &&
          fundingSource.needsUserValidation === false
        );
      }) ?? []
    );
  }, [fundingSources?.fundingSources]);

  const reorder = (
    list: FundingSourceDto[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.has('addLineOfCredit')) {
      searchParams.delete('addLineOfCredit');
      setSearchParams({});
      handleAddLoanAccount();
    }
  }, [searchParams]);

  const { t } = useTranslation();

  const {
    isOpen: isRemoveModalOpen,
    onOpen: onRemoveModalOpen,
    onClose: onRemoveModalClose,
  } = useDisclosure();

  const {
    isOpen: isLinkPathModalOpen,
    onClose: onLinkPathModalClose,
    onOpen: onLinkPathModalOpen,
  } = useDisclosure();

  const {
    isOpen: isLendingPartnersModalOpen,
    onClose: onLendingPartnersModalClose,
    onOpen: onLendingPartnersModalOpen,
  } = useDisclosure();

  const {
    mutate: removeFundingSource,
    isPending: removeFundingSourceIsPending,
  } = useRemoveFundingSource();
  const { mutate: renameFundingSource } = useRenameFundingSource();

  React.useEffect(() => {
    document.getElementById('accounts')?.focus();
  }, []);

  const reorderItems = (origin: number, destination: number) => {
    if (!translatableSources) return;

    const items = reorder(translatableSources, origin, destination);
    const fundingSourceIds = items.map((source) => source.fundingSourceId);
    reorderFundingSources({ fundingSourceIds: fundingSourceIds });
  };

  const handleRenameSource = (fundingSourceId: string, name: string) => {
    renameFundingSource({
      friendlyName: name,
      fundingSourceId: fundingSourceId,
    });
  };

  const confirmRemoveAccount = (fundingSourceId: string) => {
    const account = find(
      translatableSources,
      (o) => o.fundingSourceId == fundingSourceId
    );
    setAccountToUpdate(account);
    onRemoveModalOpen();
  };

  const removeAccount = async () => {
    if (!accountToUpdate) return;

    removeFundingSource(accountToUpdate.fundingSourceId, {
      onSettled: () => {
        onRemoveModalClose();
      },
    });
  };

  const setAsDefault = (fundingSourceId: string) => {
    const idx = findIndex(
      translatableSources,
      (o) => fundingSourceId == o.fundingSourceId
    );
    reorderItems(idx, 0);
  };

  const handleAddLoanAccount = () => {
    onLendingPartnersModalOpen();
  };

  const handleLendingPartnersModalClose = () => {
    onLendingPartnersModalClose();
  };

  const chooseLinkPath = () => {
    onLinkPathModalOpen();
  };

  return (
    <LoanPaymentContext.Provider>
      {fundingSourcesIsLoading ||
      userInfoIsLoading ||
      !translatableSources ||
      !pendingSources ? (
        <Center h="50vh">
          <Spinner color="blue.400" size="xl" />
        </Center>
      ) : (
        <Stack spacing={6}>
          <WalletBalanceHeader />
          <LinkAccounts chooseLinkPath={chooseLinkPath} />
          <Flex flexDirection="column">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flex="1"
              pb="8px"
            >
              <Text fontWeight="bold" fontSize={['1.2rem', '1.5rem']}>
                Linked Accounts
              </Text>
              <AuthorizedUserTooltip placement="left">
                <IconButton
                  icon={<IoAddCircleOutline size="24px" />}
                  aria-label="Add Account"
                  variant="link"
                  size="icon"
                  w="fit-content"
                  onClick={chooseLinkPath}
                  _focus={{ outline: 'none' }}
                  isDisabled={isAuthorizedUser}
                />
              </AuthorizedUserTooltip>
            </Flex>
            <Box mb="1.5rem" paddingBottom="4rem">
              {fundingSources?.fundingSources?.length === 0 && (
                <AddFundingSourceTile maxW="360px" />
              )}
              <Flex
                direction="column"
                borderRadius="8px"
                overflow="hidden"
                boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
              >
                {pendingSources &&
                  pendingSources.map((source) => {
                    return (
                      <Fragment key={source.fundingSourceId}>
                        <PendingFundingSource fundingSource={source} />
                        <Divider color="gray.200" />
                      </Fragment>
                    );
                  })}
                {translatableSources &&
                  translatableSources.map((source, idx) => {
                    return (
                      <Fragment key={source.fundingSourceId}>
                        <FundingSource
                          fundingSource={source}
                          isPreferred={idx == 0}
                          setAsDefault={setAsDefault}
                          handleRenameSource={handleRenameSource}
                          handleRemoveSource={confirmRemoveAccount}
                          isOnlyFundingSource={translatableSources.length === 1}
                        />
                        {idx !== translatableSources.length - 1 && (
                          <Divider color="gray.200" />
                        )}
                      </Fragment>
                    );
                  })}
              </Flex>
            </Box>
          </Flex>
          <SelectPathModal
            isOpen={isLinkPathModalOpen}
            onClose={onLinkPathModalClose}
            addLoanAccount={handleAddLoanAccount}
          />
          {isLendingPartnersModalOpen && (
            <LendingPartnersModal
              isOpen={isLendingPartnersModalOpen}
              onClose={handleLendingPartnersModalClose}
            />
          )}
          {translatableSources && (
            <>
              <Modal isOpen={isRemoveModalOpen} onClose={onRemoveModalClose}>
                <ModalOverlay />
                <ModalContent p="2rem">
                  <Heading fontSize="1.2rem">
                    {t('wallet.accounts.removeAccount')}
                  </Heading>
                  <Text mt="1rem">
                    {t('wallet.accounts.areYouSureYouWantRemove')}
                    <strong>
                      {` ${accountToUpdate?.financialInstitutionName} (xx-${accountToUpdate?.accountNumber})`}
                    </strong>
                    {t('wallet.accounts.willNotDeletePendingTransactions')}
                  </Text>
                  <Flex justifyContent="flex-end" width="100%" mt="2rem">
                    <Button
                      variant="secondary"
                      mr="1rem"
                      onClick={onRemoveModalClose}
                      isDisabled={removeFundingSourceIsPending}
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={removeAccount}
                      isLoading={removeFundingSourceIsPending}
                    >
                      {t('button.remove')}
                    </Button>
                  </Flex>
                </ModalContent>
              </Modal>
            </>
          )}
        </Stack>
      )}
    </LoanPaymentContext.Provider>
  );
};

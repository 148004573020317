import { Stack, Button, Text } from '@chakra-ui/react';
import { BusinessDtoBusinessStructureEnum } from 'api/wallet-app';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { RadioButtonGroupInput } from '../../inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { typeOfBusinessFormSchema } from './type-of-business-form-schema';

type TypeOfBusiness = { businessStructure: string };

export default function TypeOfBusinessForm({
  defaultValues,
  isLoading,
  onSubmitTypeOfBusiness,
}: {
  defaultValues: TypeOfBusiness;
  isLoading: boolean;
  onSubmitTypeOfBusiness: (data: TypeOfBusiness, shouldUpdate: boolean) => void;
}) {
  const methods = useForm<TypeOfBusiness>({
    defaultValues,
    resolver: yupResolver(typeOfBusinessFormSchema),
  });
  const {
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  const onSubmit: SubmitHandler<TypeOfBusiness> = (data) => {
    onSubmitTypeOfBusiness(data, isDirty);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text>
              Please select your business type from the options below:
            </Text>
            <RadioButtonGroupInput
              name="businessStructure"
              options={[
                {
                  label: 'Sole Proprietorship',
                  value: BusinessDtoBusinessStructureEnum.SoleProp,
                  subtitle: 'You own and run the business by yourself.',
                },
                {
                  label: 'LLC',
                  value: BusinessDtoBusinessStructureEnum.Llc,
                  subtitle:
                    'Your business limits personal liability and offers flexible management, often with co-owners.',
                },
                {
                  label: 'C Corporation',
                  value: BusinessDtoBusinessStructureEnum.Corporation,
                },
                {
                  label: 'S Corporation',
                  value: 'S_CORPORATION',
                },
                {
                  label: 'Partnership',
                  value: BusinessDtoBusinessStructureEnum.Partnership,
                },
              ]}
            />
          </Stack>
          <Button
            type="submit"
            isLoading={isLoading}
            isDisabled={!watch('businessStructure')}
          >
            Next
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

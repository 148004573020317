import { Center, Spinner } from '@chakra-ui/react';
import { useAppState, usePlaidSdk } from 'hooks';
import { useEffect } from 'react';

export const OauthRedirect = () => {
  const { data, isSuccess } = useAppState();
  const { openPlaidSdk } = usePlaidSdk({
    onPlaidSuccess: () => {
      const { origin } = window.location;
      let replacement = origin;
      // if they're being redirected to onboarding, send a query param
      // so the plaid page doesn't start over from the beginning. Else, route them back
      // to the accounts screen
      if (isSuccess) {
        replacement =
          data?.type == 'ONBOARDING'
            ? origin + '/?redirect=true'
            : origin + '/accounts';
      }
      window.location.replace(replacement);
    },
  });

  useEffect(() => {
    openPlaidSdk();
  }, []);

  return (
    <Center
      height="100vh"
      width="100%"
      zIndex="9"
      backgroundColor="#fff"
      position="fixed"
    >
      <Spinner color="blue.400" thickness="4px" size="xl" />
    </Center>
  );
};

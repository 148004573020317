import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOnboardingClient from './useOnboardingClient';
import { v4 as uuid } from 'uuid';
import { OnboardingDataDto } from 'api/wallet-app';
import { APP_STATE_QUERY_KEY } from 'hooks';
import { useMediaQuery, useToast } from '@chakra-ui/react';

export default function useUpdateOnboardingData() {
  const onboardingClient = useOnboardingClient();
  const queryClient = useQueryClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    position: isMobile ? 'top' : 'bottom',
    isClosable: true,
  });
  return useMutation({
    mutationKey: ['update-onboarding-data'],
    mutationFn: (payload: OnboardingDataDto) => {
      return onboardingClient.updateOnboardingData({
        idempotencyKey: uuid(),
        onboardingDataDto: payload,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [APP_STATE_QUERY_KEY] });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Failed to Update',
        description: 'We failed to update your data. Please try again',
      });
    },
  });
}

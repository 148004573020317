import { WarningIcon } from '@chakra-ui/icons';
import {
  Skeleton,
  FormControl,
  FormLabel,
  HStack,
  Select,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { useIndustryClassificationCodeList } from 'hooks';
import { useFormContext } from 'react-hook-form';

type IndustryClassificationCode = {
  industryClassificationCode: string;
};

export default function IndustryClassificationCodesInput() {
  const {
    data: industryClassificationCodeList,
    isLoading: industryClassificationCodeListIsLoading,
  } = useIndustryClassificationCodeList();

  const {
    formState: { errors },
    register,
  } = useFormContext<IndustryClassificationCode>();
  return (
    <Skeleton
      isLoaded={
        !industryClassificationCodeListIsLoading &&
        !!industryClassificationCodeList
      }
    >
      <FormControl isInvalid={!!errors.industryClassificationCode}>
        <FormLabel>
          <HStack>
            <Text color="red">*</Text>
            <Text>Industry Classification</Text>
          </HStack>
        </FormLabel>
        <Select
          placeholder="Select option"
          {...register('industryClassificationCode')}
        >
          {industryClassificationCodeList?.industryClassifications.map(
            (item) => (
              <option key={item.code} value={item.code}>
                {item.description}
              </option>
            )
          )}
        </Select>
        <FormErrorMessage>
          <WarningIcon mr="5px" />
          {errors?.industryClassificationCode?.message}
        </FormErrorMessage>
      </FormControl>
    </Skeleton>
  );
}

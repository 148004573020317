import { setup, assign, not, and } from 'xstate';
import {
  PersonDto,
  BusinessDto,
  BusinessDtoBusinessStructureEnum,
  OnboardingAddressDto,
  OnboardingDataDto,
  AppStateResponse,
} from 'api/wallet-app';

type OnboardingMachineContext = {
  onboardingData: OnboardingDataDto;
  appStateType: AppStateResponse['type'];
  isController?: boolean;
  personBeingEdited?: Partial<PersonDto> | undefined;
  businessBeingEdited?: Partial<BusinessDto> | undefined;
  resetMachine: () => void;
  viewedControllerAttestation: boolean;
};

type OnboardingMachineEvents =
  | { type: 'updateIsController'; isController: boolean }
  | { type: 'updatePersonBeingEdited'; personDetails?: Partial<PersonDto> }
  | {
      type: 'updateBusinessBeingEdited';
      businessDetails?: Partial<BusinessDto>;
    }
  | { type: 'updateAddress'; address?: OnboardingAddressDto }
  | { type: 'submit' }
  | { type: 'back' }
  | { type: 'next' };

type OnboardingMachineInputs = {
  defaultOnboardingData: OnboardingDataDto;
  defaultAppStateType: AppStateResponse['type'];
  resetMachine: () => void;
};

export const onboardingMachine = setup({
  types: {
    context: {} as OnboardingMachineContext,
    events: {} as OnboardingMachineEvents,
    input: {} as OnboardingMachineInputs,
  },
  actions: {
    setIsController: assign({
      isController: ({ event }) => {
        if (event.type === 'updateIsController') {
          return event.isController;
        } else {
          console.debug('Using wrong action type');
        }
      },
    }),
    setPersonBeingEdited: assign({
      personBeingEdited: ({ context, event }) => {
        if (event.type === 'updatePersonBeingEdited') {
          return event.personDetails === undefined
            ? undefined
            : {
                ...context.personBeingEdited,
                ...event.personDetails,
              };
        } else {
          console.debug('Using wrong action type');
        }
      },
    }),
    setBusinessBeingEdited: assign({
      businessBeingEdited: ({ context, event }) => {
        if (event.type === 'updateBusinessBeingEdited') {
          return {
            ...context.businessBeingEdited,
            ...event.businessDetails,
          };
        } else {
          console.debug('Using wrong action type');
        }
      },
    }),
    setAddress: assign({
      personBeingEdited: ({ context, event }) => {
        if (event.type === 'updateAddress') {
          if (!!context.personBeingEdited) {
            return { ...context.personBeingEdited, address: event.address };
          } else {
            return context.personBeingEdited;
          }
        } else {
          console.debug('Using wrong action type');
        }
      },
      businessBeingEdited: ({ context, event }) => {
        if (event.type === 'updateAddress') {
          if (!!context.businessBeingEdited && !!event.address) {
            // If we are updating the address for the business, we are have submitted the current
            // edited business info to the BE. Thus, we should clear out the edited business info
            return undefined;
          } else {
            return context.businessBeingEdited;
          }
        } else {
          console.debug('Using wrong action type');
        }
      },
    }),
    setViewedControllerAttestation: assign({
      viewedControllerAttestation: true,
    }),
    clearBusinessBeingEdited: assign({ businessBeingEdited: undefined }),
    clearPersonBeingEdited: assign({ personBeingEdited: undefined }),
  },
  guards: {
    isSoleProp: ({ context }) =>
      context.onboardingData.business?.businessStructure ===
      BusinessDtoBusinessStructureEnum.SoleProp,
    isController: ({ event }) =>
      event.type === 'updateIsController' && !!event.isController,
    onlyHasBusinessStructureAndHasNotViewedControllerScreen: ({ context }) => {
      return (
        !!context.onboardingData.business?.businessStructure &&
        !context.onboardingData.business.ein &&
        context.onboardingData.people.length === 0 &&
        !context.viewedControllerAttestation
      );
    },
    isEditingBusinessInfo: ({ context }) => !!context.businessBeingEdited,
    hasControllerOrBusinessInfo: ({ context }) =>
      context.onboardingData.people.length > 0 ||
      !!context.onboardingData.business?.ein,
    isNonSolePropAndHasInfo: and([
      not('isSoleProp'),
      'hasControllerOrBusinessInfo',
    ]),
    isNonSolePropAndDoesNotHaveInfo: and([
      not('isSoleProp'),
      'onlyHasBusinessStructureAndHasNotViewedControllerScreen',
    ]),
    isNonSolePropAndEditingBusinessInfo: and([
      not('isSoleProp'),
      'isEditingBusinessInfo',
    ]),
  },
}).createMachine({
  id: 'onboarding',
  initial: 'TypeOfBusiness',
  context: ({
    input: { defaultOnboardingData, resetMachine, defaultAppStateType },
  }) => ({
    onboardingData: defaultOnboardingData,
    appStateType: defaultAppStateType,
    resetMachine: resetMachine,
    isController:
      defaultOnboardingData.people?.length > 0 ||
      !!defaultOnboardingData.business?.industryClassificationCode
        ? true
        : undefined,
    personBeingEdited: undefined,
    businessBeingEdited: undefined,
    viewedControllerAttestation: false,
  }),
  states: {
    TypeOfBusiness: {
      always: [
        {
          target: 'Overview',
          guard: 'isSoleProp',
        },
        {
          target: 'Overview',
          guard: 'isNonSolePropAndHasInfo',
        },
        {
          target: 'AreYouAController',
          guard: 'isNonSolePropAndDoesNotHaveInfo',
        },
      ],
      on: {
        next: [
          {
            target: 'Overview',
            guard: 'isSoleProp',
          },
          {
            target: 'AreYouAController',
            guard: not('isSoleProp'),
          },
        ],
      },
    },
    Overview: {
      on: {
        updatePersonBeingEdited: {
          target: 'PersonalInfo',
          actions: 'setPersonBeingEdited',
        },
        updateBusinessBeingEdited: [
          {
            target: 'SolePropBusinessDetails',
            actions: 'setBusinessBeingEdited',
            guard: 'isSoleProp',
          },
          {
            target: 'LlcBusinessDetails',
            actions: 'setBusinessBeingEdited',
            guard: not('isSoleProp'),
          },
        ],
        back: 'TypeOfBusiness',
        next: 'AdditionalInfo',
      },
    },
    PersonalInfo: {
      on: {
        updatePersonBeingEdited: {
          target: 'Address',
          actions: 'setPersonBeingEdited',
        },
        back: {
          target: 'Overview',
          actions: 'clearPersonBeingEdited',
        },
      },
    },
    Address: {
      on: {
        updateAddress: [
          {
            actions: 'clearBusinessBeingEdited',
            guard: 'isNonSolePropAndEditingBusinessInfo',
          },
          { target: 'Ssn', actions: 'setAddress' },
        ],
        back: [
          {
            target: 'LlcBusinessDetails',
            guard: 'isNonSolePropAndEditingBusinessInfo',
          },
          'PersonalInfo',
        ],
      },
    },
    Ssn: {
      on: {
        updatePersonBeingEdited: {
          actions: 'clearPersonBeingEdited',
        },
        next: 'Overview',
        back: 'Address',
      },
    },
    Submit: {
      on: {
        back: 'Overview',
      },
    },
    SolePropBusinessDetails: {
      on: {
        back: {
          target: 'Overview',
          actions: 'clearBusinessBeingEdited',
        },
        updateBusinessBeingEdited: {
          actions: 'clearBusinessBeingEdited',
        },
        next: 'Overview',
      },
    },
    AreYouAController: {
      on: {
        updateIsController: [
          {
            target: 'Overview',
            actions: 'setIsController',
            guard: 'isController',
          },
          {
            target: 'DeadEnd',
            actions: 'setIsController',
            guard: not('isController'),
          },
        ],
        back: {
          target: 'TypeOfBusiness',
          actions: 'setViewedControllerAttestation',
        },
      },
    },
    LlcBusinessDetails: {
      on: {
        updateBusinessBeingEdited: {
          target: 'Address',
          actions: 'setBusinessBeingEdited',
        },
        back: 'Overview',
      },
    },
    DeadEnd: {
      on: {
        back: {
          target: 'AreYouAController',
        },
      },
    },
    AdditionalInfo: {
      on: {
        back: 'Overview',
        next: 'PenaltyOfPerjury',
      },
    },
    PenaltyOfPerjury: {
      on: {
        back: 'AdditionalInfo',
        submit: 'Submit',
      },
    },
  },
});

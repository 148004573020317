import {
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  useDisclosure,
  ModalBody,
} from '@chakra-ui/react';
import { RxInfoCircled } from 'react-icons/rx';

import mainImage from 'images/buoy.svg';

export default function DeadEnd() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <WhoIsABoModal isOpen={isOpen} onClose={onClose} />
      <Heading as="h3" size="lg">
        You cannot open a Wallet account at this time
      </Heading>
      <Image src={mainImage} alt="Buoy" mb={6} />
      <Text fontSize={14} mb={4}>
        To open a Bushel Wallet account you must have beneficial ownership of
        the company or substantial control of the company.
      </Text>
      <Button leftIcon={<RxInfoCircled size={20} />} onClick={onOpen}>
        Learn More
      </Button>
    </>
  );
}

function WhoIsABoModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={6}>
          <Heading as="h4" fontSize="26px">
            Who is a Beneficial Owner?
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={6} pb={12}>
          <Text>
            A beneficial owner is any individual (i.e., natural persons) who
            directly or indirectly owns 25% or more of a business or exercises
            significant control over its operations. "Control" refers to the
            ability to influence important business decisions, such as
            establishing banking relationships. If you are registering on behalf
            of a business, please provide information about all individuals who
            meet this criteria.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

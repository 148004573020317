import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import currency from 'currency.js';
import { useSendMoney } from 'hooks/api';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmForm, confirmFormResolver } from '../confirmFormResolver';
import { TransferContext } from '../../transferMachine';
import { useSearchParams } from 'react-router-dom';

export const Confirm = ({
  send,
  context,
}: {
  send: any;
  context: TransferContext;
}) => {
  const { amount, balance, fundingSource, recipient } = context;
  const { mutate: sendMoney, isPending: sendMoneyIsPending } = useSendMoney();
  const [searchParams] = useSearchParams();
  const methods = useForm<ConfirmForm>({
    defaultValues: { memo: '' },
    resolver: confirmFormResolver,
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  function onSubmit(formData: ConfirmForm) {
    sendMoney(
      {
        payeeWalletId: recipient?.id as string,
        amount: amount ?? '0',
        fundingSourceId: fundingSource?.fundingSourceId,
        memo: formData.memo,
        paymentRequestId: searchParams.get('paymentId') ?? undefined,
      },
      {
        onSuccess: () => {
          send('NEXT');
        },
      }
    );
  }

  return !recipient ? (
    <CenteredSpinner
      centerProps={{ height: '170px' }}
      spinnerProps={{ size: 'xl', thickness: '4px' }}
    />
  ) : (
    <Stack spacing={4}>
      <HStack justifyContent="space-between">
        <ArrowBackIcon
          boxSize={6}
          cursor="pointer"
          onClick={() => send('BACK')}
        />
        <Text>Send to {context?.recipient?.name}</Text>
        <div />
      </HStack>
      <Stack spacing={6}>
        <Stack>
          <Text fontSize="sm" color="#718096">
            Amount
          </Text>
          <Heading
            as="h3"
            fontSize="24px"
            py={3}
            px={4}
            border="solid 1px"
            borderColor="#E2E8F0"
            borderRadius="xl"
          >
            {currency(amount as string).format()}
          </Heading>
        </Stack>
        <Stack>
          <Text fontSize="sm" color="#718096">
            From
          </Text>
          <Stack
            py={3}
            px={4}
            border="solid 1px"
            borderColor="#E2E8F0"
            borderRadius="xl"
            fontFamily="inter"
          >
            {fundingSource?.name == 'wallet' ? (
              <HStack justify="space-between" width="100%">
                <Text fontWeight="bold">Wallet Balance</Text>
                <Text>{currency(balance as string).format()}</Text>
              </HStack>
            ) : (
              <HStack justify="space-between" width="100%">
                <Text
                  maxWidth="70%"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {fundingSource?.name
                    ? fundingSource.name
                    : fundingSource?.financialInstitutionName}
                </Text>
                <Text>xxxx {fundingSource?.accountNumber}</Text>
              </HStack>
            )}
          </Stack>
          {fundingSource?.name === 'wallet' && (
            <Text fontSize="12px" color="textSecondary" fontFamily="inter">
              Your Wallet Balance is like cash. Once you pay, you can&apos;t
              cancel it. So please verify your payment.
            </Text>
          )}
        </Stack>
        <Stack>
          <Text fontSize="sm" color="#718096">
            To
          </Text>
          <HStack
            color="gray.500"
            py={3}
            px={4}
            border="solid 1px"
            borderColor="#E2E8F0"
            borderRadius="xl"
            justify="space-between"
            fontFamily="inter"
          >
            <Text fontWeight="bold">{recipient?.name}</Text>
            <Text>{recipient?.location}</Text>
          </HStack>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
              <FormControl isInvalid={!!errors?.memo}>
                <FormLabel fontSize="sm" color="#718096">
                  What is this payment for?
                </FormLabel>
                <Textarea
                  id="memo"
                  {...register('memo')}
                  placeholder="Required - 256 character limit"
                  p={4}
                />
                <FormErrorMessage>{errors?.memo?.message}</FormErrorMessage>
              </FormControl>
              <Stack spacing={4}>
                <Text fontSize="sm" color="textSecondary">
                  By clicking the button below, I authorize the above
                  transaction.
                </Text>
                <Button
                  isLoading={sendMoneyIsPending}
                  isDisabled={!watch('memo')}
                  type="submit"
                >
                  Pay {currency(amount as string).format()}
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

import { Heading, Stack, Text } from '@chakra-ui/react';
import {
  BusinessDtoBusinessStructureEnum,
  PersonDtoRolesEnum,
} from 'api/wallet-app';
import { useUpdateOnboardingData } from 'hooks';
import { AddressForm } from 'pages/onboarding/components';
import { OnboardingContext } from 'contexts/OnboardingContext';
import { useMemo } from 'react';

export default function Address() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      personBeingEdited,
      businessBeingEdited,
      onboardingData: { business: { businessStructure } = {}, people },
      resetMachine,
    },
  } = OnboardingContext.useSelector((state) => state);

  const isBeneficialOwnerInformation = useMemo(() => {
    return (
      personBeingEdited?.roles?.length === 1 &&
      personBeingEdited.roles.includes(PersonDtoRolesEnum.BeneficialOwner)
    );
  }, [personBeingEdited]);

  const { mutate: updateOnboardingData, isPending } = useUpdateOnboardingData();

  return (
    <Stack>
      <Heading as="h3" size="lg">
        {!!businessBeingEdited
          ? 'Registered Business Address'
          : isBeneficialOwnerInformation
            ? 'Beneficial Owner Address'
            : 'Your Residential Address'}
      </Heading>
      <Text mb="1rem" fontSize={14}>
        {!!businessBeingEdited
          ? "Please enter your business address as listed within your business's official documents."
          : "Make sure your address matches the address on your driver's license"}
      </Text>
      <AddressForm
        isLoading={isPending}
        handleOnSubmit={(data) => {
          if (
            !!businessBeingEdited &&
            businessStructure !== BusinessDtoBusinessStructureEnum.SoleProp
          ) {
            updateOnboardingData(
              {
                people: people,
                business: {
                  ...businessBeingEdited,
                  businessAddress: data,
                },
              },
              {
                onSuccess: () => {
                  resetMachine();
                  send({
                    type: 'updateAddress',
                  });
                },
              }
            );
          } else {
            send({
              type: 'updateAddress',
              address: data,
            });
          }
        }}
        defaultValues={
          personBeingEdited?.address ?? businessBeingEdited?.businessAddress
        }
        streetNumber1Label={
          !!businessBeingEdited
            ? 'Business Address Line 1'
            : "Driver's License Address Line 1"
        }
        streetNumber2Label={
          !!businessBeingEdited ? 'Business Address Line 2' : undefined
        }
      />
    </Stack>
  );
}

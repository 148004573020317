/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResubmissionNeededStateDto } from './ResubmissionNeededStateDto';
import {
  ResubmissionNeededStateDtoFromJSON,
  ResubmissionNeededStateDtoFromJSONTyped,
  ResubmissionNeededStateDtoToJSON,
} from './ResubmissionNeededStateDto';

/**
 * Onboarding application state
 * @export
 * @interface ResubmissionNeededAppState
 */
export interface ResubmissionNeededAppState {
  /**
   *
   * @type {ResubmissionNeededStateDto}
   * @memberof ResubmissionNeededAppState
   */
  appState: ResubmissionNeededStateDto;
}

/**
 * Check if a given object implements the ResubmissionNeededAppState interface.
 */
export function instanceOfResubmissionNeededAppState(
  value: object
): value is ResubmissionNeededAppState {
  if (!('appState' in value) || value['appState'] === undefined) return false;
  return true;
}

export function ResubmissionNeededAppStateFromJSON(
  json: any
): ResubmissionNeededAppState {
  return ResubmissionNeededAppStateFromJSONTyped(json, false);
}

export function ResubmissionNeededAppStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResubmissionNeededAppState {
  if (json == null) {
    return json;
  }
  return {
    appState: ResubmissionNeededStateDtoFromJSON(json['appState']),
  };
}

export function ResubmissionNeededAppStateToJSON(
  value?: ResubmissionNeededAppState | null
): any {
  if (value == null) {
    return value;
  }
  return {
    appState: ResubmissionNeededStateDtoToJSON(value['appState']),
  };
}

import { object, string } from 'yup';

export const llCBusinessInfoFormSchema = object().shape({
  industryClassificationCode: string().required(
    'Industry classification is required'
  ),
  businessPhone: string()
    .required('Phone number is required')
    .test(
      'phone-has-ten-digits',
      'Phone number must be at least 10 numbers',
      (value) => value.replace(/[\s()\-_]/g, '').length === 10
    ),
  businessName: string().required('Business name is required'),
  ein: string()
    .required('EIN is required')
    .test(
      'is-nine-digits',
      'EIN must be 9 numbers',
      (value) => value.replace(/[_\-]/g, '').length === 9
    ),
  doingBusinessAs: string().optional(),
});

import { Wrapper } from 'components/layout/app';
import { PanelWrapper } from 'components/layout/onboarding';
import { NotificationProvider } from 'contexts/NotificationContext';
import { LinkIntegrationPartner } from 'pages/WalletApp/link-integration-partner';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WalletAppRoutes } from 'routes';
import { AddFundingSource, PLAID_SCREEN_KEY } from './add-funding-source';

export default function WalletApp() {
  const [
    shouldDisplayFundingSourceScreen,
    setShouldDisplayFundingSourceScreen,
  ] = useState(sessionStorage.getItem(PLAID_SCREEN_KEY) === 'true');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  // Purposefully check this first so that if both exist, the page to link a
  // funding source shows first
  return shouldDisplayFundingSourceScreen ? (
    <PanelWrapper>
      <AddFundingSource
        progressFromScreen={() => {
          setShouldDisplayFundingSourceScreen(false);
        }}
      />
    </PanelWrapper>
  ) : token ? (
    <LinkIntegrationPartner token={token} />
  ) : (
    <Wrapper>
      <NotificationProvider>
        <WalletAppRoutes />
      </NotificationProvider>
    </Wrapper>
  );
}

import { useToast } from '@chakra-ui/react';
import {
  useAddFundingSource,
  useGeneratePlaidToken,
  useLogPlaidEvent,
  useRemoveFundingSource,
} from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { convertMetadataToStringOrNull } from 'utils';

export default function usePlaidSdk({
  fundingSourceId,
  onPlaidSuccess,
  onPlaidExit,
}: {
  fundingSourceId?: string;
  onPlaidSuccess?: ({
    publicToken,
    metadata,
  }: {
    publicToken: string;
    metadata: PlaidLinkOnSuccessMetadata;
  }) => void;
  onPlaidExit?: PlaidLinkOnExit;
} = {}) {
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);

  // Plaid Link Tokens are one-time use. Thus, every time you open the Plaid SDK,
  // you should get a new token.
  const {
    mutate: generatePlaidLinkToken,
    isPending: generatePlaidLinkTokenIsPending,
  } = useGeneratePlaidToken();
  const { mutate: addFundingSource, isPending: addFundingSourceIsPending } =
    useAddFundingSource();
  const { mutate: logPlaidEvent } = useLogPlaidEvent();
  const { mutate: removeFundingSource } = useRemoveFundingSource();
  const toast = useToast();

  const plaidConfig: PlaidLinkOptions = useMemo(() => {
    return {
      token: plaidLinkToken,
      onSuccess: (publicToken, metadata) => {
        addFundingSource(
          {
            plaidAccountId: metadata.accounts[0].id,
            plaidPublicToken: publicToken,
          },
          {
            onSuccess: () => {
              if (onPlaidSuccess) {
                onPlaidSuccess({ publicToken, metadata });
              }
            },
          }
        );
        setPlaidLinkToken(null);
      },
      onEvent: (eventName, metadata) => {
        logPlaidEvent({
          eventName,
          plaidEvent: convertMetadataToStringOrNull(metadata),
        });
      },
      onExit: (error, metadata) => {
        if (error?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
          if (fundingSourceId) {
            toast({
              position: 'bottom',
              status: 'error',
              title: 'Failed to Link Account',
              description:
                'We were unable to verify your account. Please try adding your account again.',
              isClosable: true,
              onCloseComplete: () => {
                removeFundingSource(fundingSourceId);
              },
            });
          } else {
            // Not sure if it is even possible to get into this else block
            console.error('Missing funding source id');
          }
        }
        if (onPlaidExit) {
          onPlaidExit(error, metadata);
        }
        setPlaidLinkToken(null);
      },
    };
  }, [plaidLinkToken]);

  const { ready, open } = usePlaidLink(plaidConfig);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return {
    plaidSdkIsReady: ready,
    addFundingSourceIsPending,
    generatePlaidLinkTokenIsPending,
    openPlaidSdk: () => {
      generatePlaidLinkToken(
        { fundingSourceId },
        {
          onSuccess: ({ linkToken }) => {
            if (linkToken) {
              setPlaidLinkToken(linkToken);
            } else {
              console.error('Plaid link token is not defined');
            }
          },
        }
      );
    },
  };
}

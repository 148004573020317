import { Button, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { TextInput } from 'components/formComponents';
import { FormProvider, useForm } from 'react-hook-form';
import { IndustryClassificationCodesInput } from '../../inputs';
import { useHookFormMask } from 'use-mask-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { llCBusinessInfoFormSchema } from './llc-business-info-form-schema';

type LlcBusinessInfo = {
  businessPhone: string;
  businessName: string;
  ein: string;
  industryClassificationCode: string;
  doingBusinessAs?: string;
};

export default function LlcBusinessInfoForm({
  defaultValues,
  disableEinInput,
  onSubmitBusinessInfo,
}: {
  defaultValues: LlcBusinessInfo;
  disableEinInput: boolean;
  onSubmitBusinessInfo: (data: LlcBusinessInfo) => void;
}) {
  const methods = useForm<LlcBusinessInfo>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(llCBusinessInfoFormSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const registerWithMask = useHookFormMask(register);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitBusinessInfo)} noValidate>
        <Stack spacing={12}>
          <Stack spacing={4}>
            <TextInput
              label="Business Phone Number"
              {...registerWithMask('businessPhone', ['(999) 999-9999'])}
              errors={errors}
              required={true}
              type="tel"
              inputMode="numeric"
            />
            <TextInput
              label="Legal Business Name"
              {...register('businessName')}
              errors={errors}
              required={true}
            />
            <TextInput
              label="Employer Identification Number (EIN)"
              type="tel"
              inputMode="numeric"
              subtitle="This is your business's unique 9-digit tax identification number. You can find this on your IRS paperwork or tax documents."
              placeholder="12-3456789"
              {...registerWithMask('ein', ['99-9999999'])}
              errors={errors}
              required={true}
              isDisabled={disableEinInput}
            />
            <IndustryClassificationCodesInput />
            <Stack>
              <HStack align="flex-end">
                <Text>DBA</Text>
                <Text color="gray.500" fontSize="xs">
                  Optional
                </Text>
              </HStack>
              <Text fontSize="14" color="gray.500">
                Enter your business's operating name, if different from the
                legal name.
              </Text>
              <Input {...register('doingBusinessAs')} />
            </Stack>
          </Stack>
          <Button type="submit">Next</Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

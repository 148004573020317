import { assertNoPoBox, noSpecialCharsRegex } from 'utils/helpers';
import { object, string } from 'yup';

export const addressFormSchema = object().shape({
  streetNumber1: string()
    .required('Please enter a street address')
    .max(50, 'Maximum 50 characters')
    .matches(noSpecialCharsRegex, 'No special characters allowed')
    .test('no-po-box', 'PO Boxes not allowed', (value) => {
      return assertNoPoBox(value);
    }),
  streetNumber2: string()
    .optional()
    .max(50, 'Maximum 50 characters')
    .matches(/^[a-zA-Z0-9 ]*$/, 'No special characters allowed')
    .test('no-po-box', 'PO Boxes not allowed', (value) => {
      return assertNoPoBox(value);
    }),
  city: string()
    .required('Please enter a city/town')
    .min(3, 'City must have at least 3 characters')
    .max(50, 'City cannot have more than 50 characters'),
  stateProvinceRegion: string().required('State is required'),
  country: string().required('Country is required'),
  postalCode: string()
    .required('Please enter a zip code')
    .min(5, 'Zip code must be at least 5 digits')
    .matches(/^\d{5}(-\d{4})?$/, {
      message: 'Please provide a valid postal code',
    }),
});

import { Button, Heading, Stack } from '@chakra-ui/react';
import { OnboardingContext } from 'contexts/OnboardingContext';
import {
  PersonalInfoSection,
  BeneficialOwnerSection,
  BusinessInfoSection,
} from 'pages/onboarding/components';
import {
  BusinessDtoBusinessStructureEnum,
  PersonDtoRolesEnum,
} from 'api/wallet-app';
import { useMemo } from 'react';

export const controllerRoles = new Set([
  PersonDtoRolesEnum.SoleProprietor,
  PersonDtoRolesEnum.Controller,
]);

export default function Overview() {
  const { send } = OnboardingContext.useActorRef();
  const {
    context: {
      onboardingData: { business, people },
    },
  } = OnboardingContext.useSelector((state) => state);
  const { businessStructure, industryClassificationCode } = business ?? {};

  const isSolePropBusinessStructure = useMemo(
    () => businessStructure === BusinessDtoBusinessStructureEnum.SoleProp,
    [businessStructure]
  );
  const existingController = useMemo(
    () =>
      people.find((person) =>
        person.roles.some((role) => controllerRoles.has(role))
      ),
    [people]
  );

  return (
    <Stack spacing={6}>
      <Heading as="h3" size="lg">
        Wallet Account Creation
      </Heading>
      <Stack spacing={12}>
        <Stack spacing={8}>
          <PersonalInfoSection
            onClickEdit={() => {
              send({
                type: 'updatePersonBeingEdited',
                personDetails: existingController ?? {
                  roles: isSolePropBusinessStructure
                    ? [PersonDtoRolesEnum.SoleProprietor]
                    : [PersonDtoRolesEnum.Controller],
                },
              });
            }}
          />
          <BusinessInfoSection
            onClickEdit={() => {
              send({
                type: 'updateBusinessBeingEdited',
                businessDetails: business,
              });
            }}
          />
          {!isSolePropBusinessStructure && <BeneficialOwnerSection />}
        </Stack>
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            send({ type: 'next' });
          }}
          isDisabled={!existingController || !industryClassificationCode}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}

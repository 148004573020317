import { TextInput } from 'components/formComponents';
import { Button, HStack, Stack } from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';
import { OnboardingAddressDto } from 'api/wallet-app';
import { yupResolver } from '@hookform/resolvers/yup';
import { addressFormSchema } from './address-form-schema';
import { UsStatesInput } from '../../inputs';

type AddressForm = Omit<Required<OnboardingAddressDto>, 'streetNumber2'> & {
  streetNumber2?: string;
};

export default function AddressForm({
  isLoading,
  streetNumber1Label,
  streetNumber2Label,
  defaultValues,
  handleOnSubmit,
}: {
  isLoading: boolean;
  streetNumber1Label?: string;
  streetNumber2Label?: string;
  defaultValues?: OnboardingAddressDto;
  handleOnSubmit: (data: OnboardingAddressDto) => void;
}) {
  const methods = useForm<AddressForm>({
    mode: 'onBlur',
    defaultValues: defaultValues ?? {
      city: '',
      country: 'US',
      postalCode: '',
      stateProvinceRegion: '',
      streetNumber1: '',
    },
    resolver: yupResolver(addressFormSchema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack spacing={12}>
          <AddressFormInputs
            streetNumber1Label={streetNumber1Label}
            streetNumber2Label={streetNumber2Label}
          />
          <Button isLoading={isLoading} type="submit">
            Next
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

export function AddressFormInputs({
  streetNumber1Label,
  streetNumber2Label,
}: {
  streetNumber1Label?: string;
  streetNumber2Label?: string;
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const registerWithMask = useHookFormMask(register);
  return (
    <Stack spacing={4}>
      <TextInput
        label={streetNumber1Label ?? 'Address Line 1'}
        {...register('streetNumber1')}
        errors={errors}
        required={true}
      />
      <TextInput
        label={streetNumber2Label ?? 'Address Line 2'}
        {...register('streetNumber2')}
        errors={errors}
      />
      <HStack>
        <TextInput
          flex={5}
          label="City"
          {...register('city')}
          errors={errors}
          required={true}
        />
        <UsStatesInput flex={3} />
      </HStack>
      <TextInput
        label="Zip Code"
        {...registerWithMask('postalCode', '99999[-9999]')}
        data-testid="postalCode"
        type="tel"
        inputMode="numeric"
        errors={errors}
        required={true}
        maxW="35%"
      />
      <TextInput
        hidden={true}
        label="Country"
        {...register('country')}
        value="US"
      />
    </Stack>
  );
}
